import React from "react";

import { Datagrid, List, TextField, EditButton, ImageField } from "react-admin";
import GarmentOptionValueFilter from "./GarmentOptionValueFilter";
import { CustomPagination } from "../../globals";

const GarmentOptionValueList = (props) => (
  <List
    {...props}
    filters={<GarmentOptionValueFilter />}
    title="resources.garment_types.list"
    sort={{ field: "name", order: "ASC" }}
    pagination={<CustomPagination />}
    bulkActionButtons={false}
    style={{ maxWidth: 1280, height: "100%" }}
  >
    <Datagrid>
      <TextField label="resources.garment_types.name" source="name" />
      <TextField
        label="resources.garment_types.description"
        source="description"
      />
      <TextField
        label="resources.garment_types.vietnamese"
        source="vietnamese"
      />
      <TextField label="table_columns.subheading" source="subheading" />

      <ImageField source="image" label="resources.garment_types.image" />
      <ImageField source="preview" label="table_columns.preview" />

      <EditButton />
    </Datagrid>
  </List>
);

export default GarmentOptionValueList;
