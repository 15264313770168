import React from "react";
import { FunctionField } from "react-admin";
import {
  Datagrid,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  SingleFieldList,
  ReferenceField,
} from "react-admin";
import { Typography } from "@material-ui/core";

import { CustomPagination } from "../../globals";
import { ArrayField } from "react-admin";

import { ImageURLField, CustomTextField, DisplayField } from "./components";

export const MeasurementList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      sort={{ field: "order", order: "ASC" }}
      bulkActionButtons={false}
      style={{ maxWidth: 1280, height: "100%" }}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="table_columns.id" />

        <FunctionField
          label="table_columns.name"
          render={(record) => (
            <div>
              <Typography>{record.type_eng}</Typography>
              <Typography variant="caption" component="b">
                {record.type_viet}
              </Typography>
            </div>
          )}
        />
        <ImageURLField source="image" label="table_columns.image" />

        <TextField source="order" label="table_columns.order" />
        <CustomTextField
          source="description"
          label="table_columns.description"
        />
        <CustomTextField
          source="instructions"
          label="table_columns.instructions"
        />

        <DisplayField source="scope" label="Displayed on" />

        <ArrayField source="garment_ids">
          <SingleFieldList
            linkType={false}
            style={{ display: "block", marginTop: 5, marginBottom: 5 }}
          >
            <ReferenceField
              link="show"
              source="garment_id"
              reference="garments"
            >
              <FunctionField
                render={(record) => <div>{record.description}</div>}
              />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};

export default MeasurementList;

const ListActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} record={data} />
  </TopToolbar>
);
