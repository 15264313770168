import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
/*
import OrderCreate from "./OrderCreate";
import OrderEdit from "./OrderEdit";
import OrderList from "./OrderList";
import OrderShow from "./OrderShow";
*/
import PaymentList from "./PaymentList";
import PaymentCreate from "./PaymentCreate";

export default {
  create: PaymentCreate,
  //edit: OrderEdit,
  list: PaymentList,
  //show: OrderShow,
  icon: ChatBubbleIcon
};
