import SwatchbookEdit from "./SwatchbookEdit";
import SwatchbookList from "./SwatchbookList";
//import SwatchbookShow from "./SwatchbookShow";
import SwatchbookCreate from "./SwatchbookCreate";

export default {
  list: SwatchbookList,
  edit: SwatchbookEdit,
  //show: SwatchbookShow,
  create: SwatchbookCreate,
};
