import React, { Fragment } from "react";

import {
  Datagrid,
  List,
  useTranslate,
  useUpdate,
  useNotify,
  useRefresh,
  SimpleForm,
  SelectInput,
  NumberInput,
} from "react-admin";

import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@mdi/react";
import { mdiContentCut } from "@mdi/js";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { BookCodeField, GarmentField, StaffField } from "../../components";
import {
  CustomPagination,
  fabric_thumbnail,
  CustomDateField,
  fabric_image,
  thumbnail_url,
} from "../../globals";
import { Typography } from "@material-ui/core";

import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import rowStyle from "./rowStyle";
import { FabricCutFilter } from "./FabricCutFilter";

//import GarmentFabricFilter from "./GarmentFabricFilter";
const FabricCutList = (props) => {
  const theme = useTheme();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="page_headers.to_cut"
      filters={<FabricCutFilter />}
      pagination={<CustomPagination />}
      filter={
        Number(localStorage.getItem("store_id")) === 0
          ? null
          : { store_id: Number(localStorage.getItem("store_id")) }
      }
      sort={{ field: "item_id", order: "DESC" }}
    >
      <Datagrid rowStyle={rowStyle(props.selectedRow, theme)}>
        <ItemField label="table_columns.id" />
        {/*<OrderField label="table_columns.order" source="item" />*/}

        <BookCodeField label="table_columns.book_code" source="cut" />
        <CustomDateField
          label="table_columns.created"
          source="creation_date"
          formatedDate={true}
        />
        <GarmentField label="table_columns.garment" source="item" />
        <StoreField label="table_columns.store" />
        <StaffField label="table_columns.staff" source="item" />

        {props.resource === "fabric_cuts/cut" && (
          <CustomDateField
            label="table_columns.cut_date"
            source="cut_date"
            formatedDate={true}
            utc={false}
          />
        )}
        {props.resource === "fabric_cuts/cut" && (
          <CutterField label="table_columns.cutter" />
        )}
        {props.resource === "fabric_cuts/cut" && (
          <CutField label="Cut Location" />
        )}
        <FabricThumbnailField label="resources.fabrics.fields.fabric" />
        {props.resource === "fabric_cuts/to_cut" && <LocationList />}
      </Datagrid>
    </List>
  );
};

const ItemField = ({ record }) => {
  return record.item_id;
};

const StoreField = ({ record }) => {
  return (
    <Tooltip title={record.item.order.store.name}>
      <Avatar
        alt={record.item.order.store.name}
        style={{
          backgroundColor: record.item.order.store.color,
          color: "white",
        }}
      >
        {record.item.order.store.alias}
      </Avatar>
    </Tooltip>
  );
};

const CutterField = ({ record }) => {
  return record.cutter ? (
    <div
      style={{ display: "flex" }}
      onClick={(event) => event.stopPropagation()}
    >
      {
        <Tooltip title={record.cutter.nick_name}>
          <Avatar
            component={Link}
            to={`/staffs/${record.cutter.id}/show`}
            alt={record.cutter.nick_name}
            src={thumbnail_url + record.cutter.thumbnail}
            style={{ marginRight: 5 }}
          />
        </Tooltip>
      }
    </div>
  ) : null;
};
const CutField = ({ record }) => {
  return `${record.length}m - ${record.location.name}`;
};

const LocationList = ({ record }) => {
  const translate = useTranslate();
  const [length, setLength] = React.useState(record.length);
  const [location, setLocation] = React.useState(null);
  const [updateCut] = useUpdate("fabric_cuts");
  const notify = useNotify();

  const refresh = useRefresh();
  const handleCut = () => {
    updateCut(
      {
        payload: {
          id: record.id,
          data: {
            staff_id: localStorage.getItem("staff_id")
              ? localStorage.getItem("staff_id")
              : 1,
            status_id: 3, //cut
            location_id: location,
            length,
          },
        },
      },
      {
        onSuccess: () => {
          refresh();
          notify(
            "ra.notification.created",
            "info"
            //`Cut ${length}m of fabric ${props.record.fabric_code} from location ${location}`
          );
        },
      }
    );
  };

  const LocationStock = ({ record }) => (
    <Typography>
      {record.location} - {record.stock}m
    </Typography>
  );
  return (
    <SimpleForm
      toolbar={<NulledToolbar />}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Fragment>
        <SelectInput
          id={record.id.toString()}
          optionText={<LocationStock />}
          source="location_id"
          optionValue="location_id"
          choices={record.fabric.locations}
          label={translate("table_columns.location")}
          style={{ width: 100, marginRight: 5 }}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />

        <NumberInput
          disabled={record.status_id === 1}
          source="length"
          id={record.id.toString()}
          defaultValue={record.length}
          step={0.1}
          onChange={(e) => setLength(parseFloat(e.target.value))}
        />
        <Tooltip title="Cut">
          <span>
            <IconButton
              type="button"
              action={null}
              onClick={() => handleCut()}
              disabled={record.status_id === 1 || length <= 0}
            >
              <Icon
                path={mdiContentCut}
                title={translate("table_columns.cut")}
                size={2}
                horizontal
                color={
                  location && record.status_id !== 1 && length > 0
                    ? green[600]
                    : grey[600]
                }
              />
            </IconButton>
          </span>
        </Tooltip>
      </Fragment>
    </SimpleForm>
  );
};

const NulledToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate = null,
  permissions,
  ...props
}) => null;

const FabricThumbnailField = ({ source, record }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Avatar
        src={fabric_thumbnail + record.fabric.image}
        onClick={handleClickOpen}
        style={{ width: 50, height: 50, borderRadius: 5 }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography variant="caption" component="b">
          {record.fabric.unique_code}
        </Typography>
      </div>
      <Typography variant="caption" color="textSecondary">
        {record.fabric.old_code}
      </Typography>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <img
            src={fabric_image + record.fabric.image}
            alt={record.unique_code}
            onClick={handleClose}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FabricCutList;
