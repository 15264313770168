import React from "react";
import ReactDOM from "react-dom";
/*import "./index.css";
import "./assets/fontawesome/css/fontawesome-all.min.css";

import "./assets/css/custom.css";
import "./assets/css/bigprinter.css";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app*/
import App from "./App";
//import BigPrinter from "./templates/bigprinter";
//import registerServiceWorker from "./registerServiceWorker";

//var loc = window.location.href;

//var view = loc.indexOf("big_print") > -1 ? <BigPrinter /> : <App />;

//ReactDOM.render(view, document.getElementById("root"));
//ReactDOM.render(App, document.getElementById("root"));
//registerServiceWorker();

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
