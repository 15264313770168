

import React from "react";
import {
    SimpleForm,
    TextInput,
    ImageInput,
  ImageField,
  Create
} from "react-admin";

const LocationCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          label="resources.fabrics.fields.name"
          source="name"
        />
        <TextInput
          label="resources.fabrics.fields.description"
          source="description"
        />
        <ImageInput
          source="images"
          label="resources.fabrics.fields.image"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>)


export default LocationCreate