import React from "react";
import {
  Edit,
  SimpleForm,
  Toolbar,
  useCreate,
  useRedirect,
  useNotify,
  useDelete,
  SaveButton,
  useUpdate,
  TextField,
  TextInput,
  ArrayField,
  Datagrid,
  FunctionField,
  useRefresh,
} from "react-admin";
import Link from "@material-ui/core/Link";

import { IconButton, TextField as MUITextInput } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import _ from "lodash";

import { bas_url } from "../../globals";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Typography, Button } from "@material-ui/core";

import { FabricThumbnail } from "../../globals";

import { useFormState } from "react-final-form";
//import { NulledToolbar } from "../../components";
const CollectionEdit = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;

  return (
    <Edit {...props} title="Create Collection">
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <TextInput
          source="name"
          label="table_columns.name"
          validate={required()}
        />
        <TextInput
          source="description"
          label="table_columns.description"
          validate={required()}
        />
        <FabricAdd />
        <ArrayField source="fabric_ids" label="table_columns.fabric">
          <Datagrid>
            <TextField label="table_columns.id" source="fabric.id" />
            <FunctionField
              label="table_columns.fabric_code"
              render={(record) => (
                <Link
                  href={`${bas_url}/f/${record.fabric.unique_code}`}
                  color="primary"
                  target="_blank"
                >
                  <Typography>{record.fabric.unique_code}</Typography>
                </Link>
              )}
            />
            <FunctionField
              label="table_columns.swatchbook"
              render={(record) => {
                console.log(record);
                return (
                  <Link
                    href={`${bas_url}/s/${record.fabric.swatchbook.unique_code}`}
                    color="primary"
                    target="_blank"
                  >
                    <Typography>
                      {record.fabric.swatchbook.unique_code}
                    </Typography>
                  </Link>
                );
              }}
            />

            <FabricThumbnail
              label="table_columns.thumbnail"
              source="fabric.image"
            />
            <FunctionField
              label="resources.fabrics.fields.stock"
              render={(record) => {
                return record.fabric && record.fabric.total_stock > 0
                  ? record.fabric.total_stock + "[m]"
                  : "No Stock";
              }}
            />
            <TextField label="table_columns.order" source="order" />
            <FabricDeleteOne label="Action" />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  );
};

const FabricDeleteOne = ({ record }) => {
  const { id } = record;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [deleteFabric] = useDelete("fabric_collections");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDelete = () => {
    setLoading(true);
    deleteFabric(
      {
        payload: { id },
      },
      {
        onSuccess: () => {
          setLoading(false);
          setOpen(false);
          refresh();
          notify(`Fabric ${record.fabric.unique_code} deleted from Collection`);
        },
      }
    );
  };
  const handleClose = () => {
    !loading && setOpen(false);
  };
  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <DeleteForeverIcon style={{ color: red["600"] }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Confirm delete fabric from Collection`}</DialogTitle>
        <DialogContent>
          <Typography>Delete?</Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button onClick={handleDelete} color="primary" variant="contained">
              Delete
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const FabricAdd = ({ record }) => {
  const [openOne, setOpenOne] = React.useState(false);
  const [openMultiple, setOpenMultiple] = React.useState(false);
  const [collection_id] = React.useState(record.id);
  const [unique_code, setUnique_code] = React.useState("");
  const [fabrics, setFabrics] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [create] = useCreate("fabric_collections/add");
  const notify = useNotify();
  const refresh = useRefresh();

  const handleAddMultiple = () => {
    console.log(fabrics);
    let fabricList = fabrics.split(/[\r\n,]+/);
    fabricList.forEach((e) => {
      console.log(e);
      let unique_code = null;

      if (e.lastIndexOf("/") > 0) {
        unique_code = e.substring(e.lastIndexOf("/") + 1, e.length).trim();
      } else {
        unique_code = e.trim();
      }
      console.log(unique_code);

      unique_code.length && handleAddOne(unique_code, collection_id);
    });
  };

  const handleAddOne = (unique_code, collection_id) => {
    //console.log("unique_code", unique_code, "collection_id", collection_id);
    setLoading(true);
    create(
      {
        payload: {
          data: {
            unique_code,
            collection_id,
            staff_id: Number(localStorage.getItem("staff_id")),
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          console.log(data);
          setOpenOne(false);
          setLoading(false);

          if (data.id) {
            notify(`Fabric ${unique_code} added to Collection`);
            refresh();
          } else {
            notify(`ERROR adding ${unique_code} to Collection`);
          }
        },
      },
      {
        onFailure: (error) => {
          console.log(error);
          notify(`Comment approval error: ${error.message}`, "warning");
        },
      }
    );
    //setOpen(false);

    //setLoading(true);
  };
  const handleClose = () => {
    !loading && setOpenOne(false);
    !loading && setOpenMultiple(false);
  };
  return (
    <div>
      <Button
        type="button"
        action={null}
        color="secondary"
        onClick={() => setOpenOne(true)}
      >
        <Typography>Add One Fabric</Typography>
      </Button>
      <Button
        type="button"
        action={null}
        color="primary"
        onClick={() => setOpenMultiple(true)}
      >
        <Typography>Add Multiple Fabrics</Typography>
      </Button>
      <Dialog
        open={openMultiple}
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>{`Add multiple Fabrics to Collection: ${record.name}`}</DialogTitle>
        <DialogContent>
          <MUITextInput
            id="standard-multiline-static"
            multiline
            rows={10}
            value={fabrics}
            onChange={(e) => setFabrics(e.target.value)}
            label="Fabric List"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={handleAddMultiple}
              color="primary"
              variant="contained"
              disabled={loading || fabrics === ""}
            >
              ADD
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={openOne} onClose={handleClose}>
        <DialogTitle>{`Add Fabric to Collection: ${record.name}`}</DialogTitle>
        <DialogContent>
          <MUITextInput
            value={unique_code}
            onChange={(e) =>
              e.target.value.length < 8 && setUnique_code(e.target.value)
            }
            label="unique_code"
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={() => handleAddOne(unique_code, collection_id)}
              color="primary"
              variant="contained"
              disabled={loading || unique_code === ""}
            >
              ADD
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: blue[600],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const UserCreateToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveCustomButton label="ra.action.save" redirect="list" />
  </Toolbar>
);

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;

  const [update] = useUpdate("collections");
  const [createFabricCollection] = useCreate("fabric_collections");
  const [deleteFabricCollection] = useDelete("fabric_collections");
  const [updateFabricCollection] = useUpdate("fabric_collections");

  const handleClick = () => {
    //console.log(formState.values);
    if (!formState.valid) {
      return;
    }

    let fabrics = formState.values.fabric_ids
      ? formState.values.fabric_ids
      : [];

    //console.log(fabrics, record.fabric_ids);

    update(
      {
        payload: {
          id: formState.values.id,
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.fabric_ids &&
            record.fabric_ids.forEach((o) => {
              if (!_.find(fabrics, (oo) => oo.id === o.id)) {
                deleteFabricCollection({
                  payload: { id: o.id },
                });
              }
            });

          fabrics.forEach((o) => {
            //console.log(o);

            if (o.id) {
              //console.log("id");
              updateFabricCollection({
                payload: {
                  id: o.id,
                  data: {
                    collection_id: newRecord.id,
                    fabric_id: o.fabric_id,
                    order: o.order,
                  },
                },
              });
            } else {
              //console.log("no id");
              createFabricCollection({
                payload: {
                  data: {
                    collection_id: newRecord.id,
                    fabric_id: o.fabric_id,
                    order: o.order,
                  },
                },
              });
            }
          });
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default CollectionEdit;
