//import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import GarmentOptionCreate from "./GarmentOptionCreate";
import GarmentOptionEdit from "./GarmentOptionEdit";
import GarmentOptionList from "./GarmentOptionList";
import GarmentOptionShow from "./GarmentOptionShow";

export default {
  create: GarmentOptionCreate,
  edit: GarmentOptionEdit,
  list: GarmentOptionList,
  show: GarmentOptionShow,
};
