import React from "react";

import {
  TextField,
  Show,
  SimpleShowLayout,
  EditButton,
  TopToolbar,
  ImageField,
  ListButton,
} from "react-admin";

const GarmentOptionValueShow = (props) => {
  return (
    <Show
      {...props}
      title="resources.garment.show"
      actions={<Actions />}
      style={{ maxWidth: 1280, height: "100%" }}
    >
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="vietnamese" />
        <TextField source="subheading" />

        <ImageField source="image" title="title" />
        <ImageField source="preview" title="title" />
      </SimpleShowLayout>
    </Show>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentOptionValueShow;
