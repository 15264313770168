import loopbackProvider from "react-admin-loopback";
import { api_uri } from "./globals";
//import { fetchUtils } from "react-admin";
/*
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("X-Custom-Header", "foobar");
  options.headers["Content-Range"] = "posts 0-24/319";
  return fetchUtils.fetchJson(url, options);
};
*/
//const dataProvider = loopbackProvider(api_uri, httpClient);
const dataProvider = loopbackProvider(api_uri);

export default (type, resource, params) =>
  new Promise(resolve =>
    setTimeout(() => resolve(dataProvider(type, resource, params)), 500)
  );
