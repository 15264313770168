import React from "react";

import { Filter, ReferenceInput, AutocompleteInput } from "react-admin";

export const HotelFilter = props => (
  <Filter {...props} variant="standard">
    <ReferenceInput
      label="table_columns.hotel_name"
      source="id"
      reference="hotels"
      perPage={10000}
      resettable
      sort={{ field: "name", order: "ASC" }}
      suggestionLimit={10}
      allowEmpty
    >
      <AutocompleteInput
        optionText={record =>
          record.name && `${record.name} - ${record.address}`
        }
      />
    </ReferenceInput>
  </Filter>
);
