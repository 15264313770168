//import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import LocationCreate from "./LocationCreate";
import LocationEdit from "./LocationEdit";
import LocationList from "./LocationList";
import LocationShow from "./LocationShow";

export default {
  create: LocationCreate,
  edit: LocationEdit,
  list: LocationList,
  show: LocationShow,
  //icon: ChatBubbleIcon
};
