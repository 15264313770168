import React from "react";
import {
  Datagrid,
  TextField,
  ArrayField,
  DateField,
  Show,
  SimpleShowLayout,
  FunctionField,
  TopToolbar,
  EditButton,
  ListButton,
} from "react-admin";
import Link from "@material-ui/core/Link";
import { bas_url, location_thumbnail } from "../../globals";

const LocationShow = (props) => {
  return (
    <Show {...props} title={<LocationTitle />} actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField label="resources.fabrics.fields.name" source="name" />

        <TextField
          label="resources.fabrics.fields.description"
          source="description"
        />
        <TextField
          label="resources.fabrics.fields.num_fabrics"
          source="count"
        />

        <FunctionField
          label="table_columns.image"
          render={(record) => (
            <img
              width={100}
              src={location_thumbnail + record.image}
              alt={record.unique_code}
            />
          )}
        />
        <DateField
          label="resources.fabrics.fields.creation_date"
          source="creation_date"
        />
        <DateField
          label="resources.fabrics.fields.update_date"
          source="update_date"
        />
        <ArrayField source="fabrics">
          <Datagrid>
            <FunctionField
              label="table_columns.fabric"
              render={(record) => (
                <Link
                  href={`${bas_url}/f/${record.unique_code}`}
                  color="primary"
                  target="_blank"
                >
                  {record.unique_code}
                </Link>
              )}
            />
            <TextField source="old_code" label="table_columns.supplier_code" />
            <FunctionField
              label="table_columns.swatchbook"
              render={(record) => (
                <Link
                  href={`${bas_url}/s/${record.swatchbook}`}
                  color="primary"
                  target="_blank"
                >
                  {record.swatchbook}
                </Link>
              )}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const LocationTitle = ({ record }) => {
  return <span>Location {record ? `"${record.description}"` : ""}</span>;
};

const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    {data && <EditButton basePath={basePath} record={data} />}
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default LocationShow;
