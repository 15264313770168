import React from "react";
import { useLocale, useSetLocale } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import LanguageIcon from "@material-ui/icons/Language";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const Configuration = () => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  return (
    <Tooltip title="Change Language">
      <IconButton
        color="inherit"
        onClick={() => (locale === "en" ? setLocale("vn") : setLocale("en"))}
      >
        <LanguageIcon />
        <Typography color="inherit">{locale === "en" ? "VN" : "EN"}</Typography>
      </IconButton>
    </Tooltip>
  );
};
export default Configuration;
