import React from "react";

import {
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  SearchInput,
  useTranslate,
  DateInput,
} from "react-admin";

export const OrderFilter = ({ ...props }) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      {/* Customer Filter*/}
      <SearchInput
        placeholder={translate("table_columns.search_customer")}
        source="q"
        resettable
        alwaysOn
      />

      {/* Store Filter*/}
      {(Number(localStorage.getItem("store_id")) === 0 ||
        Number(localStorage.getItem("store_id")) === 1) && (
        <ReferenceInput
          label="table_columns.store"
          source="store_id"
          reference="stores"
          allowEmpty={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}

      <TextInput label="table_columns.book_number" source="book" resettable />

      <TextInput label="table_columns.code" source="code" resettable />

      <SelectInput
        source="status"
        allowEmpty={false}
        label="table_columns.status"
        choices={statuses}
      />
      <DateInput source="gt" label="Date" key="gt" />
      <DateInput source="lt" label="Date to" key="lt" />

      {/* let filter = { timestamp: { gt: new Date("2020-02-20T00:00:00.000Z") } }; 
    <InputRow label="Date from">
      <DateInput source="timestamp.gt" label="Date from" key="gt" />
      <DateInput source="timestamp.lt" label="End Date" key="lt" /> 
    </InputRow>

     <ReferenceInput
        label="table_columns.hotel"
        source="hotel_id"
        reference="hotels"
        perPage={10000}
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText={(record) =>
            record &&
            `${convertViToEn(record.name)} - ${convertViToEn(record.address)}`
          }
          resettable
        />
      </ReferenceInput>
    */}
    </Filter>
  );
};

const statuses = [
  { id: "unpaid", name: "Unpaid" },
  { id: "pending_final_payment", name: "Final Payment" },
  { id: "completed", name: "Completed" },
];

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginRight: 10,
  },
};

export class InputRow extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props;
    return (
      <span style={styles.row}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, rest);
        })}
      </span>
    );
  }
}
