import React, { cloneElement } from "react";
import {
  Datagrid,
  List,
  Responsive,
  TextField,
  FunctionField,
  TopToolbar,
  useListContext,
  SimpleShowLayout,
  RichTextField,
  Labeled,
} from "react-admin";

import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";

import Avatar from "@material-ui/core/Avatar";

import Tooltip from "@material-ui/core/Tooltip";

import DoneIcon from "@material-ui/icons/Done";
import { Link } from "react-router-dom";
import ErrorIcon from "@material-ui/icons/Error";
import {
  CustomDateField,
  formatDecimals,
  PayButton,
  CustomPagination,
} from "../../globals";

import { BookCodeField, StoreField } from "../../components";

import { PaymentFilter } from "./PaymentFilter";
import { Typography } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const ListActions = (props) => {
  const { filters } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};

export const PaymentList = ({ permissions, ...props }) => {
  const paymentRowStyle = (record, index) => ({
    // backgroundColor: record.balance === 0 ? "#e8f5e9" : "white"
  });
  return (
    <List
      {...props}
      filters={<PaymentFilter />}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
      actions={<ListActions />}
      pagination={<CustomPagination />}
      filter={{ disabled: false }}
    >
      <Responsive
        //small={}
        medium={
          <Datagrid rowStyle={paymentRowStyle} expand={<ExpandPanel />}>
            {permissions === "admin" && (
              <TextField source="id" label="table_columns.id" />
            )}
            <CustomDateField
              source="creation_date"
              label="table_columns.date"
              formatedDate={true}
            />
            <FunctionField
              label="Customer"
              render={(record) =>
                record.order && (
                  <div>
                    <Typography>
                      {`${record.order.customer_first_name} ${record.order.customer_last_name}`}
                    </Typography>
                    <Typography variant="caption" component="b">
                      {record.order.customer_email
                        ? record.order.customer_email
                        : "No e-mail"}
                    </Typography>
                  </div>
                )
              }
            />
            <StoreField label="table_columns.store" />

            <BookCodeField label="table_columns.book_code" source="payment" />

            <FunctionField
              label="table_columns.payment_type"
              render={(record) => (
                <Typography>{`${
                  record.type
                    ? record.type.name &&
                      record.type &&
                      record.type.name.toUpperCase()
                    : ""
                }`}</Typography>
              )}
            />
            <FunctionField
              label="Method"
              render={(record) =>
                record.is_cc ? (
                  <CreditCardIcon style={{ color: blue[600] }} />
                ) : (
                  <AttachMoneyIcon style={{ color: green[600] }} />
                )
              }
            />
            <FunctionField
              label="table_columns.amount"
              render={(record) => (
                <div>
                  <Typography>
                    {`$
                ${formatDecimals((record.amount / record.rate).toFixed(2))}`}
                  </Typography>
                  {record.rate !== 1 && record.currency && (
                    <Typography variant="caption" component="b">
                      {`Paid with: ${formatDecimals(record.amount)} ${
                        record.currency.alias
                      }`}
                    </Typography>
                  )}
                </div>
              )}
            />
            <FunctionField
              label="table_columns.balance"
              render={(record) =>
                record.order && record.order.balance > 0 ? (
                  <Typography>
                    ${formatDecimals(record.order.balance.toFixed(2))}
                  </Typography>
                ) : (
                  <DoneIcon style={{ color: green[600] }} />
                )
              }
            />
            <FunctionField
              label="table_columns.cashier"
              render={(record) => (
                <Link to={{ pathname: "/staffs/" + record.staff_id + "/show" }}>
                  <Tooltip title={record.staff && record.staff.name}>
                    <Avatar
                      alt={record.staff && record.staff.name}
                      src={record.staff && record.staff.image}
                    />
                  </Tooltip>
                </Link>
              )}
            />
            <FunctionField
              render={(record) =>
                record.order &&
                record.order.balance > 0 &&
                !record.disabled && (
                  <PayButton
                    record={{
                      id: record.order.id,
                      status: record.order.status,
                    }}
                  />
                )
              }
            />
            {/* <ReferenceField
              reference="orders"
              source="order_id"
              label=" "
              link="show"
            >
              <Tooltip title="Linked Order">
                <FunctionField
                  render={record =>
                    record.linked_order_id && (
                      <LinkIcon style={{ color: "#ff5252" }} />
                    )
                  }
                />
              </Tooltip>
                </ReferenceField> */}
            <FunctionField
              render={(record) =>
                record.disabled && (
                  <Tooltip title="HUY BILL">
                    <ErrorIcon style={{ color: "#e53935" }} />
                  </Tooltip>
                )
              }
            />
          </Datagrid>
        }
      />
    </List>
  );
};

export default PaymentList;

const ExpandPanel = (props) => {
  return (
    <SimpleShowLayout {...props}>
      {props.record.notes ? (
        <RichTextField source="notes" />
      ) : (
        <Labeled label="Notes">
          <Typography>No Notes</Typography>
        </Labeled>
      )}
    </SimpleShowLayout>
  );
};
