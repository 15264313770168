import React from "react";
import {
  SimpleForm,
  ReferenceInput,
  TextInput,
  Create,
  SelectInput,
  required
} from "react-admin";
import { parse } from "query-string";

export const CustomerCreate = props => {
  const { order_id: order_id_string } = parse(props.location.search);
  const order_id = order_id_string ? parseInt(order_id_string, 10) : "";

  const redirect = order_id ? `/orders/${order_id}/show/customers` : false;

  return (
    <Create {...props} title="Create Order">
      <SimpleForm defaultValue={{ order_id }} redirect={redirect}>
        <ReferenceInput
          source="order_id"
          reference="orders"
          allowEmpty
          validate={required()}
        >
          <SelectInput optionText="email" />
        </ReferenceInput>
        <TextInput source="created_at" />
      </SimpleForm>
    </Create>
  );
};
