import React from "react";

import Logo from "../assets/logo-receipt.png";
import moment from "moment-timezone";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { PaymentFormat } from "../globals";
import _ from "lodash";

const dateFormat = "MMM DD,YY hh:mm A";

const formatDecimals = (figure) => {
  return figure && figure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export class DepositPreview extends React.PureComponent {
  render() {
    const { record, reprint } = this.props;
    //console.log(this.props.total_deposit);
    let total_deposit = this.props.total_deposit;

    // console.log(record, total_deposit);

    const itemList = getitemList(record);
    const payments = getPayments(record);
    let deposit = [];

    if (total_deposit === null) {
      deposit = payments.filter((p) => p.type_id === 1).length
        ? payments.filter((p) => p.type_id === 1)[0].value
        : 0;
      //let total_deposit = 0;
      //console.log(deposit);

      deposit.map((i) => (total_deposit += i.amount / i.currency.rate));

      total_deposit = total_deposit.toFixed(total_deposit % 1 === 0 ? 0 : 2);
      //console.log(total_deposit);
    }
    //console.log(record);
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ width: "80mm" }}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <img
            style={{ marginBottom: 20 }}
            src={Logo}
            width="150"
            alt="Bebe Logo"
          />
          <Typography variant="h6" component="h6">
            Deposit Receipt
          </Typography>
          <Typography variant="h6" component="h6">
            Order #{record.id} {reprint && "REPRINT"}
          </Typography>

          {/*reprint && (
            <Typography variant="h6" component="h6">
              REPRINT
            </Typography>
          )*/}

          <Typography gutterBottom>
            {moment().format("DD/MM/YYYY h:mm:ss a")}
          </Typography>
        </Grid>

        <Divider style={{ marginTop: 20 }} />

        <Grid container>
          <Grid item>
            <Typography>{`Customer Name: ${record.customer_first_name} ${record.customer_last_name}`}</Typography>
            {record.store_id !== 4 ? (
              <div>
                <Typography>{`Hotel: ${record.hotel_ids[0].hotel.name}`}</Typography>
                <Typography>{`Room:  ${record.hotel_ids[0].room}`}</Typography>
                <Typography>{`Fitting Time:  ${moment(record.fitting).format(
                  dateFormat
                )}`}</Typography>
              </div>
            ) : (
              <Typography>{`Hotel: Online Order`}</Typography>
            )}

            <Typography>
              {`Sales Assistant:  ${record.staff_ids[0].staff.name}`}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 10 }} />

        <Grid container direction="column">
          <Typography>{`Items (${record.items.length}):`}</Typography>
          <Grid item style={{ paddingLeft: 40 }}>
            {itemList.map((i) => (
              <div key={i.id}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>{`${i.garment} - ${i.customer_name}`}</Typography>
                  <Typography>{`$${i.price}`}</Typography>
                </Grid>
                <Typography
                  style={{ paddingLeft: 20 }}
                >{` ${i.detail}`}</Typography>
              </div>
            ))}
          </Grid>
        </Grid>

        <div>
          {record.discount > 0 && (
            <div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>Total:</Typography>
                  <Typography>{`$${record.total_price}`}</Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>Discount:</Typography>
                  <Typography>{`$${record.discount}`}</Typography>
                </Grid>
              </Grid>
            </div>
          )}

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Grid container>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography style={{ fontWeight: "bold" }}>
                Total Bill:
              </Typography>

              <Typography style={{ fontWeight: "bold" }}>{`$${
                record.total_price - record.discount
              }`}</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: "bold" }}
              >{`Deposit Paid:`}</Typography>

              <Typography
                style={{ fontWeight: "bold" }}
              >{`$${total_deposit}`}</Typography>
            </Grid>

            {deposit.is_cc === 1 && (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {deposit.currency === "USD" ? (
                  <Typography style={{ fontWeight: "bold" }}>
                    Credit Card Fee:
                  </Typography>
                ) : (
                  <PaymentFormat
                    style={{ fontWeight: "bold" }}
                    displayType="text"
                    value={deposit.alt_fee}
                    prefix="Credit Card Fee: ($"
                    suffix={` ${deposit.currency})`}
                  />
                )}

                <Typography
                  style={{ fontWeight: "bold" }}
                >{`$${deposit.fee}`}</Typography>
              </Grid>
            )}
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                Balance Due:
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>{`$${(
                record.total_price -
                record.discount -
                total_deposit
              ).toFixed(
                (record.total_price - record.discount - total_deposit) % 1 === 0
                  ? 0
                  : 2
              )}`}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </div>

        <Grid container direction="column" justify="center" alignItems="center">
          <Typography>{record.store.name}</Typography>
          <Typography>{record.store.address}</Typography>
          <Typography>{record.store.phone}</Typography>
          <Typography>Hoi An, Vietnam</Typography>
        </Grid>
      </Grid>
    );
  }
}

export class FinalPreview extends React.PureComponent {
  render() {
    const { record, reprint } = this.props;
    const itemList = getitemList(record);
    const payments = getPayments(record);
    let final_payment = this.props.final_payment;

    let total_deposit = 0;
    let final = [];
    let deposit = payments.filter((p) => p.type_id === 1)[0].value;
    deposit.map((i) => (total_deposit += i.amount / i.currency.rate));

    total_deposit = total_deposit.toFixed(total_deposit % 1 === 0 ? 0 : 2);

    //console.log({ deposit });

    if (final_payment === null) {
      final = payments.filter((p) => p.type_id === 2)[0].value;

      final.map((i) => (final_payment += i.amount / i.currency.rate));
      final_payment = final_payment.toFixed(final_payment % 1 === 0 ? 0 : 2);
      //console.log(final_payment);
    }
    //console.log(final, final_payment);

    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ width: "80mm" }}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <img style={{ margin: 20 }} src={Logo} width="150" alt="Bebe Logo" />
          <Typography variant="h6" component="h6">
            Final Payment Receipt
          </Typography>
          <Typography variant="h6" component="h6">
            Order #{record.id} {reprint && "REPRINT"}
          </Typography>
          {/*reprint && (
            <Typography variant="h6" component="h6">
              REPRINT
            </Typography>
          )*/}

          <Typography gutterBottom>
            {moment().format("DD/MM/YYYY h:mm:ss a")}
          </Typography>
        </Grid>

        <Divider style={{ marginTop: 20 }} />

        <Grid container>
          <Grid item>
            <Typography>{`Customer Name: ${record.customer_first_name} ${record.customer_last_name}`}</Typography>
            {record.store_id !== 4 ? (
              <div>
                <Typography>{`Hotel: ${record.hotel_ids[0].hotel.name}`}</Typography>
                <Typography>{`Room:  ${record.hotel_ids[0].room}`}</Typography>
                <Typography>{`Fitting Time:  ${moment(record.fitting)
                  .utc()
                  .format(dateFormat)}`}</Typography>
              </div>
            ) : (
              <Typography>{`Hotel: Online Order`}</Typography>
            )}

            <Typography>
              {`Sales Assistant:  ${record.staff_ids[0].staff.name}`}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 10 }} />

        <Grid container direction="column">
          <Typography>{`Items (${record.items.length}):`}</Typography>
          <Grid item style={{ paddingLeft: 40 }}>
            {itemList.map((i) => (
              <div key={i.id}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>{`${i.garment} - ${i.customer_name}`}</Typography>
                  <Typography>{`$${i.price}`}</Typography>
                </Grid>
                <Typography
                  style={{ paddingLeft: 20 }}
                >{` ${i.detail}`}</Typography>
              </div>
            ))}
          </Grid>
        </Grid>
        <div>
          {record.discount > 0 && (
            <div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>Total:</Typography>
                  <Typography>{`$${record.total_price}`}</Typography>{" "}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>Discount:</Typography>
                  <Typography>{`$${record.discount}`}</Typography>
                </Grid>
              </Grid>
            </div>
          )}

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Grid container>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography style={{ fontWeight: "bold" }}>
                Total Bill:
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>{`$${
                record.total_price - record.discount
              }`}</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography
                style={{ fontWeight: "bold" }}
              >{`Deposit paid:`}</Typography>

              <Typography
                style={{ fontWeight: "bold" }}
              >{`$${total_deposit}`}</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Typography
                style={{ fontWeight: "bold" }}
              >{`Final Payment paid:`}</Typography>

              <Typography
                style={{ fontWeight: "bold" }}
              >{`$${final_payment}`}</Typography>
            </Grid>
            {final.is_cc === 1 && (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {final.currency === "USD" ? (
                  <Typography style={{ fontWeight: "bold" }}>
                    Credit Card Fee:
                  </Typography>
                ) : (
                  <PaymentFormat
                    style={{ fontWeight: "bold" }}
                    displayType="text"
                    value={final.alt_fee}
                    prefix="Credit Card Fee: ($"
                    suffix={` ${final.currency})`}
                  />
                )}

                <Typography
                  style={{ fontWeight: "bold" }}
                >{`$${final.fee}`}</Typography>
              </Grid>
            )}
          </Grid>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </div>

        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Typography>ORDER PAID IN FULL</Typography>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography>{record.store.name}</Typography>
          <Typography>{record.store.address}</Typography>
          <Typography>{record.store.phone}</Typography>
          <Typography>Hoi An, Vietnam</Typography>
        </Grid>
      </Grid>
    );
  }
}

const getitemList = (record) => {
  let items = _(record.itemList)
    .groupBy((i) => i.group + i.customer_name)
    .map((value, key) => ({
      price:
        value[0].price % 1 === 0
          ? formatDecimals(value[0].price.toFixed(0))
          : formatDecimals(value[0].price.toFixed(2)),
      garments: value,
      customer_name: value[0].customer_name,
      garment: value[0].garment,
      id: value[0].id,
      is_suit: value[0].is_suit,
      detail: value[0].is_suit
        ? "(" +
          value[0].num_jackets +
          " Jackets, " +
          value[0].num_pants +
          " Trousers)"
        : "",
    }))
    .value();
  return items;
};
const getPayments = (record) => {
  let payments = _(record.payments)
    .groupBy((i) => i.type_id)
    .map((value, key) => ({
      value,
      type_id: value[0].type_id,
    }))
    .value();

  return payments;
};
