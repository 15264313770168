import MeasurementList from "./MeasurementList";
import MeasurementShow from "./MeasurementShow";
import MeasurementEdit from "./MeasurementEdit";
import MeasurementCreate from "./MeasurementCreate";

export default {
  create: MeasurementCreate,
  edit: MeasurementEdit,
  list: MeasurementList,
  show: MeasurementShow,
  //icon: ChatBubbleIcon
};
