import React from "react";
import { SimpleForm, TextInput, SaveButton, Toolbar, Edit } from "react-admin";

const HotelEdit = props => {
  const required = (message = "Required") => value =>
    value ? undefined : message;

  return (
    <Edit {...props} title="Edit">
      <SimpleForm toolbar={<HotelEditToolbar />}>
        <TextInput
          source="name"
          label="table_columns.hotel_name"
          validate={required()}
          fullWidth
        />
        <TextInput
          source="address"
          label="table_columns.address"
          validate={required()}
          fullWidth
        />
        <TextInput
          source="phone"
          label="table_columns.phone"
          validate={required()}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
export default HotelEdit;

const HotelEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton label="ra.action.edit" redirect="show" />
  </Toolbar>
);
