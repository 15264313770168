import red from "@material-ui/core/colors/red";

const rowStyle = (selectedRow, theme) => (record, index, defaultStyle = {}) => {
  let style = defaultStyle;
  if (selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected
    };
  }

  if (record.link && record.link.length && record.status !== "completed")
    return {
      ...style,
      borderLeftColor: red["A200"],
      borderLeftWidth: 10,
      borderLeftStyle: "solid"
    };
  return style;
};

export default rowStyle;
