//import StaffCreate from "./StaffCreate";
import StaffEdit from "./StaffEdit";
import StaffList from "./StaffList";
import StaffShow from "./StaffShow";
import StaffCreate from "./StaffCreate";

export default {
  // create: StaffCreate,
  edit: StaffEdit,
  list: StaffList,
  show: StaffShow,
  create: StaffCreate
};
