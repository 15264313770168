import React from "react";
import { MenuItemLink } from "react-admin";
import SubMenu from "./SubMenu";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocalPostOfficeIcon from "@material-ui/icons/LocalPostOffice";
import SettingsIcon from "@material-ui/icons/Settings";
import { useTranslate } from "react-admin";
import BarChartIcon from "@material-ui/icons/BarChart";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ApartmentIcon from "@material-ui/icons/Apartment";

import Icon from "@mdi/react";
import { mdiContentCut } from "@mdi/js";

const Menu = ({ onMenuClick, logout, open }) => {
  const [menuCashier, setmenuCashier] = React.useState(false);
  const [menuPayment, setmenuPayment] = React.useState(false);
  const [menuCutter, setmenuCutter] = React.useState(false);

  const [menuUtil, setmenuUtil] = React.useState(false);
  const [menuCommission, setmenuCommission] = React.useState(false);
  const [menuGarment, setmenuGarment] = React.useState(false);
  const [menuFactory, setmenuFactory] = React.useState(false);

  const translate = useTranslate();

  return (
    <div>
      {/*permissions === "admin" && (
        <DashboardMenuItem onClick={onMenuClick} name="menu.dashboard" />
      )*/}
      <SubMenu
        handleToggle={() => setmenuCashier(!menuCashier)}
        isOpen={menuCashier}
        sidebarIsOpen={open}
        name="menu.cashier.main"
        icon={<AttachMoneyIcon />}
      >
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="orders/all"
          to="/orders/all"
          primaryText={translate("menu.cashier.all_orders")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="orders/unpaid"
          to="/orders/unpaid"
          primaryText={translate("menu.cashier.unpaid_orders")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="orders/completed"
          to="/orders/completed"
          primaryText={translate("menu.cashier.completed")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="orders/pending"
          to="/orders/pending"
          primaryText={translate("menu.cashier.final_payment")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="orders/huy_bill"
          to="/orders/huy_bill"
          primaryText={"Huy Bill"}
          onClick={onMenuClick}
        />
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="payments"
          to="/payments"
          primaryText={translate("menu.cashier.order_payments")}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => setmenuCutter(!menuCutter)}
        isOpen={menuCutter}
        sidebarIsOpen={open}
        name="menu.cutter.main"
        icon={
          <Icon
            path={mdiContentCut}
            title={translate("table_columns.cut")}
            size={1.5}
            horizontal
          />
        }
      >
        <MenuItemLink
          key="fabric_cuts/to_cut"
          to="/fabric_cuts/to_cut"
          primaryText={translate("menu.cutter.to_cut")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          key="fabric_cuts/cut"
          to="/fabric_cuts/cut"
          primaryText={translate("menu.cutter.completed")}
          onClick={onMenuClick}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => setmenuFactory(!menuFactory)}
        isOpen={menuFactory}
        sidebarIsOpen={open}
        name="menu.factory.main"
        icon={<ApartmentIcon />}
      >
        <MenuItemLink
          key="items/all"
          to="/items/all"
          primaryText={translate("menu.factory.items")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          key="items/unallocated"
          to="/items/unallocated"
          primaryText={translate("menu.factory.unallocated")}
          onClick={onMenuClick}
        />
        <MenuItemLink
          key="items/live"
          to="/items/live"
          primaryText={translate("menu.factory.live")}
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => setmenuPayment(!menuPayment)}
        isOpen={menuPayment}
        sidebarIsOpen={open}
        name="menu.payment.main"
        icon={<LocalPostOfficeIcon />}
        style={{ paddingLeft: 0 }}
      >
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="staff_payment"
          to="/staff_payments"
          primaryText={translate("table_columns.staff")}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => setmenuCommission(!menuCommission)}
        isOpen={menuCommission}
        sidebarIsOpen={open}
        name="menu.commission.main"
        icon={<BarChartIcon />}
        style={{ paddingLeft: 0 }}
      >
        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="commission/pending"
          to="/bas_order_commissions/pending"
          primaryText={translate("menu.commission.pending")}
        />

        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="commission/approved"
          to="/bas_order_commissions/approved"
          primaryText={translate("menu.commission.approved")}
        />

        <MenuItemLink
          style={{ paddingLeft: 40 }}
          key="commission/rejected"
          to="/bas_order_commissions/rejected"
          primaryText={translate("menu.commission.rejected")}
        />
      </SubMenu>

      <div>
        <SubMenu
          handleToggle={() => setmenuUtil(!menuUtil)}
          isOpen={menuUtil}
          sidebarIsOpen={open}
          name="menu.util"
          icon={<SettingsIcon />}
        >
          <MenuItemLink
            key="measurements"
            to="/measurements"
            primaryText={translate("table_columns.measurements")}
            style={{ paddingLeft: 40 }}
          />
          <MenuItemLink
            key="collection"
            to="/collections"
            primaryText={translate("table_columns.collection")}
            style={{ paddingLeft: 40 }}
          />
          <MenuItemLink
            key="hotel"
            to="/hotels"
            primaryText={translate("table_columns.hotel")}
            style={{ paddingLeft: 40 }}
          />
          <MenuItemLink
            key="staff"
            to="/staffs"
            primaryText={translate("table_columns.staff")}
            style={{ paddingLeft: 40 }}
          />
          <MenuItemLink
            style={{ paddingLeft: 40 }}
            key="locations"
            to="/locations"
            primaryText={translate("table_columns.location")}
          />
          <MenuItemLink
            key="swatchbooks"
            to="/swatchbooks"
            primaryText={translate("table_columns.swatchbook")}
            style={{ paddingLeft: 40 }}
          />
          <SubMenu
            handleToggle={() => setmenuGarment(!menuGarment)}
            isOpen={menuGarment}
            sidebarIsOpen={open}
            name="table_columns.garment"
            icon={<ExpandLessIcon />}
            style={{ paddingLeft: 0 }}
          >
            <MenuItemLink
              style={{ paddingLeft: 40 }}
              key="garments"
              to="/garments"
              primaryText={translate("table_columns.garment_type")}
            />
            <MenuItemLink
              style={{ paddingLeft: 40 }}
              key="garment_options"
              to="/garment_options"
              primaryText={translate("table_columns.garment_options")}
            />
            <MenuItemLink
              style={{ paddingLeft: 40 }}
              key="garment_option_values"
              to="/garment_option_values"
              primaryText={translate("table_columns.garment_option_values")}
            />
            <MenuItemLink
              style={{ paddingLeft: 40 }}
              key="garment_fabrics"
              to="/garment_fabrics"
              primaryText={"Garment - Fabric"}
            />
            <MenuItemLink
              style={{ paddingLeft: 40 }}
              key="garment_option_value_fabrics"
              to="/garment_option_value_fabrics"
              primaryText={"Option Value - Fabric"}
            />
          </SubMenu>
        </SubMenu>
      </div>
    </div>
  );
};

export default Menu;

/*
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
//import SettingsIcon from "@material-ui/icons/Settings";
import { withRouter } from "react-router-dom";
import { translate, Responsive } from "react-admin";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import TextureIcon from "@material-ui/icons/Texture";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import StraightenIcon from "@material-ui/icons/Straighten";
//import { useLocale } from "react-admin";
//import Configuration from "../../configuration/Configuration";
import DashboardMenuItem from "./DashboardMenuItem";
import MenuItemLink from "./MenuItemLink";

//import { usePermissions } from "react-admin";

import SubMenu from "./SubMenu";

class Menu extends Component {
  state = {
    menuCashier: false,
    menuCustomers: false,
    menudeliveries: false,
    menuCutter: false
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object
  };

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, logout, translate, permissions } = this.props;
    //const { permissions } = usePermissions();

    return (
      <div>
        {permissions === "admin" && (
          <DashboardMenuItem onClick={onMenuClick} name="Dashboard" />
        )}
        <SubMenu
          handleToggle={() => this.handleToggle("menuCashier")}
          isOpen={this.state.menuCashier}
          sidebarIsOpen={open}
          name="menu.cashier.main"
          icon={<AttachMoneyIcon />}
          style={{ paddingLeft: 0 }}
        >
          <MenuItemLink
            key="orders/"
            to="/orders/"
            primaryText="All Orders"
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="payments"
            to="/payments"
            primaryText="Payments"
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="orders/allorders"
            to="/orders/allorders"
            primaryText={translate(`menu.cashier.all_orders`)}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="orders/unpaid"
            to="/orders/unpaid"
            primaryText={translate(`menu.cashier.unpaid_orders`)}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="orders/final_payment"
            to="/orders/final_payment"
            primaryText={translate(`menu.cashier.final_payment`)}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="orders/live"
            to="/orders/live"
            primaryText={translate(`menu.cashier.live_orders`)}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="orders/completed"
            to="/orders/completed"
            primaryText={translate(`menu.cashier.completed`)}
            onClick={onMenuClick}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => this.handleToggle("menudelivery")}
          isOpen={this.state.menudelivery}
          sidebarIsOpen={open}
          name="menu.cashier.delivery"
          icon={<MotorcycleIcon />}
        >
          <MenuItemLink
            key="deliveries/todeliver"
            to="/deliveries/todeliver"
            primaryText={translate("menu.cashier.to_deliver")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="deliveries/complete"
            to="/deliveries/complete"
            primaryText={translate("menu.cashier.delivery_complete")}
            onClick={onMenuClick}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => this.handleToggle("menuCutter")}
          isOpen={this.state.menuCutter}
          sidebarIsOpen={open}
          name="menu.cutter.main"
          icon={<TextureIcon />}
        >
          <MenuItemLink
            key="cutter/tocut"
            to="/cutter/tocut"
            primaryText={translate("menu.cutter.to_cut")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="/cutter/completed"
            to="/cutter/completed"
            primaryText={translate("menu.cutter.completed")}
            onClick={onMenuClick}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => this.handleToggle("menuFactory")}
          isOpen={this.state.menuFactory}
          sidebarIsOpen={open}
          name="menu.factory.main"
          icon={<PlayForWorkIcon />}
        >
          <MenuItemLink
            key="factory/unallocated"
            to="/factory/unallocated"
            primaryText={translate("menu.factory.unallocated_orders")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="factory/tailors"
            to="/factory/tailors"
            primaryText={translate("menu.factory.tailor_status")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="factory/liveorders"
            to="/factory/liveorders"
            primaryText={translate("menu.factory.live_orders")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="factory/tailor_scan"
            to="/factory/tailor_scan"
            primaryText={translate("menu.factory.tailor_scan")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="factory/qc"
            to="/factory/qc"
            primaryText={translate("menu.factory.qc")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="factory/completed"
            to="/factory/completed"
            primaryText={translate("menu.factory.completed")}
            onClick={onMenuClick}
          />
        </SubMenu>

        <SubMenu
          handleToggle={() => this.handleToggle("menuFitting")}
          isOpen={this.state.menuFitting}
          sidebarIsOpen={open}
          name="menu.fitting.main"
          icon={<StraightenIcon />}
        >
          <MenuItemLink
            key="fitting/today"
            to="/fitting/today"
            primaryText={translate("menu.fitting.fittings_today")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="fitting/upcoming"
            to="/fitting/upcoming"
            primaryText={translate("menu.fitting.upcoming_fittings")}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key="fitting/ongoing"
            to="/fitting/ongoing"
            primaryText={translate("menu.fitting.ongoing_fittings")}
            onClick={onMenuClick}
          />
        </SubMenu>

        <Responsive
        // xsmall={<Configuration style={{ marginLeft: 40 }} />}
        //medium={null}
        />
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme
  //locale: useLocale()
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

export default enhance(Menu);
*/
