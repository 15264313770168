import React from "react";

import { Filter, ReferenceInput, SelectInput } from "react-admin";

export const ItemFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        reference="item_statuses"
        source="status_id"
        //filter={{ id: { inq: [1, 2] } }}
      >
        <SelectInput optionText={(record) => record.status.toUpperCase()} />
      </ReferenceInput>
    </Filter>
  );
};
