import React from "react";

import {
  TextInput,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";

export const PaymentFilter = (props) => (
  <Filter {...props} variant="standard">
    {Number(localStorage.getItem("store_id")) === 0 && (
      <ReferenceInput
        label="table_columns.store"
        source="store_id"
        reference="stores"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}

    <ReferenceInput source="customer_id" reference="customers">
      <AutocompleteInput
        optionText={(choice) =>
          choice && `${choice.first_name} ${choice.last_name}`
        }
      />
    </ReferenceInput>

    <TextInput label="table_columns.order" source="id" />
    <TextInput label="Customer Last Name" source="customer_last_name" />
    <TextInput label="Customer Fist Name" source="customer_first_name" />
    <TextInput label="Customer Email" source="customer_email" />

    <ReferenceInput
      {...props}
      label="table_columns.staff"
      source="staff_id"
      reference="staffs"
      perPage={10000}
      //helperText="Select Staff"
      //sort={{ field: "staff", order: "ASC" }}
      //filterToQuery={searchText => ({ staffs: searchText })}
      limitChoicesToValue={true}
    >
      <AutocompleteInput optionText="nick_name" />
    </ReferenceInput>
  </Filter>
);
