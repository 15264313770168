import React from "react";

import { Filter, SearchInput, useTranslate, SelectInput } from "react-admin";

const GarmentFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput
        source="description.like"
        alwaysOn
        resetteable="true"
        placeholder={translate("table_columns.garment")}
      />
      <SelectInput
        source="gender"
        label="table_columns.gender"
        optionValue="_id"
        optionText="name"
        allowEmpty={false}
        choices={[
          { _id: "1", name: translate("table_columns.men") },
          { _id: "2", name: translate("table_columns.women") },
        ]}
      />
    </Filter>
  );
};

export default GarmentFilter;
