import React from "react";

import { Filter, ReferenceInput, AutocompleteInput } from "react-admin";

const GarmentOptionValueFabricFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        perPage={-1}
        reference="garment_option_values"
        source="garment_option_value_id"
        sort={{ field: "description", order: "ASC" }}
        allwaysOn
        allowEmpty={false}
      >
        <AutocompleteInput
          optionText="description"
          open={true}
          shouldRenderSuggestions={(val) => {
            return val && val.trim().length > 1;
          }}
        />
      </ReferenceInput>
    </Filter>
  );
};

export default GarmentOptionValueFabricFilter;
