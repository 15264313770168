import React from "react";
import { Typography } from "@material-ui/core";
import { bucket_url } from "../../globals";
import { Chip } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import indigo from "@material-ui/core/colors/indigo";
import blueGrey from "@material-ui/core/colors/blueGrey";

export const ImageURLField = ({ record = {} }) => {
  return record.image ? (
    <img
      src={
        bucket_url + "/containers/bebe-measurements/download/" + record.image
      }
      alt="Staff"
      width="150px"
    />
  ) : (
    <img
      width="150px"
      src={
        bucket_url + "/containers/bebe-measurements/download/placeholder.webp"
      }
      alt="Staff"
    />
  );
};
ImageURLField.defaultProps = { label: "image" };

export const CustomTextField = ({ record, source }) => {
  return record[source] ? (
    <Typography style={{ width: 100 }}>
      {record[source].length > 200
        ? `${record[source].slice(0, 200)}...`
        : record[source]}
    </Typography>
  ) : null;
};

export const DisplayField = ({ record, source }) => {
  let label;
  let backgroundColor = red[600];
  let color = "white";
  switch (record[source]) {
    case 1:
      label = "APP";
      backgroundColor = blueGrey[600];
      break;
    case 2:
      label = "Wordpress";
      backgroundColor = indigo[600];
      break;
    case 3:
      label = "Both";
      backgroundColor = green[600];
      break;
    case 4:
      label = "None";
      backgroundColor = red[600];
      break;
    default:
      label = "Not set";
      backgroundColor = "white";
      color = "black";

      break;
  }
  return <Chip label={label} style={{ backgroundColor, color }} />;
};
