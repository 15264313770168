import React from "react";
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  Create
} from "react-admin";

const StaffCreate = props => {
  const required = (message = "Required") => value =>
    value ? undefined : message;
  return (
    <Create {...props} title="resources.staff.fields.create_title">
      <SimpleForm>
        {/* <NumberInput source="type" defaultValue="1" style={styles.hidden} /> */}
        <TextInput
          source="first_name"
          label="resources.staff.fields.first_name"
          validate={required()}
        />
        <TextInput
          source="middle_name"
          label="resources.staff.fields.middle_name"
          validate={required()}
        />
        <TextInput
          source="last_name"
          label="resources.staff.fields.family_name"
          validate={required()}
        />
        <TextInput
          source="nick_name"
          label="resources.staff.fields.nick_name"
          validate={required()}
        />
        <ReferenceInput
          validate={required()}
          label="resources.staff.fields.type"
          source="type_id"
          reference="staff_types"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          validate={required()}
          label="resources.staff.fields.store"
          source="store_id"
          reference="stores"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <TextInput
          validate={required()}
          source="phone_number"
          label="resources.staff.fields.phone_number"
        />

        {/* <NumberInput source="base_salary"  label="resources.staff.fields.base_salary"  /> 

      <ImageInput
        source="pictures"
        label="resources.staff.fields.staff_image"
        accept="image/*"
      >
        <ImageField source="src" title="title" />
      </ImageInput>*/}
      </SimpleForm>
    </Create>
  );
};

export default StaffCreate;
