import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const MeasurementCreate = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;
  return (
    <Create {...props} title="Create Measurement">
      <SimpleForm>
        <TextInput
          source="type_eng"
          label="table_columns.name"
          validate={[required()]}
        />
        <TextInput
          source="type_viet"
          label="table_columns.vietnamese"
          validate={required()}
        />
        <NumberInput source="order" label="table_columns.order" />
        <TextInput
          multiline
          options={{ multiline: true, rows: 5 }}
          fullWidth
          source="description"
          label="table_columns.description"
        />
        <TextInput
          multiline
          options={{ multiline: true, rows: 5 }}
          fullWidth
          source="instructions"
          label="table_columns.instructions"
        />
        <SelectInput
          source="scope"
          label="Display on"
          choices={[
            { id: "1", name: "App" },
            { id: "2", name: "Wordpress" },
            { id: "3", name: "Both" },
            { id: "4", name: "None" },
          ]}
        />

        <ArrayInput source="garment_ids" label="table_columns.garment">
          <SimpleFormIterator>
            <ReferenceInput
              source="garment_id"
              reference="garments"
              label="table_columns.garment"
            >
              <SelectInput optionText={(record) => record.description} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default MeasurementCreate;
