import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  useCreate,
  useUpdate,
  useDelete,
  ImageInput,
  FormDataConsumer,
  ImageField,
} from "react-admin";
import { Divider } from "@material-ui/core";
import { ImageURLField } from "./components";

import { useFormState } from "react-final-form";
import _ from "lodash";

const MeasurementEdit = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;
  return (
    <Edit
      {...props}
      title="resources.staff.fields.edit_title"
      style={{ maxWidth: 1280, height: "100%" }}
    >
      <SimpleForm toolbar={<UserEditToolbar {...props} />}>
        <TextInput
          source="type_eng"
          label="table_columns.name"
          validate={required()}
        />
        <TextInput
          source="type_viet"
          label="table_columns.vietnamese"
          validate={required()}
        />
        <NumberInput source="order" label="table_columns.order" />

        <TextInput
          multiline
          options={{ multiline: true, rows: 5 }}
          fullWidth
          source="description"
          label="table_columns.description"
        />
        <TextInput
          multiline
          options={{ multiline: true, rows: 5 }}
          fullWidth
          source="instructions"
          label="table_columns.instructions"
        />
        <SelectInput
          source="scope"
          label="Display on"
          choices={[
            { id: "1", name: "App" },
            { id: "2", name: "Wordpress" },
            { id: "3", name: "Both" },
            { id: "4", name: "None" },
          ]}
        />
        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="image" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.images) {
              return <ImageURLField source="image" {...rest} />;
            }
          }}
        </FormDataConsumer>
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>

        <ArrayInput source="garment_ids" label="table_columns.garment">
          <SimpleFormIterator>
            <ReferenceInput
              source="garment_id"
              reference="garments"
              label="table_columns.garment"
            >
              <SelectInput optionText={(record) => record.description} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const UserEditToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  permissions,
  ...props
}) => (
  <Toolbar {...props}>
    <EditCustomButton label="ra.action.edit" redirect="show" />
  </Toolbar>
);

const EditCustomButton = ({ record, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;
  const [update] = useUpdate("measurements");

  const [createGarmentMeasurement] = useCreate("garment_measurements");
  const [updateGarmentMeasurement] = useUpdate("garment_measurements");
  const [deleteGarmentMeasurement] = useDelete("garment_measurements");

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }

    let garments = formState.values.garment_ids
      ? formState.values.garment_ids
      : [];

    update(
      {
        payload: {
          id: formState.values.id,
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.garment_ids.forEach((o) => {
            if (!_.find(garments, (oo) => oo.id === o.id)) {
              deleteGarmentMeasurement({
                payload: { id: o.id },
              });
            }
          });

          garments.forEach((o) => {
            if (o.id) {
              updateGarmentMeasurement({
                payload: {
                  id: o.id,
                  data: {
                    measurement_id: newRecord.id,
                    garment_id: o.garment_id,
                  },
                },
              });
            } else {
              createGarmentMeasurement({
                payload: {
                  data: {
                    measurement_id: newRecord.id,
                    garment_id: o.garment_id,
                  },
                },
              });
            }
          });
          notify("ra.notification.updated", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default MeasurementEdit;
