import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useRedirect,
  useNotify,
  useDelete,
  SaveButton,
  useUpdate,
  regex,
  useRefresh,
} from "react-admin";
import _ from "lodash";

import { useFormState } from "react-final-form";

const CollectionCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const validateName = regex(/^[a-zA-Z]+$/, "No special characters"); // no blank spaces
  const required = (message = "Required") => (value) =>
    value ? undefined : message;
  const onFailure = (error) => {
    console.log(error);
    notify(`Could not create question: ${error.message}`);
    refresh();
  };
  const onSuccess = (data) => {
    console.log(data);
  };

  return (
    <Create
      {...props}
      title="Create Collection"
      onFailure={onFailure}
      onSuccess={onSuccess}
    >
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <TextInput
          source="name"
          label="table_columns.name"
          validate={[validateName, required()]}
        />
        <TextInput
          source="description"
          label="table_columns.description"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

const UserCreateToolbar = ({ permissions, ...props }) => {
  console.log(props);
  return (
    <Toolbar {...props}>
      <SaveCustomButton label="ra.action.save" redirect="edit" />
    </Toolbar>
  );
};

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;

  const [create] = useCreate("collections");
  const [createFabricCollection] = useCreate("fabric_collections");
  const [deleteFabricCollection] = useDelete("fabric_collections");
  const [updateFabricCollection] = useUpdate("fabric_collections");

  const handleClick = () => {
    console.log(formState.values);
    if (!formState.valid) {
      return;
    }

    let fabrics = formState.values.fabric_ids
      ? formState.values.fabric_ids
      : [];

    console.log(fabrics, record.fabric_ids);

    create(
      {
        payload: {
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.fabric_ids &&
            record.fabric_ids.forEach((o) => {
              if (!_.find(fabrics, (oo) => oo.id === o.id)) {
                deleteFabricCollection({
                  payload: { id: o.id },
                });
              }
            });

          fabrics.forEach((o) => {
            console.log(o);

            if (o.id) {
              console.log("id");
              updateFabricCollection({
                payload: {
                  id: o.id,
                  data: {
                    collection_id: newRecord.id,
                    fabric_id: o.fabric_id,
                    order: o.order,
                  },
                },
              });
            } else {
              console.log("no id");
              createFabricCollection({
                payload: {
                  data: {
                    collection_id: newRecord.id,
                    fabric_id: o.fabric_id,
                    order: o.order,
                  },
                },
              });
            }
          });
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default CollectionCreate;
