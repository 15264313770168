export default {
  menu: {
    dashboard: "Dashboard",
    fabrics: "Fabrics",
    stock: "Stock",
    swatchbooks: "Swatch Books",
    locations: "Locations",
    suppliers: "Suppliers",
    types: "Types",
    colors: "Colors",
    swatchbook_locations: "Swatchbook Locations",
    configuration: "Configuration",
    vietnamese: "Vietnamese",
    english: "English",
    language: "Language",
    inventory: "Inventory",
    sales: "Sales",
    orders: "Orders",
    measurements: "Measurements",
    garments: "Garments",
    garments_types: "Types",
    staff: "Staff",
    garment_options: "Options",
    garment_option_values: "Option Values",
    garment_garment_options: "Type - Options",
    garment_option_option_values: "Option - Option Values",
    util: "Util",
    dictionary: "Dictionary",
    exchange_rates: "Exchange Rates",
    hotels: "Hotels",
    garment_measurements: "Garment - Measurements",
    tailor_specialties: "Tailor - Specialty",
    payment: {
      main: "Payments",
    },
    cashier: {
      order_payments: "Order Payments",

      main: "Cashier",
      all_orders: "All Orders",
      unpaid_orders: "Unpaid Orders",
      final_payment: "Final Payment",
      live_orders: "Live Orders",
      completed: "Completed",
      delivery: "Delivery",
      to_deliver: "To Deliver",
      delivery_complete: "Delivery Complete",
    },
    cutter: {
      main: "Cutter",
      to_cut: "To Cut",
      completed: "Completed",
    },
    factory: {
      main: "Factory",
      unallocated_orders: "Unallocated Orders",
      unallocated: "Unallocated",
      tailor_status: "Tailor Status",
      tailor_scan: "Tailor Scan",
      live_orders: "Live Orders",
      qc: "QC",
      completed: "Completed",
      items: "All items",
      live: "Live Items",
    },
    fitting: {
      main: "Fitting",
      fittings_today: "Fittings Today",
      upcoming_fittings: "Upcoming Fittings",
      ongoing_fittings: "Ongoing Fittings",
    },
    commission: {
      main: "Commission",
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected",
    },
  },
  grid: {
    code: "Code",
  },

  page_headers: {
    all_orders: "All Orders",
    unpaid_orders: "Unpaid Orders",
    final_payment: "Final Payments",
    cashier_live_orders: "Cashier Live Orders",
    order_complete: "Completed Orders",
    to_deliver: "To Deliver",
    delivery_complete: "Completed Deliveries",
    to_cut: "To Cut",
    cut_complete: "Completed Cuts",
    unallocated_orders: "Unallocated Orders",
    tailor_status: "Tailor Status",
    factory_live_orders: "Factory Live Orders",
    factory_completed_orders: "Factory Completed Orders",
    qc: "Quality Control",
    factory_complete: "",
    fittings_today: "Fittings Today",
    upcoming_fittings: "Upcoming Fittings",
    ongoing_fittings: "Ongoing Fittings",
  },

  table_columns: {
    instructions: "Instructions",
    descriotion: "Description",

    alias: "Alias",

    collection: "Collection",
    num_fabrics: "# Fabrics",

    item_price: "Item Price",
    supplier_code: "Supplier Code",

    fabric: "Fabric",
    swatchbook: "Swatch Book",

    subheading: "Subheading",
    search_customer: "Search Customer",
    index: "Index",
    men: "Men",
    women: "Women",
    base_preview: "Preview",
    gender: "Gender",
    preview: "Preview",

    garment_type: "Type",
    garment_options: "Options",
    garment_option_values: "Option Values",

    option: "Option",
    options: "Options",
    values: "values",

    measurements: "Measurements",
    image: "Image",
    vietnamese: "Vietnamese",
    english: "english",
    no_notes: "No Notes",
    name: "Name",
    active: "Active",
    amount_to_pay: "Amount",
    currency: "Currency",
    book_code: "Book / Code",
    commission: "Commission",
    linked_order: "Linked Order",
    main_seller: "Main Seller",
    order_summary: "Order Summary",
    save: "Save",
    save_and_pay: "Save and Pay",
    shipping: "Shipping",
    cash: "Cash",
    credit_card: "Credit Card",
    method: "Method",
    paid_with: "Paid with",
    payments: "Payments",
    is_main: "Is Main?",
    hotel_name: "Hotel Name",
    address: "Address",
    phone: "Phone",
    total_bill: "Total",
    pay_now: "Pay",
    payment_type: "Type",
    cashier: "Cashier",
    id: "Id",
    discount: "Discount",
    payment_info: "Payment Info",
    amount: "Amount",
    amount_usd: "Amount USD",
    order_info: "Order Info",
    book_number: "Book Number",
    code: "Code",
    date: "Date",
    staff: "Staff",
    email: "Email",
    first_name: "First Name",
    how_did_you_find_us: "How did you find us?",
    notes: "Notes",
    origin: "Customer Origin",
    hotel_room: "Hotel Room",
    other: "Other",
    last_name: "Last Name",
    customer: "Customer",
    status: "Status",
    price: "Total Bill",
    balance: "Balance",
    created: "Created",
    receipts: "Receipts",
    order: "Order",
    store: "Store",
    balance_usd: "Balance USD",
    receipt_reprint: "Receipt Reprint",
    deadline: "Deadline",
    tailor_deadline: "Tailor Deadline",
    items: "# Items",
    courier: "Courier",
    hotel: "Hotel",
    confirm: "Confirm",
    print_slip: "Print Slip",
    delivery_deadline: "Delivery Deadline",
    undo_delivery: "Undo Delivery",
    timestamp: "Timestamp",
    order_number: "Order Number",
    sales_staff: "Sales Staff",
    item_number: "Item Number",
    description: "Description",
    fabric_code: "Fabric Code",
    location: "Location",
    cut_location: "Cut From",
    thumbnail: "Thumbnail",
    cut: "Cut",
    extra: "Extra",
    cut_uncut: "Cut/Uncut",
    undo_cut: "Undo Cut",
    item: "Item",
    allocate: "Allocate",
    garment: "Garment",
    fitting: "Fitting",
    tailor: "Tailor",
    specialty: "Specialty",
    today: "Today",
    week: "Week",
    now: "Now",
    latest_garment: "Latest Garment",
    last_day: "Last Day",
    received: "Received",
    next_fit: "Next Fit",
    first_qc: "First QC",
    final_qc: "Final QC",
    schedule: "Schedule",
    alert: "Alert",
    arrived: "Arrived",
    garment_ok: "Garment OK",
    completed: "Completed",
    days: "Days",
    creation_date: "Creation Date",
    update_date: "Update Date",

    cutter: "Cutter",
    cut_date: "Cut Date",
  },

  overlay: {
    payment: {
      total: "Total",
      deposit: "Deposit",
      linked_order: "Linked Order",
      remaining: "Remaining",
      cancel: "Cancel",
      print_receipt: "Print Receipt",
      cashier_notes: "Receipt Notes",
      delivery_notes: "Deliver Notes",
      amount: "Amount",
      notif_rel_orders: "Customer also has related orders",
      payments: "Payments",
      deposit_paid: "Deposit Paid",
      paid_with: "Paid With",
      balance: "Balance",
      delivery_deadline: "Delivery Deadline",
      in_store_pick_up: "In Store Pick Up",
    },
    cutter: {
      verify_cuts: "Verify Cuts",
      ppt: "Please pass to",
      confirmed_by: "Confirmed By",
      confirm_cuts: "Confirm Cuts",
      cancel: "Cancel",
      validation_error: "Who confirmed these cuts?",
    },
    factory: {
      unallocated: {
        assign_tailor_to_item: "Assign Tailor to Item",
        confirm_allocation: "Confirm Allocation",
        item_number: "Item #",
        allocated_to: "Allocated To",
        tailor_deadline: "Tailor Deadline",
        sapi: "Same as Previous Item",
        allocate: "Allocate",
      },
    },
  },

  resources: {
    welcome: "Please use the menu on the left",
    staff: {
      fields: {
        staff_list: "Staff List",
        first_name: "First Name",
        last_name: "Last Name",
        type: "Staff Type",
        store: "Store",
        staff_image: "Staff Image",
        create_title: "Create new Staff",
        edit_title: "Edit Staff",
        show_title: "Staff Details",
        phone_number: "Phone Number",
        base_salary: "Base Salary",
        middle_name: "Middle Name",
        family_name: "Family Name",
        nick_name: "Nick Name",
        image: "Image",
        specialties: "Specialty",
        staff_image_thumbnail: "Thumbnail Staff Image",
        username: "username",
      },
    },
    measurements: {
      name: "Measurements List",
      fields: {
        type_viet: "Type Viet",
        type_eng: "Type Eng",
        alias: "Alias",
        minimum: "Min Value",
        maximum: "Max Value",
        step: "Step",
        order: "Order",
        default: "Default Value",
      },
    },
    garment: {
      show_title: "Garment Option",
      name: "Option Name",
      order: "Option Order",
      option_values: "Option Values",
      option_value: "Option Value",
      option: "Option Value Name",
      image: "Image",
      description: "Description",
      option_description: "Option Description",
      show: "Garment Type",
      measurements: "Measurements",
      vietnamese: "Vietnamese",
      garment_name: "Garment Name",
      alias: "Alias",
      create_option: "Create Option",
      value_name: "Option Value Name",
      option_value_title: "Option Values",
      create_value: "Create Option Value",
      edit_value: "Edit Option Value",
    },
    garment_types: {
      list: "Garment Types List",
      name: "Name",
      description: "Description",
      gender: "Gender",
      image: "Image",
      alias: "Alias",
      vietnamese: "Vietnamese",
    },
    garment_option: {
      list: "Garment Option List",
      name_value: "Garment Option Value List",
      name_option: "Garment Option List",
      fields: {
        name: "Name",
        description: "Description",
        order: "Order",
        image: "Image",
        create_value: "Create Garment Option Value",
        edit_value: "Edit Garment Option Value",
        shot_value: "Garment Option Value",
        create_option: "Create Garment Option",
        edit_option: "Edit Garment Option",
        shot_option: "Garment Option",
        option: "Option",
        option_values: "Option Values",
        vietnamese: "Vietnamese",
      },
    },
    fabrics: {
      name: "Fabric List",
      fields: {
        unique_code: "Unique Code",
        type: "Type",
        supplier_code: "Supplier Code",
        location: "Location",
        locations: "Locations",
        swatchbook: "Swatch Book",
        total_stock: "Total Stock",
        total_extra_fabric: "Total extra fabric",
        stock: "Stock",
        extra_fabric: "Extra Fabric",
        image: "Image",
        price: "Price",
        supplier: "Supplier",
        color: "Color",
        color_depth: "Color Depth",
        notes: "Notes",
        creation_date: "Creation Date",
        update_date: "Update Date",
        fabric: "Fabric",
        quantity: "Quantity",
        extra: "Extra Fabric",
        create_stock: "Add Stock",
        create_swatchbook: "Create Swatch Book",
        create_swatchbook_location: "Add Swatchbook to a Location",
        stock_list: "Stock List",
        fabric_list: "Fabric List",
        location_list: "Location List",
        swatchbook_list: "Swatch Book List",
        supplier_list: "Supplier List",
        type_list: "Type List",
        color_list: "Color List",
        swatchbook_location_list: "Swatchbook Location list",
        stock_by_fabric_location: "Stock by Fabric / Location",
        description: "Description",
        name: "Name",
        alias: "Alias",
        num_fabrics: "# Fabrics",
        width: "Width",
      },
    },
    dictionary: {
      id: "id",
      key: "key",
      english: "english",
      vietnamese: "vietnamese",
      dictionary_list: "Words",
      creation_date: "Creation date",
      edit_word: "Edit word",
      create_word: "Create word",
      show_word: "Word details",
    },
    hotel: {
      id: "Id",
      hotel_list: "Hotels",
      name: "Name",
      contact: "Contact",
      address: "Address",
      phone: "Phone",
      url: "URL",
      commission: "Commission",
      email: "email",
      create_hotel: "Create a new Hotel",
      show_hotel: "Hotel",
    },
    garment_measurement: {
      garment: "Garment Type",
      type_viet: "Measurement Vietnamese",
      type_eng: "Measurement English",
      list: "Garment - Measurement Relationship",
      create: "Create a garment type - measurement relationship",
      show: "Garment - Measurement",
      measurement: "Measurement",
      edit: "Edit a garment type - measurement relationship",
      order: "Order",
    },
    tailor_specialties: {
      list: "Tailor - Specialty list",
      name: "Name",
      nick_name: "Nick Name",
      garment: "Garment Specialty",
      edit: "Edit",
      show: "Show",
      creation_date: "Creation Date",
      create: "Create",
    },
    swatchbooks: {
      fabric_type: "Fabric Type",
    },
  },
};
