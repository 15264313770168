import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  TextField,
  FunctionField,
  ArrayField,
  ReferenceField,
  RichTextField,
  SimpleShowLayout,
  SimpleForm,
  FormDataConsumer,
  SelectInput,
  refreshView,
  useCreate,
  ReferenceInput,
} from "react-admin";
import { Link } from "react-router-dom";

import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import classnames from "classnames";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";

import { makeStyles } from "@material-ui/core/styles";

import {
  CustomDateField,
  //Store,
  //Staff,
  formatDecimals,
  CustomPagination,
} from "../globals";

//import { OrderFilter } from "./OrderFilter";
import { Typography } from "@material-ui/core";
//import { useTranslate } from "react-admin";

export const OrderCommissionList = ({ permissions, ...props }) => {
  /*const RowStyle = (record, index) => ({
    backgroundColor: record.linked_order_id ? "#ede7f6" : "white"
  });*/
  //const { status: status_string } = parse(props.location.search);
  //const status = status_string ? status_string : "";

  // const { disabled: disabled_string } = parse(props.location.search);
  // const disabled = disabled_string ? disabled_string : "";

  //const translate = useTranslate();
  //const [update] = useUpdate("orders");
  const [create] = useCreate("order_commissions");

  let filter =
    Number(localStorage.getItem("store_id")) === 0 ||
    Number(localStorage.getItem("store_id")) === 1
      ? null
      : { store_id: Number(localStorage.getItem("store_id")) };

  //For the menu
  //let statusFilter = status ? { status } : null;

  //let disabledFilter = disabled ? { disabled } : null;

  let filters = { ...filter }; //, ...statusFilter, ...disabledFilter };

  const handleCommissionChange = (order_id, status_commission_id) => {
    console.log(order_id, status_commission_id);

    create({
      payload: {
        data: {
          order_id,
          status_commission_id,
          staff_id: Number(localStorage.getItem("staff_id")),
        },
      },
    });

    refreshView();
  };

  return (
    <List
      {...props}
      // filters={<OrderFilter />}
      bulkActionButtons={false}
      sort={{ field: "id", order: "DESC" }}
      filter={filters}
      pagination={<CustomPagination />}
    >
      <Responsive
        //small={}
        medium={
          <Datagrid optimized expand={<ExpandPanel />}>
            {/*permissions === "admin" && (
              <TextField source="id" label="Order #" />
            )*/}
            <SimpleForm
              toolbar={<TestToolbar {...props} />}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.status_commission_id === null ? (
                    <ReferenceInput
                      label="table_columns.commission"
                      source="status_commission_id"
                      reference="status_commissions"
                      onChange={(event) => {
                        event.stopPropagation();
                        handleCommissionChange(formData.id, event.target.value);
                      }}
                    >
                      <SelectInput
                        optionText="status"
                        style={{ width: 30 }}
                        source="status_commission_id"
                        label=""
                        {...rest}
                      />
                    </ReferenceInput>
                  ) : formData.status_commission_id === 1 ? (
                    <DoneIcon style={{ color: green[600] }} />
                  ) : (
                    formData.status_commission_id === 2 && (
                      <ClearIcon style={{ color: red[600] }} />
                    )
                  )
                }
              </FormDataConsumer>
            </SimpleForm>
            <ReferenceField source="store_id" reference="stores" link={false}>
              <FunctionField
                render={(record) => (
                  <Tooltip title={record.name}>
                    <Avatar
                      alt={record.name}
                      style={{
                        backgroundColor: record.color,
                        color: "white",
                      }}
                    >
                      {record.alias}
                    </Avatar>
                  </Tooltip>
                )}
              />
            </ReferenceField>

            <CustomDateField
              source="timestamp"
              utc={true}
              formatedDate={true}
              label="table_columns.date"
            />

            <FunctionField
              label="table_columns.hotel"
              render={(record) => {
                return record.hotels.map(
                  (h) =>
                    h.active &&
                    !h.disabled && (
                      <div key={h.id}>
                        {h.hotel && <Typography>{h.hotel.name}</Typography>}
                        {h.room && (
                          <Typography
                            variant="caption"
                            color="textSecondary"
                          >{`Room: ${h.room}`}</Typography>
                        )}
                      </div>
                    )
                );
              }}
            />
            <FunctionField
              label="table_columns.book_code"
              render={(record) =>
                record.book && record.code ? (
                  <Typography
                    component={Link}
                    to={{ pathname: `/orders/${record.id}/show` }}
                    style={
                      record.link && record.link.length
                        ? { color: red["A200"] }
                        : {}
                    }
                  >{`${record.book} / ${_.padStart(
                    record.code,
                    7,
                    "0"
                  )}`}</Typography>
                ) : (
                  <Typography
                    component={Link}
                    to={{ pathname: `/orders/${record.id}/show` }}
                    style={{ color: red["A200"] }}
                  >
                    Pending
                  </Typography>
                )
              }
              sortBy="book"
            />
            {/*
            <Staff
              source="sellers"
              label="table_columns.staff"
              sortable={false}
            />

            
            <ArrayField source="staff_ids">
              <SingleFieldList>
                <FunctionField
                  render={record => (
                    <Tooltip title={record.name}>
                      <Avatar src={record.image} alt={record.name} />
                    </Tooltip>
                  )}
                />
              </SingleFieldList>
            </ArrayField>
*/}
            <FunctionField
              label="table_columns.customer"
              sortBy="customer_last_name"
              render={(record) => (
                <div>
                  <Typography>
                    {`${record.customer_first_name} ${record.customer_last_name}`}
                  </Typography>
                  <Typography variant="caption" component="b">
                    {record.customer_email
                      ? record.customer_email
                      : "No e-mail"}
                  </Typography>
                </div>
              )}
            />
            {/*
            <ArrayField
              source="origins"
              label="table_columns.origin"
              sortable={false}
            >x 
              <SingleFieldList linkType={false}>
                <ChipField source="source" />
              </SingleFieldList>
            </ArrayField>
*/}
            <FunctionField
              label="table_columns.total_bill"
              render={(record) => (
                <Typography>
                  ${formatDecimals(record.total_price - record.discount)}
                </Typography>
              )}
            />

            <FunctionField
              label="Balance"
              render={(record) => (
                <Typography>
                  $
                  {record.balance &&
                    formatDecimals(
                      record.balance.toFixed(record.balance % 2 === 0 ? 0 : 2)
                    )}
                </Typography>
              )}
            />
            {/*Ç<FunctionField
              label="Commision"
              render={record => <Typography>$10</Typography>}
          />*/}

            <StatusField
              label="table_columns.status"
              source="status"
              contained
            />
            {/*
            <Tooltip title="Linked Order">
              <FunctionField
                render={record =>
                  record.linked_order_id && (
                    <LinkIcon style={{ color: "#ff5252" }} />
                  )
                }
              />
              </Tooltip> */}
          </Datagrid>
        }
      />
    </List>
  );
};

const ExpandPanel = (props) => {
  return (
    <SimpleShowLayout {...props}>
      {props.record.notes && (
        <SimpleShowLayout>
          <RichTextField source="notes" />
        </SimpleShowLayout>
      )}

      {props.record.disableds.length && (
        <SimpleShowLayout>
          <ArrayField source="disableds" label="Huy Bill">
            <Datagrid>
              <TextField label="reason" source="reason" />
              <CustomDateField
                utc={false}
                label="table_columns.creation_date"
                source="creation_date"
                formatedDate={true}
              />
              <ReferenceField
                source="staff_id"
                label="table_columns.staff"
                reference="sellers"
              >
                <FunctionField
                  render={(record) => (
                    <Tooltip title={record.name}>
                      <Avatar alt={record.name} src={record.image}>
                        {record.name}
                      </Avatar>
                    </Tooltip>
                  )}
                />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      )}
    </SimpleShowLayout>
  );
};

const useStyles = makeStyles({
  unpaid: { color: "white", backgroundColor: red[600] },
  completed: { color: "white", backgroundColor: green[600] },
  pending_final_payment: { color: "white", backgroundColor: orange[600] },
  flat: { boxShadow: "none" },
});
const StatusField = (props) => {
  const classes = useStyles();
  return (
    <Tooltip title={props.record[props.source]}>
      <Avatar
        className={classnames({
          [classes.unpaid]: props.record[props.source] === "unpaid",
          [classes.completed]: props.record[props.source] === "completed",
          [classes.pending_final_payment]:
            props.record[props.source] === "pending_final_payment",
        })}
      >
        {props.record[props.source] === "unpaid" && <Typography>U</Typography>}
        {props.record[props.source] === "pending_final_payment" && (
          <Typography>D</Typography>
        )}
        {props.record[props.source] === "completed" && (
          <Typography>C</Typography>
        )}
      </Avatar>
    </Tooltip>
  );
};

export default OrderCommissionList;

const TestToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate = null,
  permissions,
  ...props
}) => null;
