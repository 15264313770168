// in src/swatchbooks.js
import React from "react";

import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ListButton,
  TopToolbar,
  BooleanInput,
} from "react-admin";
const SwatchbookEdit = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;
  return (
    <Edit {...props} actions={<ListActions />}>
      <SimpleForm>
        <TextInput
          label="resources.fabrics.fields.unique_code"
          source="unique_code"
          disabled
        />
        <ReferenceInput
          reference="fabric_types"
          source="type_id"
          sort={{ field: "alias", order: "ASC" }}
          allowEmpty={false}
          perPage={-1}
          validate={required()}
        >
          <AutocompleteInput
            optionText={(record) =>
              record && `${record.alias} - ${record.description}`
            }
          />
        </ReferenceInput>
        <TextInput source="description" label="table_columns.description" />
        <BooleanInput source="is_virtual" label="Is Virtual?" />
      </SimpleForm>
    </Edit>
  );
};
const ListActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default SwatchbookEdit;
