import React from "react";

import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ImageField,
  ReferenceField,
} from "react-admin";

import { CustomPagination } from "../../globals";

import GarmentFilter from "./GarmentFilter";

const GarmentList = (props) => {
  return (
    <List
      {...props}
      filters={<GarmentFilter />}
      title="resources.garment_types.list"
      sort={{ field: "alias", order: "ASC" }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      style={{ maxWidth: 1280, height: "100%" }}
    >
      <Datagrid>
        <TextField label="resources.garment_types.name" source="name" />
        <TextField label="resources.garment_types.alias" source="alias" />
        <TextField
          label="resources.garment_types.description"
          source="description"
        />
        <TextField
          label="resources.garment_types.vietnamese"
          source="vietnamese"
        />
        {/* <FunctionField
          label="table_columns.gender"
          render={(record) => (record.gender === 1 ? "Men" : "Women")}
       /> */}

        <TextField source="order" label="table_columns.order" />

        <ImageField source="image" label="table_columns.image" />

        <ArrayField source="option_ids" label="table_columns.options">
          <SingleFieldList
            style={{
              display: "block",
              marginTop: 5,
              marginBottom: 5,
            }}
            linkType={false}
          >
            <ReferenceField
              source="option.id"
              reference="garment_options"
              link="show"
            >
              <FunctionField
                render={(record) => <div>{record.description}</div>}
              />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField>

        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default GarmentList;
