import React from "react";

import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ReferenceField,
} from "react-admin";

import { CustomPagination } from "../../globals";

import GarmentOptionFilter from "./GarmentOptionFilter";
const GarmentOptionList = (props) => (
  <List
    {...props}
    filters={<GarmentOptionFilter />}
    title="resources.garment_option.list"
    sort={{ field: "name", order: "ASC" }}
    pagination={<CustomPagination />}
    bulkActionButtons={false}
    style={{ maxWidth: 1280, height: "100%" }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField label="resources.garment_option.fields.name" source="name" />
      <TextField
        label="resources.garment_option.fields.description"
        source="description"
      />
      <TextField
        label="resources.garment_option.fields.vietnamese"
        source="vietnamese"
      />

      <ArrayField source="value_ids" label="table_columns.values">
        <SingleFieldList
          style={{ display: "block", marginTop: 5, marginBottom: 5 }}
          linkType={false}
        >
          <ReferenceField
            link={"show"}
            component="div"
            source="garment_option_value_id"
            reference="garment_option_values"
            sort={{ field: "value.name", order: "ASC" }}
          >
            <FunctionField
              render={(record) => {
                return `${record.id} - ${record.description}`;
              }}
            />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>

      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
export default GarmentOptionList;
