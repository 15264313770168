//import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import GarmentCreate from "./GarmentCreate";
import GarmentEdit from "./GarmentEdit";
import GarmentList from "./GarmentList";
import GarmentShow from "./GarmentShow";

export default {
  create: GarmentCreate,
  edit: GarmentEdit,
  list: GarmentList,
  show: GarmentShow,
};
