// in src/garments.js
import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  NumberInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useUpdate,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  useTranslate,
  useDelete,
  useCreate,
} from "react-admin";
import { Typography } from "@material-ui/core";

import { useFormState } from "react-final-form";
import { Divider } from "@material-ui/core";
import _ from "lodash";

const GarmentEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title="Edit Garment"
      style={{ maxWidth: 1280, height: "100%" }}
    >
      <SimpleForm toolbar={<UserEditToolbar {...props} />}>
        <TextInput source="name" label="Garment Name" />
        <TextInput source="description" label="Description" />
        <TextInput source="alias" label="Alias" />
        <TextInput source="vietnamese" label="Vietnamese" />

        <NumberInput source="order" label="Order" />
        <SelectInput
          source="gender"
          label="Gender"
          choices={[
            { id: "1", name: "Male" },
            { id: "2", name: "Female" },
          ]}
        />

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.image").toUpperCase()}
          </Typography>
        </Fragment>

        <ImageField source="image" title="title" />

        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>

        <ImageField source="preview" title="title" />

        <ImageInput
          source="previews"
          label="table_columns.base_preview"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.options").toUpperCase()}
          </Typography>
        </Fragment>

        <ArrayInput source="option_ids" label="">
          <SimpleFormIterator>
            <ReferenceInput
              source="garment_option_id"
              reference="garment_options"
              label="table_columns.option"
            >
              <SelectInput optionText="description" />
            </ReferenceInput>
            <NumberInput source="index" label="table_columns.index" />
            <SelectInput
              source="default_preview"
              label="Default Preview"
              choices={[
                { id: "front", name: "Front" },
                { id: "back", name: "Back" },
                { id: "detail", name: "Detail" },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
        {/*
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.measurements").toUpperCase()}
          </Typography>
</Fragment> */}
      </SimpleForm>
    </Edit>
  );
};

const UserEditToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  permissions,
  ...props
}) => (
  <Toolbar {...props}>
    <EditCustomButton label="ra.action.edit" redirect="show" />
  </Toolbar>
);

const EditCustomButton = ({ record, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;
  const [update] = useUpdate("garments");
  const [deleteOption] = useDelete("garment_garment_options");
  const [updateOption] = useUpdate("garment_garment_options");
  const [createOption] = useCreate("garment_garment_options");

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    let options = formState.values.option_ids
      ? formState.values.option_ids
      : [];

    update(
      {
        payload: {
          id: formState.values.id,
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.option_ids.forEach((o) => {
            if (!_.find(options, (oo) => oo.id === o.id)) {
              deleteOption({
                payload: { id: o.id },
              });
            }
          });

          options.forEach((o) => {
            if (o.id) {
              updateOption({
                payload: {
                  id: o.id,
                  data: {
                    garment_id: newRecord.id,
                    garment_option_id: o.garment_option_id,
                    index: o.index,
                    default_preview: o.default_preview,
                  },
                },
              });
            } else {
              createOption({
                payload: {
                  data: {
                    garment_id: newRecord.id,
                    garment_option_id: o.garment_option_id,
                    index: o.index,
                    default_preview: o.default_preview,
                  },
                },
              });
            }
          });
          notify("ra.notification.updated", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  /*, [
      formState.valid,
      formState.values,
      create,
      notify,
      redirectTo,
      redirect,
      basePath
    ]*/
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default GarmentEdit;
