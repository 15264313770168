// in src/garments.js
import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  ListButton,
  TopToolbar,
  ImageField,
  ImageInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from "react-admin";
import { Divider } from "@material-ui/core";

const GarmentOptionValueFabricCreate = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;
  return (
    <Create
      {...props}
      title="Create New Garment Option Value"
      actions={<Actions />}
    >
      <SimpleForm>
        <ReferenceInput
          reference="garment_option_values"
          source="garment_option_value_id"
          sort={{ field: "description", order: "ASC" }}
          perPage={-1}
          validate={required()}
        >
          <AutocompleteInput optionText="description" />
        </ReferenceInput>

        <ReferenceInput
          reference="fabrics"
          source="fabric_id"
          sort={{ field: "unique_code", order: "ASC" }}
          perPage={-1}
          validate={required()}
          filter={{ type_id: { inq: [3, 120] } }}
        >
          <AutocompleteInput
            open={true}
            optionText="unique_code"
            shouldRenderSuggestions={(val) => {
              return val.trim().length > 4;
            }}
          />
        </ReferenceInput>

        <TextInput source="description" fullWidth />
        <ImageInput
          source="preview_fronts"
          label="FRONT PREVIEW"
          accept="image/*"
        >
          <ImageField source="preview_front" title="title" />
        </ImageInput>
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>

        <ImageInput
          source="preview_backs"
          label="BACK PREVIEW"
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="preview_back" title="title" />
        </ImageInput>

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>

        <ImageInput
          source="preview_details"
          label="DETAIL PREVIEW"
          accept="image/*"
          mulitple={true}
        >
          <ImageField source="preview_detail" title="title" />
        </ImageInput>
        {/* <TextInput source="name" label="resources.garment.value_name" />
        <TextInput source="description" label="resources.garment.description" />
        <TextInput source="vietnamese" label="resources.garment.vietnamese" />
        <TextInput source="subheading" label="table_columns.subheading" />
        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>
        <ImageInput
          source="previews"
          label="table_columns.preview"
          accept="image/*"
        >
          <ImageField source="preview" title="title" />
  </ImageInput> */}
      </SimpleForm>
    </Create>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentOptionValueFabricCreate;
