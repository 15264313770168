// in src/templates/factory.js
import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";
import moment from "moment";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DateTimePicker from "react-datetime-picker";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import "../assets/css/bootstrap.min.css";
import swal from "sweetalert2";
import QRScanner from "./classes/qrscanner";
import store, {
  api_uri,
  refreshRecord,
  OrderLink,
  ItemLink,
  GlobalIFU,
  //clearClearables,
  StaffLink,
  TailorLink,
  StatusSpan,
  TailorLoad,
  StatusText,
  styles,
  CustomDateField,
  StoreLink
} from "../globals";
import Logo from "../assets/bebe-logo.png";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";

import { FactoryFilters, QCFilters } from "./filters";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress, DialogActions } from "@material-ui/core";

export const FactoryUnallocatedList = props => (
  <div>
    <List
      {...props}
      bulkActionButtons={false}
      title="page_headers.unallocated_orders"
      filters={<FactoryFilters />}
      filter={
        Number(localStorage.getItem("store_id")) === 0
          ? null
          : { store_id: Number(localStorage.getItem("store_id")) }
      }
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <OrderLink label="table_columns.order" source="order_id" />
        <ItemLink
          label="table_columns.item"
          source="item_id"
          style={{ textAlign: "center" }}
        />
        <StoreLink label="table_columns.store" source="store" />
        <CustomDateField label="table_columns.created" source="timestamp" />
        <StaffLink label="table_columns.staff" source="staff" />
        <TextField label="table_columns.garment" source="description" />
        <CustomDateField
          label="table_columns.fitting"
          source="fitting_date"
          alert={true}
        />
        <CustomDateField
          label="table_columns.last_day"
          source="last_day"
          utc={false}
          format={"MMM DD"}
          alert={true}
        />
        <AllocateItem label="table_columns.allocate" sortable={false} />
      </Datagrid>
    </List>
    <GlobalIFU screen="Factory: Unallocated" />
  </div>
);

export const FactoryTailorList = props => (
  <List {...props} title="page_headers.tailor_status" bulkActionButtons={false}>
    <Datagrid>
      <TailorLink label="table_columns.tailor" source="name" />

      <TailorLoad label="table_columns.today" source="items_day" />
      <TailorLoad label="table_columns.week" source="items_week" />
      <TailorLoad label="table_columns.now" source="items_inprog" />
      <DateField
        label="table_columns.latest_garment"
        source="latest_garment"
        showTime={true}
        options={{
          timeZone: "UTC",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        }}
      />
    </Datagrid>
  </List>
);

export const FactoryLiveList = props => (
  <div>
    <List
      {...props}
      bulkActionButtons={false}
      title="page_headers.factory_live_orders"
      filters={<FactoryFilters />}
      filter={
        Number(localStorage.getItem("store_id")) === 0
          ? null
          : { store_id: Number(localStorage.getItem("store_id")) }
      }
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <OrderLink label="table_columns.order" source="order_id" />
        <ItemLink label="table_columns.item" source="item_id" />
        <StoreLink label="table_columns.store" source="store" />
        <CustomDateField
          label="table_columns.creation_date"
          source="timestamp"
        />
        <StaffLink label="table_columns.staff" source="staff" />
        <TailorLink label="table_columns.tailor" source="tailor" />

        <TextField label="table_columns.garment" source="description" />
        <CustomDateField
          label="table_columns.tailor_deadline"
          source="completion_time"
          alert={true}
        />
        <CustomDateField
          label="table_columns.fitting"
          source="fitting_date"
          utc={false}
          alert={true}
        />
        <CustomDateField
          label="table_columns.last_day"
          source="last_day"
          utc={false}
          alert={true}
          format={"MMM DD"}
        />
        <StatusText label="table_columns.status" source="status" />
      </Datagrid>
    </List>
    <GlobalIFU screen="Factory: Live Orders" />
  </div>
);

export const FactoryQCList = props => (
  <div>
    <div className="item-input-set">
      <QRScanner />
      <InitializeSalesStaff />
    </div>
    <List
      {...props}
      bulkActionButtons={false}
      title="page_headers.qc"
      filters={<QCFilters />}
      filter={
        Number(localStorage.getItem("store_id")) === 0
          ? null
          : { store_id: Number(localStorage.getItem("store_id")) }
      }
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <OrderLink label="table_columns.order" source="order_id" />
        <ItemLink label="table_columns.item" source="item_id" />
        <StoreLink label="table_columns.store" source="store" />
        <StaffLink label="table_columns.staff" source="staff" />

        <CustomDateField label="table_columns.received" source="timestamp" />
        <CustomDateField
          label="table_columns.next_fit"
          source="next_fit"
          alert={true}
        />
        <TailorLink label="table_columns.tailor" source="tailor" />
        <TextField label="table_columns.garment" source="description" />
        <StatusSpan label="table_columns.status" source="status" />
        <FirstQC />
        <FirstQCThumbs
          label="table_columns.first_qc"
          source="type"
          style={{ textAlign: "center", width: 100 }}
          headerStyle={{ textAlign: "center", width: 100 }}
        />
        <FinalQCThumbs
          label="table_columns.final_qc"
          source="type"
          style={{ textAlign: "center", width: 100 }}
          headerStyle={{ textAlign: "center", width: 100 }}
        />
        <CustomDateField
          label="table_columns.delivery_deadline"
          source="delivery_deadline"
          alert={true}
        />
      </Datagrid>
    </List>
    <div style={styles.lightbox} id="lightbox">
      <div style={styles.lightbox.bg} />
      <div style={styles.lightbox.body} className="lb-body">
        <center style={styles.lightbox.body.smallcontent}>
          <h3>Reject Final QC</h3>
          <hr />
          <div>
            <strong>Ask customer for new fitting?</strong>{" "}
            <input
              type="checkbox"
              id="cb-newFitting"
              onClick={force => toggleManETA(false)}
            />
          </div>
          <div id="div-maneta" className="el-hide">
            <h4>New Fitting Time</h4>
            {/*  <DTPicker callback={eta => genNewFitTime(eta)} />*/}
          </div>

          <h4>Reason</h4>
          <textarea id="ta-reason" className="form-control" rows="4" />
          <hr />

          <div className="form-footer">
            <input
              type="button"
              onClick={hideModal}
              className="btn btn-danger pull-left"
              value="Cancel"
            />
            <input
              id="btn-cb"
              type="button"
              onClick={function() {}}
              className="btn btn-success pull-right"
              value="Back to Tailor"
            />
          </div>
        </center>
      </div>
    </div>
    <GlobalIFU screen="Factory: QC" />
  </div>
);

export const FactoryCompletedList = props => (
  <div>
    <List
      {...props}
      bulkActionButtons={false}
      title="page_headers.factory_completed_orders"
      filters={<FactoryFilters />}
      filter={
        Number(localStorage.getItem("store_id")) === 0
          ? null
          : { store_id: Number(localStorage.getItem("store_id")) }
      }
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <OrderLink label="table_columns.order" source="order_id" />
        <ItemLink label="table_columns.item" source="id" />
        <StoreLink label="table_columns.store" source="store" />
        <StaffLink label="table_columns.staff" source="staff" />
        <TailorLink label="table_columns.tailor" source="tailor" />

        <CustomDateField
          label="table_columns.creation_date"
          source="timestamp"
        />
        <CustomDateField
          label="table_columns.completed"
          source="completion_time"
        />
        <TextField label="table_columns.garment" source="description" />
      </Datagrid>
    </List>
    <GlobalIFU screen="Factory: Live Orders" />
  </div>
);

/*
function FactoryUnallocatedListOverlay({record, translate,...props})
{
  const [deadline, setDeadline] = React.useState(null);
  const [newdeadline, setNewdeadline] = React.useState();

  

    return(
      <div>
        <div style={styles.lightbox} id="lightbox">
          <div style={styles.lightbox.bg} />
            <div style={styles.lightbox.body} className="lb-body">
              <center style={styles.lightbox.body.content}>
                <Grid container justify="center" alignItems="center" direction="column">
                  <Grid item style={{marginBottom:"10px"}}>
                    <Typography variant="headline" component="h2">
                      {translate('overlay.factory.unallocated.assign_tailor_to_item')} #{record.item}
                    </Typography>
                  </Grid>
                </Grid>
                <span onClick={hideModal} style={styles.modalclose}>
                  <i className="fas fa-times" />
                </span>
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>{translate('table_columns.tailor')}</th>
                        <th>{translate('table_columns.specialty')}</th>
                        <th>{translate('table_columns.today')}</th>
                        <th>{translate('table_columns.week')}</th>
                        <th>{translate('table_columns.now')}</th>
                        <th>{translate('table_columns.latest_garment')}</th>
                      </tr>
                    </thead>
                    <tbody id="tailors-tblbody" />
                  </table>
                </div>
              </center>
            </div>
          </div>

        <div style={styles.lightbox} id="lightbox-allocate">
          <div style={styles.lightbox.bg} />
          <div style={styles.lightbox.body} className="lb-body">
            
            <div style={styles.lightbox.body.smallcontent}>

             
              <Grid container justify="center" alignItems="center" direction="column" style={{padding:"0px"}}>
                <Grid item style={{marginBottom:"30px"}}>
                  <Typography variant="headline" component="h2">{translate('overlay.factory.unallocated.confirm_allocation')}</Typography>
                </Grid>
       

                <Grid container justify="center" alignItems="center" style={{margin:10}}>
                  <Grid item xs={4}>
                  <Typography>{translate('overlay.factory.unallocated.item_number')}: </Typography>
                  </Grid>
                  <Grid item xs={8}>{record.item}</Grid>
                </Grid>

                <Grid container justify="center" alignItems="center" style={{margin:10}}>
                  <Grid item xs={4}>
                  <Typography>Fitting: </Typography>
                  </Grid>
                  <Grid item xs={8}><Typography>{moment(record.fitting_time).format('DD/MM/YYYY, h:mm:ss a')}</Typography></Grid>
                </Grid>

                <Grid container style={{margin:10}}>
                  <Grid item xs={4} style={{alignSelf:"center"}}>
                  <Typography>{translate('overlay.factory.unallocated.allocated_to')}: </Typography>
                  </Grid>
                  <Grid item xs={8} style={{display:"flex", alignItems:"center"}}>
                      <Avatar style={{marginRight:10}} src={record.tailor_thumbnail}></Avatar>
                      <Typography>{record.tailor}</Typography>
                    </Grid>
                </Grid>

                <Grid container style={{margin:10}}>
                <Grid item xs={4} style={{alignSelf:"center"}}>
                  <Typography>{translate('overlay.factory.unallocated.tailor_deadline')}: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={deadline ? deadline.toString():""} 
                        onChange={(date) => { setDeadline(date.target.value) }}
                      >
                        <FormControlLabel
                          value={moment(record.fitting_time).subtract(9,'hours').format('YYYY-MM-DD HH:mm:ss')}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography>Default:</Typography>
                              <Typography>{moment(record.fitting_time).subtract(9,'hours').format('DD/MM/YYYY, h:mm:ss a')}</Typography>
                          </div>
                          }
                        />
                        <FormControlLabel
                          value={record.previous_allocation}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography>Same as previous:</Typography>
                              <Typography>{moment(record.previous_allocation).format('DD/MM/YYYY, h:mm:ss a')}</Typography>
                            </div>}
                        />
                        <FormControlLabel
                          value={newdeadline ? newdeadline.toString() : moment(new Date()).format('DD/MM/YYYY, h:mm:ss a')}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography>New:</Typography>

                              <DateTimePicker onChange={date => {
                                setDeadline(date)
                                setNewdeadline(date)
                                }}
                                value={newdeadline ? newdeadline : new Date()}
                                clearIcon={null}
                                required={true}
                                disableClock={true}
                                locale="vi-VN"
                                />
                            </div>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <hr />
              <Grid container justify="space-between">
                <Grid item><Button
                  onClick={() => {
                      setDeadline(moment(record.fitting_time).subtract(9,'hours').format('YYYY-MM-DD HH:mm:ss'))
                      hideAllocateModal();
                    }}
                >Cancel
                  </Button></Grid>
                <Grid item>
                <Button
                  disabled={deadline === null}
                  color="primary"
                  variant="contained"
                  onClick={() =>{
                    assignToTailor(record,deadline)
                  }}
                >{translate('overlay.factory.unallocated.allocate')}
                  </Button>
                </Grid>

                
                
                  </Grid>
            </div>
          </div>
        </div>
      </div>
      )}

*/

class InitializeSalesStaff extends React.Component {
  constructor(props) {
    super(props);
    this.fetchCourriers();
  }
  fetchCourriers() {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        //console.dir(this.responseText);
        var staff = JSON.parse(this.responseText);
        store.sales_staff = [];
        for (var i = 0; i < staff.length; i++) {
          store.sales_staff[staff[i].id] = staff[i].nick_name;
          //console.dir(store.sales_staff);
        }
      }
    };
    xhttp.open("GET", api_uri + "/staffs/qc", false);
    xhttp.send();
  }
  render() {
    return <div />;
  }
}

var manualETA = false;

/*
var innerglobal_itn = 0;
var innerglobal_tid = 0;
var innerglobal_oid = 0;
*/
//var valETA = "";

const toggleManETA = (forceHide, appearCheck = false, forceShow = false) => {
  var dmeta = document.getElementById("div-maneta");
  if (forceHide) {
    dmeta.classList.add("el-hide");
    innerglobal_newfit = "none";
    return;
  } else if (forceShow) {
    //dmeta.classList.remove("el-hide");
    return;
  }
  if (appearCheck) manualETA = !document.getElementById("cb-meta").checked;
  else manualETA = dmeta.classList.contains("el-hide");
  if (manualETA) dmeta.classList.remove("el-hide");
  else {
    dmeta.classList.add("el-hide");
    innerglobal_newfit = "none";
  }
  //console.dir(manualETA + "==" + innerglobal_tid + "==" + innerglobal_itn);
};
/*
export const CutCompletedList = props => (
  <div>
    <List {...props} title="Completed Orders" perPage={100}
      filter={{ "store_id": localStorage.getItem("store_id") }}
    >
      <Datagrid>
        <TextField label="Timestamp" source="timestamp" />
        <TextField label="Sales Staff" source="staff" />
        <TextField label="Customer Name" source="customer_name" />
        <TextField label="Order Number" source="order_number" />
        <TextField label="Status" source="status" />
        <TextField label="Delivery Confirmed" source="courrier" />
        <TextField label="Price" source="price" />
      </Datagrid>
    </List>
    <GlobalIFU />
  </div>
);
*/

export const FactoryTailorScanner = props => (
  <div
    style={{
      display: "flex",
      flex: 1,
      backgroundColor: "white",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%"
    }}
  >
    <img
      src={Logo}
      alt="Welcome to Bebe"
      width="300"
      style={{ alignSelf: "center" }}
    />
    <h3 style={{ marginTop: 20, alignSelf: "center" }}>
      Please scan in items for QC / Fitting{" "}
    </h3>
    <div style={{ alignSelf: "center", marginTop: "15px" }}>
      <QRScanner />
    </div>
  </div>
);

var innerglobal_newfit = "none";
var innerglobal_newfitreason = "none";

/*
const genNewFitTime = eta => {
  try {
    var date = eta.toISOString().split("T")[0];
    var time = [eta.getHours(), eta.getMinutes(), "00"];
    time = time.join(":");
    date = date + " " + time;
    //console.dir(date);
    innerglobal_newfit = date;
  } catch (err) {}
};
*/
const FirstQC = ({ record }) => {
  const [open, setOpen] = React.useState(false);
  const [staff, setStaff] = React.useState(null);
  const [staffs, setStaffs] = React.useState(null);

  const fetchStaff = () => {
    fetch(api_uri + "/staffs/qc")
      .then(res => res.json())
      .then(staffs => {
        console.log(staffs);
        setStaffs(staffs);
      });
  };

  const confirmQC = (type, qc_id, result, order_id, extra, staff_id) => {
    let payload = {
      qc_id,
      result,
      order_id,
      type,
      extra,
      staff_id
    };
    console.log(payload);

    fetch(api_uri + "/factory/fireitemqc", {
      body: JSON.stringify(payload),
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" })
    }).then(response => refreshRecord());
  };

  const handleClose = () => setOpen(false);

  const handleAsign = record => {
    console.log(record);
    confirmQC(
      "first",
      record.id,
      true,
      record.order_id,
      { fitting: "none", reason: "" },
      staff
    );
  };

  const handleChange = event => {
    setStaff(event.target.value);
    record.staff = event.target.value;
  };

  const handleOpen = () => {
    setStaffs(fetchStaff);
    setOpen(true);
  };

  return record.type === "first" ? (
    <div>
      <IconButton color="primary" onClick={handleOpen}>
        <ThumbUpIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>First QC approved by:</DialogTitle>

        <DialogContent>
          <form>
            <FormControl
              style={{ flex: 1, display: "flex", alignItems: "center" }}
            >
              <Select
                style={{ width: 200 }}
                value={staff ? staff : -1}
                onChange={handleChange}
              >
                <MenuItem value={-1}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      alignContent: "center"
                    }}
                  >
                    <Avatar style={{ marginRight: 20 }}>NN</Avatar>
                    <Typography>Select Staff</Typography>
                  </div>
                </MenuItem>
                {staffs &&
                  _.sortBy(staffs, function(o) {
                    return o.nick_name;
                  }).map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          alignContent: "center"
                        }}
                      >
                        <Avatar
                          src={c.thumbnail_url}
                          alt={c.nick_name}
                          style={{ marginRight: 20 }}
                        />
                        <Typography>{c.nick_name}</Typography>
                      </div>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleAsign(record)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <Typography style={{ textAlign: "center", color: "green" }}>
      <DoneIcon style={{ color: "green" }} />
    </Typography>
  );
};

const FirstQCThumbs = ({ record = {} }) =>
  record.type === "first" ? (
    <div>
      <IconButton
        color="primary"
        onClick={(type, oid, msg, result, qc_id) =>
          confirmQC(
            "first",
            record.order_id,
            "First QC Passed?",
            true,
            record.id,
            "Item ready for Fitting"
          )
        }
      >
        <ThumbUpIcon />
      </IconButton>

      <IconButton
        color="primary"
        onClick={(type, oid, msg, result, qc_id) =>
          confirmQC(
            "first",
            record.order_id,
            "First QC Rejected?",
            false,
            record.id,
            "Return item to " + record.tailor
          )
        }
      >
        <ThumbDownIcon />
      </IconButton>
    </div>
  ) : (
    <Typography style={{ textAlign: "center", color: "green" }}>
      <DoneIcon style={{ color: "green" }} />
    </Typography>
  );

const FinalQCThumbs = ({ record = {} }) =>
  record.type === "final" && (
    <div>
      <IconButton
        color="primary"
        onClick={(type, oid, msg, result, qc_id) =>
          confirmQC(
            "final",
            record.order_id,
            "Final QC Passed?",
            true,
            record.id,
            "Item ready for Delivery, Confirm with cashier"
          )
        }
      >
        <ThumbUpIcon />
      </IconButton>

      <IconButton
        color="primary"
        onClick={() =>
          showModal((type, oid, msg, result, qc_id) =>
            confirmQC(
              "final",
              record.order_id,
              "Final QC Rejected for item " + record.item + "?",
              false,
              record.id,
              "Return item to " + record.tailor
            )
          )
        }
      >
        <ThumbDownIcon />
      </IconButton>
    </div>
  );

export const AllocateItem = ({ record }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpen = async () => {
    setIsLoading(true);
    await getTailors(record);
    setIsLoading(false);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  return isLoading ? (
    <CircularProgress />
  ) : (
    <div>
      <Button onClick={handleOpen}>Allocate</Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
          <Typography
            variant="h5"
            component="h5"
          >{`Allocate item #${record.item}`}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <AssignTailor record={record} close={handleClose} />
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-start">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const AssignTailor = ({ record, close }) => {
  const handleAssign = tailor => {
    //console.log(record)
    //close()
    setOpen(true);
    setTailor(tailor);
  };

  const handleClose = () => {
    setOpen(false);
    close();
  };
  const handleCloseAssign = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [tailor, setTailor] = React.useState(false);
  const [deadline, setDeadline] = React.useState(null);

  //console.log(record)
  return (
    <div>
      {record.tailors ? (
        <Table style={{ padding: 20 }}>
          <TableHead>
            <TableRow>
              <TableCell>Tailor</TableCell>
              <TableCell align="right">Specialty</TableCell>
              <TableCell align="right">Today</TableCell>
              <TableCell align="right">Week</TableCell>
              <TableCell align="right">Now</TableCell>
              <TableCell align="right">Latest Garment</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.tailors.map(tailor => (
              <TableRow key={tailor.id}>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "row",
                      alignItems: "center"
                    }}
                  >
                    <Avatar src={tailor.tailor_thumbnail} />
                    <Typography style={{ marginLeft: 10 }}>
                      {tailor.name}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell align="right">{tailor.specialty_alias}</TableCell>
                <TableCell align="right">{tailor.items_day}</TableCell>
                <TableCell align="right">{tailor.items_week}</TableCell>
                <TableCell align="right">{tailor.items_inprog}</TableCell>
                <TableCell align="right">
                  <CustomDateField record={tailor} source="latest_garment" />
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAssign(tailor)}
                  >
                    ASSIGN
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        style={{ padding: 20 }}
      >
        <DialogContent dividers>
          <Allocate
            record={record}
            tailor={tailor}
            deadline={deadline}
            setDeadline={setDeadline}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                onClick={() => {
                  setDeadline(
                    moment(record.fitting_time)
                      .subtract(9, "hours")
                      .format("YYYY-MM-DD HH:mm:ss")
                  );
                  handleCloseAssign();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={deadline === null}
                color="primary"
                variant="contained"
                onClick={() => {
                  assignToTailor(record, deadline, tailor);
                }}
              >
                Allocate
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Allocate = ({ record, tailor, deadline, setDeadline }) => {
  console.log(record, tailor);
  const [newdeadline, setNewdeadline] = React.useState(new Date());

  return (
    <div>
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item style={{ marginBottom: "10px" }}>
          <Typography variant="headline" component="h2">
            Assing item #{record.item}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{ padding: "0px" }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ margin: 10 }}
        >
          <Grid item xs={4}>
            <Typography>Fitting: </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {moment(record.fitting_time).format("DD/MM/YYYY, h:mm:ss a")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ margin: 10 }}>
          <Grid item xs={4} style={{ alignSelf: "center" }}>
            <Typography>Allocated to: </Typography>
          </Grid>
          <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{ marginRight: 10 }}
              src={tailor.tailor_thumbnail}
            ></Avatar>
            <Typography>{tailor.name}</Typography>
          </Grid>
        </Grid>

        <Grid container style={{ margin: 10 }}>
          <Grid item xs={4} style={{ alignSelf: "center" }}>
            <Typography>Tailor Deadline: </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl component="fieldset">
              <RadioGroup
                value={deadline ? deadline.toString() : ""}
                onChange={date => {
                  console.log(date.target.value);
                  setDeadline(date.target.value);
                }}
              >
                <FormControlLabel
                  value={moment(record.fitting_time)
                    .subtract(9, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")}
                  control={<Radio />}
                  label={
                    <div>
                      <Typography>Default:</Typography>
                      <Typography>
                        {moment(record.fitting_time)
                          .subtract(9, "hours")
                          .format("DD/MM/YYYY, h:mm a")}
                      </Typography>
                    </div>
                  }
                />
                <FormControlLabel
                  value={record.previous_allocation}
                  control={<Radio />}
                  label={
                    <div>
                      <Typography>Same as previous:</Typography>
                      <Typography>
                        {moment(record.previous_allocation)
                          .utc()
                          .format("DD/MM/YYYY, h:mm a")}
                      </Typography>
                    </div>
                  }
                />
                <FormControlLabel
                  value={
                    newdeadline
                      ? moment(newdeadline).format("YYYY-MM-DD HH:mm:ss")
                      : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                  }
                  control={<Radio />}
                  label={
                    <div>
                      <Typography>New:</Typography>

                      <DateTimePicker
                        onChange={date => {
                          console.log(date);
                          setDeadline(date);
                          setNewdeadline(date);
                        }}
                        value={newdeadline ? newdeadline : new Date()}
                        clearIcon={null}
                        required={true}
                        disableClock={true}
                        locale="vi-VN"
                      />
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const getTailors = async record => {
  const tailors = await fetch(
    api_uri + "/factory/tailors?garment_id=" + record.garment_id
  );
  const tailorsResponse = await tailors.json();

  record.tailors = tailorsResponse;
  return record;
};

const confirmQC = (type, oid, msg, qc, qc_id, msg_after) => {
  document.getElementById("reset-scanner").click();
  innerglobal_newfitreason = document.getElementById("ta-reason").value;
  swal({
    title: msg,
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
    input: "select",
    inputOptions: store.sales_staff,
    inputPlaceholder: "QC'ed By",
    inputAttributes: {
      autocapitalize: "off"
    },
    showLoaderOnConfirm: true,
    preConfirm: staff => {
      if (staff < 1) {
        swal.showValidationMessage("Who did the QC?");
        var lb = document.getElementById("lightbox");
        lb.style.display = "none";
        return;
      }
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          //console.dir(this.responseText);
        }
        swal({
          title: msg_after,
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Confirm"
        }).then(result => {
          refreshRecord();

          var lb = document.getElementById("lightbox");
          lb.style.display = "none";
        });
      };
      xhttp.open("POST", api_uri + "/factory/fireitemqc", true);
      xhttp.setRequestHeader("Content-Type", "application/json");
      var payload = {
        qc_id: qc_id,
        result: qc,
        order_id: oid,
        type: type,
        extra: {
          fitting: innerglobal_newfit,
          reason: innerglobal_newfitreason
        },
        staff_id: staff
      };
      console.dir(payload);
      xhttp.send(JSON.stringify(payload));
    },
    allowOutsideClick: () => !swal.isLoading()
  }).then(result => {
    // refreshRecord();
  });

  // swal({
  //   title: msg,
  //   type: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Confirm'
  // }).then((result) => {
  //   if(result.value) {
  //     var xhttp = new XMLHttpRequest();
  //     xhttp.onreadystatechange = function() {
  //       if (this.readyState === 4 && this.status === 200) {
  //         console.dir(this.responseText);
  //         refreshRecord();
  //       }
  //     };
  //     xhttp.open("POST", api_uri+"/factory/fireitemqc", true);
  //     var payload = {
  //       qc_id: qc_id,
  //       result: qc
  //     }
  //     xhttp.send(JSON.stringify(payload));
  //   }
  // })
};
/*
const showAllocateModal = (showMETA = false) => {
  // document.getElementById("cb-meta").checked = showMETA;
  manualETA = showMETA;
  //innerglobal_itn = 0;

  //innerglobal_tid = 0;
  if (showMETA) {
    toggleManETA(false, false, true);
    //document.getElementById("cb-meta").checked = false;
  } else toggleManETA(true);

  var lb = document.getElementById("lightbox-allocate");
  lb.style.display = "block";
};
*/
/*
const hideAllocateModal = () => {
  var lb = document.getElementById("lightbox-allocate");
  lb.style.display = "none";
};
*/
const showModal = (record, cb = 0) => {
  var lb = document.getElementById("lightbox");
  lb.style.display = "block";
  if (cb) {
    document.getElementById("btn-cb").onclick = cb;
  }
};

const hideModal = () => {
  var lb = document.getElementById("lightbox");
  lb.style.display = "none";
};
/*
const fetchTailors = record => {
  clearClearables();
  const fetchItems = fetch(
    api_uri + "/factory/tailors?garment_id=" + record.garment_id
  );
  fetchItems
    .then(res => {
      if (!res.ok) {
        return Promise.reject("ERROR FETCH");
      }
      return res.json();
    })
    .then(tailors => {
      var tbl = document.getElementById("tailors-tblbody");
      tbl.innerHTML = "";

      _.sortBy(tailors, i => i.name);
      tailors.map(t => tbl.appendChild(createTailorRow(record, t)));
      record.tailors = tailors;
      //document.getElementById("span-itm-num").innerHTML = record.item;
      showModal(record);
    });
};
*/
/*
const createTailorRow = (record, tailor) => {
  var tr = document.createElement("tr");
  // tr.classList.add('danger');
  var name = document.createElement("td");
  name.innerHTML = tailor.name;
  var spec = document.createElement("td");
  spec.innerHTML = "<Typography>" + tailor.specialty_alias + "</Typography>";
  var items_day = document.createElement("td");
  items_day.innerHTML = tailor.items_day;
  var items_week = document.createElement("td");
  items_week.innerHTML = tailor.items_week;
  var inProg = document.createElement("td");
  inProg.innerHTML = tailor.items_inprog;
  var latestG = document.createElement("td");
  //latestG.innerHTML = formatTimestamp(tailor.latest_garment);
  latestG.innerHTML = moment(tailor.latest_garment).fromNow(); //.format('DD/MM/YYYY, h:mm:ss a')

  var btn = document.createElement("Button");
  btn.addEventListener("click", function() {
    confirmAssignment(
      record.item_id,
      tailor.id,
      tailor.name,
      record.completion_time,
      record.order_id,
      record.item
    );
  });
  btn.innerHTML = "Assign";
  btn.classList.add("btn");
  btn.classList.add("btn-success");

  tr.appendChild(name);
  tr.appendChild(spec);
  tr.appendChild(items_day);
  tr.appendChild(items_week);
  tr.appendChild(inProg);
  tr.appendChild(latestG);
  tr.appendChild(btn);

  return tr;
};
*/
const assignToTailor = (record, deadline, tailor) => {
  console.log(deadline, tailor);

  let payload = {
    order_id: record.order_id,
    item_id: record.item_id,
    tailor_id: tailor.id,
    eta: moment(deadline).format("YYYY-MM-DD HH:mm:ss")
  };
  console.log(payload);

  fetch(api_uri + "/factory/allocateitem", {
    body: JSON.stringify(payload),
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" })
  })
    .then(res => {
      console.log(res);
      //hideAllocateModal();
      //hideModal();
      refreshRecord();
    })
    .then(res => console.log(res));

  /*
  var sameAsPrevious = document.getElementById("cb-meta").checked;
  var tmpDate = (typeof(valETA) === "object") ? valETA : new Date(valETA);
  if (tmpDate.getHours() < 8 && !sameAsPrevious) {
    swal({
      title: "Select a valid time",
      type: "error",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Go Back"
    }).then(result => {});
    return;
  } else {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status < 210) {
        //hideAllocateModal();
        refreshRecord();
        //console.dir(this.responseText);
        //hideModal();
      } else if (this.status === 501) {
        try {
          var res = JSON.parse(this.responseText);
          swal({
            title: res.error.message,
            type: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Go Back"
          }).then(result => {});
        } catch (err) {}
        return;
      }

      hideAllocateModal();
      hideModal();
      //refreshRecord();
    };
    xhttp.open("POST", api_uri + "/factory/allocateitem", true);
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    var payload = {};
    payload["order_id"] = order_id;
    payload["item_id"] = item_number;
    payload["tailor_id"] = tailor_id;
    payload["eta"] = "";
    if (sameAsPrevious) payload["eta"] = "same_as_previous";
    else if (valETA) payload["eta"] = moment(valETA).format("YYYY-MM-DD HH:mm:ss")
    xhttp.send(JSON.stringify(payload));
     }
    */
};
/*
const confirmAssignment = (
  item_number,
  tailor_id,
  tailor_name,
  ctime = "",
  order_id,
  item
) => {
  showAllocateModal(true);
  //document.getElementById("ca-itemnumber").innerHTML = item;
  //document.getElementById("ca-tailor").innerHTML = tailor_name;
  //innerglobal_itn = item_number;
  //innerglobal_tid = tailor_id;
  //innerglobal_oid = order_id;
  // swal({
  //   title: 'Confirm Allocation',
  //   html:
  //     '<div><b>Item #: </b>, ' + item_number + "</div><br>" +
  //     '<div><b>Allocated to: </b> ' + tailor_name + "</div><br>" +
  //     '<div><b>Estimated Completion Time: </b>, '+valETA+'</div><br>' +
  //     '<DTPicker />',
  //   showCancelButton: true,
  //   confirmButtonText: 'Assign',
  //   showLoaderOnConfirm: true,
  //   preConfirm: (login) => {
  //     return assignToTailor(item_number, tailor_id);
  //   },
  //   allowOutsideClick: () => !swal.isLoading()
  // })
};*/
