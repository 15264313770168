import {
  garment_bucket,
  garment_url,
  location_bucket,
  measurement_bucket,
} from "./globals";
import moment from "moment-timezone";

const addUploadCapabilities = (requestHandler) => (type, resource, params) => {
  let form = new FormData();

  let options = {};
  let description = "";
  let name = "";
  let unique_code = "";
  /* FILTER LOCATION */

  if (resource === "locations") {
    if (params.filter && params.filter.name) {
      name = params.filter.name.like;
      params.filter.name = { like: `%${name}%` };
    }
  }

  /* FILTER SWATCHBOOK UNIQUE_CODE */
  if (resource === "swatchbooks") {
    if (params.filter && params.filter.unique_code) {
      unique_code = params.filter.unique_code.like;
      params.filter.unique_code = { like: `%${unique_code}%` };
    }
  }

  /* FILTER CUSTOMER */
  let filter = {};
  let dateFilter = {};

  /*
  if (type === "GET_LIST" && resource === "garments") {
    params.include = [
      {
        relation: "option_ids",
        scope: {
          include: ["option"],
        },
      },
    ];
  }
*/

  if (type === "GET_ONE" && resource === "garments") {
    resource = "garments/detail";
  }
  if (type === "GET_LIST" && resource === "garment_options") {
    params.include = ["value_ids"];
  }

  if (type === "GET_ONE" && resource === "garment_options") {
    resource = "garment_options/detail";
  }

  if (resource.indexOf("fabric_cuts/") > -1) {
    switch (resource) {
      case "fabric_cuts/cut":
        filter = {
          status_id: 3,
        };
        break;
      case "fabric_cuts/to_cut":
        filter = {
          status_id: {
            inq: [1, 2],
          },
        };
        break;
      default:
        break;
    }
    params.include = ["item", "fabric", "cutter", "location"];
    params.filter = { ...filter, ...params.filter };

    resource = "fabric_cuts";
  }

  if (resource.indexOf("items/") > -1) {
    switch (resource) {
      case "items/all":
        break;
      case "items/unallocated":
        filter = { status_id: 4 };
        break;
      case "items/live":
        filter = { status_id: { inq: [5, 6, 7, 8] } };
        break;
      default:
        break;
    }
    params.include = [
      "options",
      "fabrics",
      "images",
      "garment",
      "status",
      {
        relation: "tailor",
        scope: {
          fields: ["id", "nick_name", "thumbnail"],
          include: ["tailor_load"],
        },
      },
      {
        relation: "order",
        scope: {
          //fields: ["id", "nick_name", "thumbnail"],
          include: ["store"],
        },
      },
    ];
    params.filter = { ...filter, ...params.filter };

    resource = "items";
  }

  if (type === "GET_ONE" && resource === "collections") {
    resource = "collections/detail";
  }

  if (type === "GET_ONE" && resource === "orders") {
    resource = "orders/detail";
  } else if (resource.indexOf("orders/") > -1) {
    if (params.filter.gt) {
      dateFilter = {
        and: [
          {
            timestamp: {
              gt: moment(params.filter.gt)
                .subtract(1, "days")
                .format("YYYY-MM-DD"),
            },
          },
          {
            timestamp: {
              lt: params.filter.lt
                ? moment(params.filter.lt).add(1, "days").format("YYYY-MM-DD")
                : moment(params.filter.gt).add(1, "days").format("YYYY-MM-DD"),
            },
          },
        ],
      };
    } else {
      if (params.filter.lt) {
        dateFilter = {
          and: [
            {
              timestamp: {
                gt: moment(new Date(0))
                  .subtract(1, "days")
                  .format("YYYY-MM-DD"),
              },
            },
            {
              timestamp: {
                lt: moment(params.filter.lt)
                  .add(1, "days")
                  .format("YYYY-MM-DD"),
              },
            },
          ],
        };
      }
    }

    delete params.filter["gt"];
    delete params.filter["lt"];

    switch (resource) {
      case "orders/unpaid":
        filter = { status: "unpaid" };
        break;
      case "orders/completed":
        filter = { status: "completed" };

        break;
      case "orders/huy_bill":
        filter = { disabled: 1 };
        break;
      case "orders/pending":
        filter = {
          status: "pending_final_payment",
        };
        break;
      case "orders/all":
        filter = { disabled: 0 };
        break;
      default:
        break;
    }
    params.filter = { ...filter, ...dateFilter, ...params.filter };
    params.include = [
      "store",
      "hotel_ids",
      "origin_ids",
      "disable_ids",
      "items",
      "staff_ids",
      "link_ids",
      "payments",
      "status",
      "itemList",
    ];
    resource = "orders";
  }

  /*
  if (resource.indexOf("bas_cutters/") > -1) {
    switch (resource) {
      case "bas_cutters/cut":
        filter = { status: "cut" };
        break;
      case "bas_cutters/to_cut":
        filter = { status: "to_cut" };
        break;
      default:
        break;
    }
    params.filter = { ...filter, ...params.filter };
    resource = "bas_cutters";
  }*/

  /* FILTER LIKE */
  if (
    type === "GET_LIST" &&
    (resource === "garment_options" ||
      resource === "garment_option_values" ||
      resource === "garments")
  ) {
    if (params.filter.description) {
      description = params.filter.description.like;
      params.filter.description = { like: `%${description}%` };
    }
  }

  if (
    resource === "garment_option_values" ||
    resource === "garments" ||
    resource === "garment_fabrics"
  ) {
    if (type === "UPDATE" || type === "CREATE") {
      params.data.images && form.append("image", params.data.images.rawFile);

      params.data.previews &&
        form.append("preview", params.data.previews.rawFile);

      params.data.image =
        params.data.images && garment_url + params.data.images.title;

      params.data.preview =
        params.data.previews && garment_url + params.data.previews.title;

      options.method = "POST";
      options.body = form;
      params.redirectTo = "show";

      return fetch(garment_bucket, options).then((res) => {
        return requestHandler(type, resource, params);
      });
    }
  }

  if (resource === "garment_option_value_fabrics") {
    if (type === "UPDATE" || type === "CREATE") {
      params.data.preview_fronts &&
        form.append("image", params.data.preview_fronts.rawFile);
      params.data.preview_front =
        params.data.preview_fronts &&
        garment_url + params.data.preview_fronts.title;

      params.data.preview_backs &&
        form.append("image", params.data.preview_backs.rawFile);
      params.data.preview_back =
        params.data.preview_backs &&
        garment_url + params.data.preview_backs.title;

      params.data.preview_details &&
        form.append("image", params.data.preview_details.rawFile);
      params.data.preview_detail =
        params.data.preview_details &&
        garment_url + params.data.preview_details.title;

      options.method = "POST";
      options.body = form;
      params.redirectTo = "list";

      return fetch(garment_bucket, options).then((res) => {
        return requestHandler(type, resource, params);
      });
    }
  }

  if (resource === "locations") {
    if (type === "UPDATE" || type === "CREATE") {
      params.data.images && form.append("image", params.data.images.rawFile);

      params.data.image = params.data.images && params.data.images.title;

      options.method = "POST";
      options.body = form;
      params.redirectTo = "show";

      return fetch(location_bucket, options).then((res) => {
        return requestHandler(type, resource, params);
      });
    }
  }

  if (resource === "measurements") {
    if (type === "UPDATE" || type === "CREATE") {
      params.data.images && form.append("image", params.data.images.rawFile);

      params.data.image = params.data.images && params.data.images.title;

      options.method = "POST";
      options.body = form;
      params.redirectTo = "show";

      return fetch(measurement_bucket, options).then((res) => {
        return requestHandler(type, resource, params);
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;

/*



  let image_url = ""; 

  if (type === "UPDATE") {
    params.data.update_date = new Date();
  }
  if (type === "CREATE" && resource === "stocks") {
    params.redirectTo = "list";
    return requestHandler(type, resource, params);
  }
  if (type === "CREATE" && resource === "swatchbooks") {
    params.redirectTo = "list";
    return requestHandler(type, resource, params);
  }
  if (type === "CREATE" && resource === "swatchbook_locations") {
    params.redirectTo = "list";
    return requestHandler(type, resource, params);
  }

  if (
    (type === "CREATE" || type === "UPDATE") &&
    (resource === "fabrics" ||
      resource === "locations" ||
      resource === "staffs" ||
      resource === "garment_options" ||
      resource === "garment_option_values" ||
      resource === "garments")
  ) {
    params.data.update_date = new Date();

    if (params.data.pictures) {
      //debugger;

      //const pictures = params.data.pictures.filter(p => p.rawFile instanceof File)
      let form = new FormData();
      let url = "";
      if (resource === "fabrics") {
        url = api + "/containers/bebe-fabrics/upload";
      }
      if (resource === "locations") {
        url = api + "/containers/bebe-locations/upload";
      }

      if (resource === "staffs") {
        url = api + "/containers/bebe-staff/upload";
      }

      if (
        resource === "garment_options" ||
        resource === "garment_option_values" ||
        resource === "garments"
      ) {
        url = api + "/containers/bebe-garment-options/upload";
        image_url = image_url + garment_bucket;
      }

      if (type === "CREATE") {
      }

      const options = {};
      //the params contain the image as a fileInstance

      form.append("image", params.data.pictures.rawFile);
      options.method = "POST";
      options.body = form;

      params.data.image = image_url + params.data.pictures.title;
      params.data.thumbnail = params.data.pictures.title;
      params.redirectTo = "show"; */
/*
      if (resource === "fabrics") {
        params.redirectTo = "/stocks/create";
      }*/
/*
      if (params.data.thumbnails && params.data.thumbnails.length) {
        if (resource === "staffs") {
          url = api + "/containers/bebe-staff-thumbnails/upload";
        }
        //the params contain the image as a fileInstance
        form.append("image", params.data.thumbnails[0].rawFile);
        options.method = "POST";
        options.body = form;
        params.data.thumbnail = params.data.thumbnails[0].title;
        params.redirectTo = "show";
      }*/

/* return fetch(url, options).then((res) => {
        params.data.image_id = res.json.idea;
        return requestHandler(type, resource, params);
      });*/
//} else {
// }
/*
    if (resource === "fabrics") {
      params.redirectTo = "/stocks/create";
    }*/
