/*
import GarmentFabricCreate from "./GarmentFabricCreate";
import GarmentFabricEdit from "./GarmentFabricEdit";
import GarmentOptionShow from "./GarmentOptionShow";
 */
import FabricCutList from "./FabricCutList";

export default {
  //create: GarmentFabricCreate,
  //edit: GarmentFabricEdit,
  list: FabricCutList,
  //show: GarmentOptionShow,
};
