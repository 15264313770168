import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SearchInput,
  useTranslate,
} from "react-admin";

export const SwatchbookFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput
        source="unique_code.like"
        placeholder={translate("table_columns.swatchbook")}
        resettable
        alwaysOn
      />

      <ReferenceInput
        reference="fabric_types"
        source="type_id"
        sort={{ field: "alias", order: "ASC" }}
        allowEmpty={false}
        perPage={-1}
      >
        <AutocompleteInput
          optionText={(record) =>
            record && `${record.alias} - ${record.description}`
          }
        />
      </ReferenceInput>
      <SelectInput
        source="is_virtual"
        label="Is Virtual?"
        optionValue="_id"
        optionText="name"
        allowEmpty={false}
        choices={[
          { _id: "0", name: "Not Virtual" },
          { _id: "1", name: "Virtual" },
        ]}
      />
    </Filter>
  );
};
