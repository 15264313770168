import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  FunctionField,
} from "react-admin";
import LocationFilter from "./LocationFilter";

import {
  CustomPagination,
  CustomDateField,
  location_thumbnail,
} from "../../globals";

const LocationList = (props) => {
  console.log(props);
  return (
    <List
      filters={<LocationFilter />}
      {...props}
      title="resources.fabrics.fields.location_list"
      sort={{ field: "creation_date", order: "DESC" }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      style={{ maxWidth: 1280, height: "100%" }}
    >
      <Datagrid>
        <TextField label="resources.fabrics.fields.name" source="name" />
        <TextField
          label="resources.fabrics.fields.description"
          source="description"
        />
        <TextField
          label="resources.fabrics.fields.num_fabrics"
          source="count"
          sortable={false}
        />
        <CustomDateField
          label="resources.fabrics.fields.creation_date"
          source="creation_date"
          utc={false}
          formatedDate={true}
        />

        <FunctionField
          render={(record) => (
            <img
              width={100}
              src={location_thumbnail + record.image}
              alt={record.unique_code}
            />
          )}
        />
        <ShowButton />

        <EditButton />
      </Datagrid>
    </List>
  );
};
export default LocationList;
