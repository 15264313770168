
import React from "react";
import {
    SimpleForm,
    TextInput,
    ImageInput,
  ImageField,
  FormDataConsumer,
  Edit,TopToolbar,ListButton, SaveButton,Toolbar
} from "react-admin";


 const LocationEdit = props => (
    <Edit {...props} title={<LocationTitle />} actions={<Actions />} >
      <SimpleForm toolbar={<EditToolbar {...props} />}>
        <TextInput label="resources.fabrics.fields.name" source="name" />
        <TextInput label="resources.fabrics.fields.description" source="description" />

  
        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.images) {
              return <ImageField source="image" {...rest} />;
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );

  const LocationTitle = ({record}) => {
    return <span>Location {record ? `"${record.name}"` : ""}</span>;
  };

  const Actions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <ListButton basePath={basePath} record={data} />
    </TopToolbar>
  );

  const EditToolbar = ({
    hasList,
    hasEdit,
    hasShow,
    hasCreate,
    permissions,
    ...props
  }) => (
    <Toolbar {...props}>
      <SaveButton label="ra.action.edit" redirect="show"  {...props}/>
    </Toolbar>
  );

  export default LocationEdit;
