// in src/garments.js
import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  NumberInput,
  SelectInput,
  useNotify,
  useRedirect,
  useCreate,
  useUpdate,
  useDelete,
  Toolbar,
  SaveButton,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
} from "react-admin";
import { Typography } from "@material-ui/core";

import { useFormState } from "react-final-form";
import { Divider } from "@material-ui/core";
import _ from "lodash";
import { Actions } from "./GarmentComponent";

const GarmentCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create {...props} title="Create New Garment" actions={<Actions />}>
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <TextInput source="name" label="resources.garment.garment_name" />
        <TextInput source="description" label="resources.garment.description" />
        <TextInput source="alias" label="resources.garment.alias" />
        <TextInput source="vietnamese" label="resources.garment.vietnamese" />

        <SelectInput
          source="gender"
          label="Gender"
          choices={[
            { id: "1", name: "Male" },
            { id: "2", name: "Female" },
          ]}
        />
        <NumberInput source="order" label="Order (optional)" />

        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.options").toUpperCase()}
          </Typography>
        </Fragment>

        <ArrayInput source="option_ids" label="">
          <SimpleFormIterator>
            <ReferenceInput
              source="garment_option_id"
              reference="garment_options"
              label="table_columns.option"
            >
              <SelectInput optionText="description" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const UserCreateToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveCustomButton label="ra.action.save" redirect="list" />
  </Toolbar>
);

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;

  const [create] = useCreate("garments");
  const [deleteOption] = useDelete("garment_garment_options");
  const [updateOption] = useUpdate("garment_garment_options");
  const [createOption] = useCreate("garment_garment_options");

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }

    let options = formState.values.option_ids
      ? formState.values.option_ids
      : [];

    create(
      {
        payload: {
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.option_ids &&
            record.option_ids.forEach((o) => {
              if (!_.find(options, (oo) => oo.id === o.id)) {
                deleteOption({
                  payload: { id: o.id },
                });
              }
            });

          options.forEach((o) => {
            if (o.id) {
              updateOption({
                payload: {
                  id: o.id,
                  data: {
                    garment_id: newRecord.id,
                    garment_option_id: o.garment_option_id,
                  },
                },
              });
            } else {
              createOption({
                payload: {
                  data: {
                    garment_id: newRecord.id,
                    garment_option_id: o.garment_option_id,
                  },
                },
              });
            }
          });
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default GarmentCreate;
