import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  RefreshButton,
  CreateButton,
} from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import { StaffFilter } from "./StaffFilter";

import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import CardActions from "@material-ui/core/CardActions";
import { Typography } from "@material-ui/core";
import {
  thumbnail_url,
  CustomPagination,
  CustomDateField,
} from "../../globals";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right",
};
const StaffActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter,
}) => (
  <CardActions style={cardActionStyle}>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={"staffs"} />
    <RefreshButton />
    {/* Add your custom actions
        <FlatButton primary label="Custom Action" onClick={console.log("dsf")} />  */}
  </CardActions>
);

const StaffList = (props) => (
  <List
    {...props}
    title="resources.staff.fields.staff_list"
    sort={{ field: "nick_name", order: "ASC" }}
    actions={<StaffActions />}
    filters={<StaffFilter />}
    bulkActionButtons={false}
    pagination={<CustomPagination />}
  >
    <Datagrid rowClick="show">
      {/* <ThumbnailField source="thumbnail" label="resources.staff.fields.image" /> 
      <TextField label="resources.staff.fields.username" source="username" />*/}

      <FunctionField
        render={(record) => (
          <Tooltip title={record.nick_name}>
            <Avatar
              alt={record.nick_name}
              src={`${thumbnail_url}${record.thumbnail}`}
            />
          </Tooltip>
        )}
      />

      <FunctionField
        label="resources.staff.fields.nick_name"
        render={(record) => (
          <Typography component="b">{record.nick_name}</Typography>
        )}
      />

      <FunctionField
        label="table_columns.name"
        render={(record) => (
          <Typography>{`${record.first_name ? record.first_name : ""} ${
            record.middle_name ? record.middle_name : ""
          } ${record.last_name ? record.last_name : ""}`}</Typography>
        )}
      />

      <ReferenceField source="type_id" reference="staff_types" link={false}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="store_id" reference="stores" link={false}>
        <FunctionField
          render={(record) => (
            <Tooltip title={record.name}>
              <Avatar
                alt={record.name}
                style={{
                  backgroundColor: record.color,
                  color: "white",
                }}
              >
                {record.alias}
              </Avatar>
            </Tooltip>
          )}
        />
      </ReferenceField>

      <TextField source="phone_number" />
      <CustomDateField source="creation_date" formatedDate={true} />

      <FunctionField
        label="Status"
        render={(record) =>
          record.status ? (
            <DoneIcon style={{ color: green[600] }} />
          ) : (
            <ClearIcon style={{ color: red[600] }} />
          )
        }
      />

      {/*
      <ReferenceManyField
        label="resources.staff.fields.specialties"
        reference="tailor_specialties"
        target="staff_id"
        linkType="show"
      >
        <SingleFieldList>
          <ReferenceField
            source="garment_id"
            reference="garments"
            linkType="show"
          >
            <ChipField style={{ flexWrap: "wrap" }} source="alias" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
*/}
    </Datagrid>
  </List>
);

export default StaffList;
