import React from "react";
import { connect } from "react-redux";
import { Layout } from "react-admin";
import AppBar from "./AppBar";
import BebeTheme from "./theme";
import Sidebar from "./Sidebar";
//import { Sidebar } from "react-admin";
import Menu from "./Menu";
//import { darkTheme, lightTheme } from "./themes";
import { ThemeProvider } from "@material-ui/core/styles";
//import { makeStyles } from "@material-ui/core/styles";
/*
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative"
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto"
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: "4em",
    paddingLeft: 5
  }
}));
*/
const CustomLayout = props => {
  //const classes = useStyles();
  return (
    <ThemeProvider theme={BebeTheme}>
      <Layout
        {...props}
        appBar={AppBar}
        sidebar={Sidebar}
        //sidebar={null}
        menu={Menu}
        //theme={theme}
      />
    </ThemeProvider>
  );
};

export default connect(
  state => ({
    theme: state.theme === "dark" ? BebeTheme : BebeTheme
  }),
  {}
)(CustomLayout);
