import React from "react";

import { ListButton, EditButton, TopToolbar } from "react-admin";

export const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    {data && <EditButton basePath={basePath} record={data} />}
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
