import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  Responsive,
  TextField,
  FunctionField,
  ArrayField,
  ReferenceField,
  RichTextField,
  SimpleShowLayout,
} from "react-admin";

import { Link as LinkR } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { bas_url } from "../globals";

import {
  OrderStatus,
  CustomerField,
  BookCodeField,
  StaffField,
  // OrderField,
  HotelField,
  StoreField,
  ItemStatus,
} from "../components";

import { PaymentSlipPreview } from "./payment/components";
import ErrorIcon from "@material-ui/icons/Error";
import red from "@material-ui/core/colors/red";

import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";

import {
  CustomDateField,
  formatDecimals,
  PayButton,
  CustomPagination,
} from "../globals";

import { OrderFilter } from "./OrderFilter";
import { Typography } from "@material-ui/core";
//import { useTranslate } from "react-admin";
import rowStyle from "./rowStyle";

const OrderList = ({ permissions, ...props }) => {
  //const { status: status_string } = parse(props.location.search);
  //const status = status_string ? status_string : "";

  let filter =
    Number(localStorage.getItem("store_id")) === 0 ||
    Number(localStorage.getItem("store_id")) === 1
      ? null
      : { store_id: Number(localStorage.getItem("store_id")) };

  //For the menu

  //let statusFilter = status ? { status } : null;

  //let filters = { ...filter, ...statusFilter };

  const theme = useTheme();

  //let disabledFilter = {};

  return (
    <List
      {...props}
      filters={<OrderFilter />}
      bulkActionButtons={false}
      sort={{ field: "timestamp", order: "DESC" }}
      pagination={<CustomPagination />}
      filter={filter}
      basePath="/orders"
    >
      <Responsive
        //small={}
        medium={
          <Datagrid
            optimized
            expand={<ExpandPanel />}
            rowClick="show"
            rowStyle={rowStyle(props.selectedRow, theme)}
          >
            {/* <OrderField source="id" label="table_columns.id" link={false} /> */}
            <StoreField label="table_columns.store" />
            <CustomDateField
              source="timestamp"
              utc={false}
              formatedDate={true}
              s
              label="table_columns.creation_date"
            />
            <HotelField label="table_columns.hotel" />
            <BookCodeField label="table_columns.book_code" source="order" />
            <StaffField label="table_columns.staff" />
            <CustomerField label="table_columns.customer" />
            <OriginField label="table_columns.origin" />
            <ItemField label="table_columns.items" />
            <PriceField label="table_columns.total_bill" />
            <BalanceField label="Balance" />
            <OrderStatus label="table_columns.status" />
            <LinkOrderField label="table_columns.linked_order" />

            {props.resource !== "orders/huy_bill" && (
              <PayButtonField label="table_columns.pay_now" />
            )}

            <PaymentSlipPreview
              label="table_columns.receipts"
              style={{ textAlign: "center" }}
              sortable={false}
              reprint={true}
              onClick={(event) => event.stopPropagation()}
            />
            <FunctionField
              render={(record) =>
                record.disabled && (
                  <Tooltip title="HUY BILL">
                    <ErrorIcon style={{ color: "#e53935" }} />
                  </Tooltip>
                )
              }
            />
          </Datagrid>
        }
      />
    </List>
  );
};

const LinkOrderField = ({ record }) => {
  return record.link_ids
    ? record.link_ids.map((record) => (
        <div key={record.id}>
          <Typography
            onClick={(event) => event.stopPropagation()}
            component={LinkR}
            to={`${record.linked_order_id}/show`}
            style={{ color: red["A200"], textAlign: "right" }}
          >
            {record.linked_order_id}
          </Typography>
        </div>
      ))
    : null;
};

const PayButtonField = ({ record }) => {
  return (
    !record.disabled &&
    record.status !== "completed" && (
      <PayButton
        record={{
          type_id:
            record.status === "unpaid"
              ? 1
              : record.status === "pending_final_payment" && 2,
          ...record,
        }}
      />
    )
  );
};
const BalanceField = ({ record }) => {
  return (
    <Typography style={{ textAlign: "right" }}>
      $
      {record.balance &&
        formatDecimals(
          record.balance.toFixed(record.balance % 2 === 0 ? 0 : 2)
        )}
    </Typography>
  );
};

const PriceField = ({ record }) => {
  return (
    <div style={{ display: "flex", alignItems: "row" }}>
      {record.discount > 0 ? (
        <Tooltip title={`Discount of $${record.discount} USD applied.`}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ textAlign: "right" }}>
              ${formatDecimals(record.total_price - record.discount)}
            </Typography>
            <Typography color="error">*</Typography>
          </div>
        </Tooltip>
      ) : (
        <Typography style={{ textAlign: "right" }}>
          ${formatDecimals(record.total_price - record.discount)}
        </Typography>
      )}
    </div>
  );
};
const ItemField = ({ record }) => {
  return record.items && record.items.length > 0 ? (
    <LinkR
      to={`/orders/${record.id}/show/4`}
      onClick={(event) => event.stopPropagation()}
    >
      <Tooltip
        title={
          <Fragment>
            {_.sortBy(record.items, (i) => i.index).map(
              (i) => i.garment && <p key={i.id}>{`- ${i.garment.alias}`}</p>
            )}
          </Fragment>
        }
      >
        <Typography>{record.items.length}</Typography>
      </Tooltip>
    </LinkR>
  ) : (
    (record.store_id = 4 ? "1" : "NO ITEMS")
  );
};

const OriginField = ({ record }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {record.origin_ids &&
        record.origin_ids.map((o) => (
          <Typography key={o.id}>{o.origin.source}</Typography>
        ))}
    </div>
  );
};

const ExpandPanel = (props) => {
  return (
    <div>
      <ExpandItems {...props} />
      <ExpandNotes {...props} />
    </div>
  );
};

const ExpandItems = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <ArrayField source="items" label="Item List">
        <Datagrid>
          <FunctionField
            label="id"
            render={(record) => <Typography>{record.id}</Typography>}
          />
          <FunctionField
            label="Item Number"
            render={(record) => {
              console.log(record);
              return (
                <Typography>{`${record.order_id}-${record.index}`}</Typography>
              );
            }}
          />
          <FunctionField
            label="table_columns.garment"
            render={(record) => {
              return (
                <Link
                  href={`${bas_url}/i/${record.order_id}-${record.index}`}
                  color="primary"
                  target="_blank"
                >
                  <Typography>{record.garment.alias}</Typography>
                </Link>
              );
            }}
          />
          <ItemStatus label="table_columns.status" />

          <FunctionField
            label="table_columns.customer"
            render={(record) => <Typography>{record.customer_name}</Typography>}
          />
          <FunctionField
            label="Price"
            render={(record) => (
              <Typography
                style={{ textAlign: "right" }}
              >{`$${record.price} USD`}</Typography>
            )}
          />
          <TextField source="notes" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

export default OrderList;

const ExpandNotes = (props) => {
  const { notes } = props.record;

  return (
    <SimpleShowLayout {...props}>
      {notes && notes !== "" && (
        <SimpleShowLayout>
          <RichTextField source="notes" label="Notes" />
        </SimpleShowLayout>
      )}

      {props.record.disabled && props.record.disable_ids.length && (
        <SimpleShowLayout>
          <ArrayField source="disable_ids" label="Huy Bill">
            <Datagrid>
              <TextField label="reason" source="reason" />
              <CustomDateField
                utc={false}
                label="table_columns.creation_date"
                source="creation_date"
                formatedDate={true}
              />
              <ReferenceField
                source="staff_id"
                label="table_columns.staff"
                reference="sellers"
              >
                <FunctionField
                  render={(record) => (
                    <Tooltip title={record.name}>
                      <Avatar alt={record.name} src={record.image}>
                        {record.name}
                      </Avatar>
                    </Tooltip>
                  )}
                />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      )}
    </SimpleShowLayout>
  );
};
