import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import OrderCreate from "./OrderCreate";
import OrderEdit from "./OrderEdit";
import OrderList from "./OrderList";
import OrderShow from "./OrderShow";

export default {
  create: OrderCreate,
  edit: OrderEdit,
  list: OrderList,
  show: OrderShow,
  icon: ChatBubbleIcon
};
