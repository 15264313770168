/*
import OrderEdit from "./OrderEdit";
import OrderList from "./OrderList";
import OrderShow from "./OrderShow";
*/
import ItemList from "./ItemList";
import ItemCreate from "./ItemCreate";

export default {
  create: ItemCreate,
  //edit: OrderEdit,
  list: ItemList,
  //show: OrderShow,
};
