import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ListButton,
  TopToolbar,
  ImageField,
  ImageInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  SelectInput,
} from "react-admin";

const GarmentFabricCreate = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;
  return (
    <Create
      {...props}
      title="Create New Garment Option Value"
      actions={<Actions />}
    >
      <SimpleForm
        //toolbar={<UserCreateToolbar />}
        defaultValue={{ type_id: 1, fabric_id: 17669 }}
      >
        <TextInput
          source="name"
          label="table_columns.name"
          validate={required()}
        />
        <TextInput
          source="description"
          label="table_columns.description"
          validate={required()}
        />

        <ReferenceInput
          reference="garments"
          source="garment_id"
          sort={{ field: "alias", order: "ASC" }}
          validate={required()}
        >
          <SelectInput optionText="alias" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div>
              <ReferenceInput
                reference="fabric_types"
                source="type_id"
                sort={{ field: "alias", order: "ASC" }}
                perPage={-1}
                validate={required()}
              >
                <AutocompleteInput optionText="alias" {...rest} />
              </ReferenceInput>
              <ReferenceInput
                {...rest}
                reference="fabrics"
                source="fabric_id"
                filter={{ type_id: formData.type_id }}
                sort={{ field: "unique_code", order: "ASC" }}
                perPage={-1}
                validate={required()}
              >
                <AutocompleteInput
                  shouldRenderSuggestions={(val) => {
                    return val.trim().length > 4;
                  }}
                  optionText="unique_code"
                />
              </ReferenceInput>
            </div>
          )}
        </FormDataConsumer>

        <ImageInput
          source="previews"
          label="table_columns.preview"
          accept="image/*"
        >
          <ImageField source="preview" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
/*
const UserCreateToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    style={{ display: "flex", justifyContent: "space-between" }}
  >
    <SaveCustomButton label="ra.action.save" redirect="list" />
  </Toolbar>
);

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;

  const [create] = useCreate("orders");

  const handleClick = () => {
    console.log(formState.values);
    if (!formState.valid) {
      return;
    }
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};
*/
export default GarmentFabricCreate;
