import React from "react";
import {
  Show,
  TextField,
  TopToolbar,
  EditButton,
  ListButton,
  Tab,
  TabbedShowLayout,
  Datagrid,
  ArrayField,
  NumberField,
  FunctionField,
  EmailField,
  SimpleShowLayout,
} from "react-admin";
import Aside from "./aside";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import Avatar from "@material-ui/core/Avatar";

import { Tooltip, Typography } from "@material-ui/core";

import Link from "@material-ui/core/Link";

import DoneIcon from "@material-ui/icons/Done";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import {
  CustomDateField,
  formatDecimals,
  PayButton,
  bas_url,
  AddItemButton,
} from "../globals";

import { PaymentSlipPreview } from "./payment/components";

const OrderShow = (props) => {
  return (
    <Show
      {...props}
      actions={<ShowActions />}
      style={{ maxWidth: 1280, height: "100%" }}
      aside={<Aside order_id={props.id} />}
    >
      <SimpleShowLayout>
        <Header />

        <TabbedShowLayout>
          <Tab label="table_columns.customer">
            <TextField
              source="customer_first_name"
              label="table_columns.first_name"
            />
            <TextField
              source="customer_last_name"
              label="table_columns.last_name"
            />
            <EmailField source="customer_email" label="table_columns.email" />
          </Tab>
          <Tab label="table_columns.hotel">
            <ArrayField source="hotel_ids" label="">
              <Datagrid>
                <TextField source="hotel.name" label="table_columns.hotel" />
                <TextField
                  source="hotel.address"
                  label="table_columns.address"
                />
                <TextField source="hotel.phone" label="table_columns.phone" />
                <TextField source="hotel.email" label="table_columns.email" />
                <TextField source="room" label="table_columns.hotel_room" />
                <CustomDateField
                  formatedDate={true}
                  source="creation_date"
                  label="table_columns.date"
                />

                <FunctionField
                  label="table_columns.active"
                  render={(record) => (record.active ? <DoneIcon /> : null)}
                />
                <TextField source="notes" label="table_columns.notes" />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="table_columns.staff">
            <ArrayField source="staff_ids" label="">
              <Datagrid>
                <FunctionField
                  render={(record) => (
                    <Avatar alt={record.staff.name}>
                      <img
                        src={record.staff.image}
                        alt={record.staff.name}
                        style={{ width: "100%" }}
                      />
                    </Avatar>
                  )}
                />
                <TextField source="staff.name" label="table_columns.staff" />
                <NumberField
                  source="amount"
                  label="table_columns.amount"
                  options={{
                    style: "currency",
                    currency: "USD",
                  }}
                />

                <FunctionField
                  label="table_columns.main_seller"
                  render={(record) => (record.is_main ? <DoneIcon /> : null)}
                />
              </Datagrid>
            </ArrayField>
          </Tab>
          <Tab label="table_columns.payments">
            <FunctionField
              label=" "
              render={(record) =>
                record.discount > 0 && (
                  <Typography color="error">{`*Discount of $${record.discount} USD applied.`}</Typography>
                )
              }
            />
            <ArrayField source="payments" label=" ">
              <Datagrid>
                <FunctionField
                  label="table_columns.cashier"
                  render={(record) => (
                    <Tooltip title={record.staff.name} link="none">
                      <Avatar alt={record.staff.name}>
                        <img
                          src={record.staff.image}
                          alt={record.staff.name}
                          style={{ width: "100%" }}
                        />
                      </Avatar>
                    </Tooltip>
                  )}
                />
                <FunctionField
                  render={(record) => record.type.name.toUpperCase()}
                  label="type"
                />
                <FunctionField
                  label="table_columns.method"
                  render={(record) =>
                    record.is_cc ? (
                      <CreditCardIcon style={{ color: blue["600"] }} />
                    ) : (
                      <AttachMoneyIcon style={{ color: green["600"] }} />
                    )
                  }
                />
                <FunctionField
                  label="table_columns.amount"
                  sortable={false}
                  render={(record) => (
                    <Typography>
                      {`$
                ${formatDecimals(
                  (record.amount / record.rate).toFixed(2)
                )} USD`}
                    </Typography>
                  )}
                />
                <FunctionField
                  label="table_columns.balance"
                  sortable={false}
                  render={(record) => (
                    <Typography>{`$${formatDecimals(
                      record.balance
                    )} USD`}</Typography>
                  )}
                />

                <FunctionField
                  label="table_columns.paid_with"
                  sortable={false}
                  render={(record) => (
                    <Typography>{` ${record.currency.symbol}${formatDecimals(
                      record.amount
                    )} ${record.currency.alias.toUpperCase()}`}</Typography>
                  )}
                />
                <CustomDateField
                  source="creation_date"
                  label="table_columns.date"
                  formatedDate={true}
                  sortable={false}
                />

                <TextField
                  source="notes"
                  label="table_columns.notes"
                  sortable={false}
                />
              </Datagrid>
            </ArrayField>
            <FunctionField
              label="Make a Payment"
              render={(record) =>
                record.status !== "completed" ? (
                  <PayButton
                    record={{ id: record.id, status: record.status }}
                  />
                ) : (
                  <PayButton
                    record={{
                      id: record.id,
                      status: record.status,
                      type_id: 4,
                    }}
                  />
                )
              }
            />
            <FunctionField
              label="Print Receit"
              render={(record) => <PaymentSlipPreview record={record} />}
            />
          </Tab>
          <Tab label="table_columns.items">
            <FunctionField
              label=" "
              render={(record) =>
                record.discount > 0 && (
                  <Typography color="error">{`*Discount of $${record.discount} USD applied.`}</Typography>
                )
              }
            />
            <ArrayField source="items" label=" ">
              <Datagrid>
                <FunctionField label="id" render={(record) => record.id} />
                <FunctionField
                  label="table_columns.garment"
                  render={(record) => {
                    return (
                      <Link
                        href={`${bas_url}/i/${record.order_id}-${record.index}`}
                        color="primary"
                        target="_blank"
                      >
                        {record.garment.alias}
                      </Link>
                    );
                  }}
                />
                <TextField
                  label="table_columns.status"
                  source="status.status"
                />

                <FunctionField
                  label="table_columns.customer"
                  render={(record) => (
                    <Typography>{record.customer_name}</Typography>
                  )}
                />
                <FunctionField
                  label="Price"
                  render={(record) => (
                    <Typography
                      style={{ textAlign: "right" }}
                    >{`$${record.price} USD`}</Typography>
                  )}
                />
                <TextField source="notes" />
              </Datagrid>
            </ArrayField>
            <FunctionField
              label="Add Item"
              render={(record) => <AddItemButton record={{ id: record.id }} />}
            />
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

const Header = ({ record }) => {
  return record.disable_ids
    ? record.disable_ids.map((d) => (
        <Typography key={d.id} style={{ color: "#e53935" }} variant="h5">
          {`Huy Bill ${d.reason ? ` (${d.reason})` : ""}`}
        </Typography>
      ))
    : null;
};

const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    {data && <EditButton basePath={basePath} record={data} />}
    <ListButton basePath={basePath + "/all"} record={data} />
  </TopToolbar>
);

export default OrderShow;
