// in src/authProvider.js
//import decodeJwt from "jwt-decode";
import { api_uri } from "./globals";

export default {
  login: ({ username, password }) => {
    const request = new Request(api_uri + "/users/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        //console.log(response);
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((token) => {
        //console.log(token);
        //const decodedToken = decodeJwt(token);
        //localStorage.setItem("token", token);
        //localStorage.setItem("permissions", decodedToken.permissions);

        localStorage.setItem("username", token.username);

        localStorage.setItem("token", token.id);
        localStorage.setItem("role", token.role);
        localStorage.setItem("avatar", token.avatar);
        localStorage.setItem("user_id", token.userId);
        localStorage.setItem("store_id", token.store_id);
        localStorage.setItem("staff_id", token.staff_id);
        localStorage.setItem("permissions", token.permissions);
        localStorage.setItem("refresh", true);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");

    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("avatar");
    localStorage.removeItem("user_id");
    localStorage.removeItem("store_id");
    localStorage.removeItem("refresh");
    localStorage.removeItem("username");

    localStorage.removeItem("staff_id");

    return Promise.resolve();
  },
  checkError: (error) => {
    // ...
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
/*
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "react-admin";

import { api_uri } from "./globals";

import decodeJwt from "jwt-decode";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(api_uri + "/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(token => {
        // const decodedToken = decodeJwt(token.id);
        localStorage.setItem("username", token.username);

        localStorage.setItem("token", token.id);
        localStorage.setItem("role", token.role);
        localStorage.setItem("avatar", token.avatar);
        localStorage.setItem("user_id", token.userId);
        localStorage.setItem("store_id", token.store_id);
        localStorage.setItem("refresh", true);
      });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("avatar");
    localStorage.removeItem("user_id");
    localStorage.removeItem("store_id");
    localStorage.removeItem("refresh");
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    // ...
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem("role");
    return Promise.resolve(role);
  }

  return Promise.resolve();
};

*/
