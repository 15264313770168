import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  Toolbar,
  useCreate,
  useNotify,
  //useRedirect,
  SaveButton,
  NumberInput,
  AutocompleteInput,
} from "react-admin";

import { parse } from "query-string";

import { useFormState } from "react-final-form";

const ItemCreate = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;

  const { order_id: order_id_string } = parse(props.location.search);
  const order_id = order_id_string ? parseInt(order_id_string, 10) : "";

  return (
    <Create {...props}>
      <SimpleForm toolbar={<UserCreateToolbar />} initialValues={{ order_id }}>
        {/* ORDER ID */}
        <ReferenceInput
          reference="orders"
          source="order_id"
          filter={{ id: order_id }}
          validate={required()}
          disabled
        >
          <AutocompleteInput
            optionText={(record) => record && `${record.id}`}
          />
        </ReferenceInput>
        {/*
        <NumberInput
          label="table_columns.order"
          source="order_id"
          validate={required()}
          required={true}
        />
*/}
        {/* GARMENT ID */}
        <ReferenceInput
          reference="garments"
          source="garment_id"
          sort={{ field: "alias", order: "ASC" }}
          perPage={-1}
        >
          <SelectInput optionText="alias" />
        </ReferenceInput>
        {/* PRICE */}
        <NumberInput label="table_columns.item_price" source="price" />

        <TextInput source="customer_name" label="Customer Alias" />
      </SimpleForm>
    </Create>
  );
};

const UserCreateToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveCustomButton label="ra.action.save" redirect="list" />
  </Toolbar>
);

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  //const redirectTo = useRedirect();
  const formState = useFormState();
  //const { basePath, redirect } = props;

  const [create] = useCreate("items");
  //const [createOrigin] = useCreate("order_origins");
  //const [createSellers] = useCreate("order_sellers");
  //const [createHotel] = useCreate("order_hotels");
  //const [createLink] = useCreate("order_links");

  const handleClick = () => {
    console.log(formState.values);
    if (!formState.valid) {
      return;
    }
    /*
    let origins = formState.values.origin_ids
      ? formState.values.origin_ids
      : [];

    let staffs = formState.values.staff_ids ? formState.values.staff_ids : [];
    let hotels = formState.values.hotels ? formState.values.hotels : [];
    let links = formState.values.link ? formState.values.link : [];
*/
    create(
      {
        payload: {
          data: {
            staff_id: Number(localStorage.getItem("staff_id")),
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          /*origins.length &&
            origins.forEach((e) => {
              createOrigin(
                {
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      origin_id: e.id,
                      description: e.description,
                    },
                  },
                },
                {
                  onSuccess: ({ data: newRecord }) => {},
                }
              );
            });

          staffs.length &&
            staffs.forEach((e, i) => {
              createSellers(
                {
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      staff_id: e.staff_id,
                      amount: e.amount ? e.amount : 0,
                      is_main: i === 0 ? 1 : 0,
                    },
                  },
                },
                {
                  onSuccess: ({ data: newRecord }) => {},
                }
              );
            });

          hotels.length &&
            hotels.forEach((e, i) => {
              createHotel(
                {
                  payload: {
                    data: {
                      hotel_id: e.hotel_id,
                      room: e.room,
                      order_id: newRecord.id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                    },
                  },
                },
                {
                  onSuccess: ({ data: newRecord }) => {},
                }
              );
            });

          if (links.length > 0) {
            links.forEach((l) => {
              createLink({
                payload: {
                  data: {
                    order_id: newRecord.id,
                    linked_order_id: l.linked_order_id,
                    staff_id: Number(localStorage.getItem("staff_id")),
                    active: l.active,
                  },
                },
              });

              createLink({
                payload: {
                  data: {
                    linked_order_id: newRecord.id,
                    order_id: l.linked_order_id,
                    staff_id: Number(localStorage.getItem("staff_id")),
                    active: l.active,
                  },
                },
              });
            });
          }

          if (links.length > 0) {
            links.forEach((l) =>
              links.forEach(
                (m) =>
                  l !== m &&
                  createLink({
                    payload: {
                      data: {
                        linked_order_id: l.linked_order_id,
                        order_id: m.linked_order_id,
                        staff_id: Number(localStorage.getItem("staff_id")),
                        active: l.active,
                      },
                    },
                  })
              )
            );
          }
*/
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });

          /*
          !pay
            ? redirectTo(redirect, basePath + "/all", newRecord.id, newRecord)
            : redirectTo(
                `/payments/create?order_id=${newRecord.id}&type_id=1`,
                basePath,
                newRecord.id,
                newRecord
              );*/
        },
      }
    );
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default ItemCreate;
