// in src/App.js

import React from "react";
//import loopbackRestClient from "aor-loopback";
import authClient from "./authClient";
import { Admin, Resource, ListGuesser } from "react-admin";
import addUploadFeature from "./addUploadFeature.js";

import dataProvider from "./dataProvider";
// import { api_uri } from "./globals";

import Layout from "./templates/layout/Layout.js";

import { ThemeProvider } from "@material-ui/core/styles";

/*
import {
  OrdersLiveList,
  OrdersUnpaidList,
  OrdersFinalPaymentList,
  OrdersCompletedList,
  OrdersAllList
} from "./templates/orders";
import {
  DeliveryToDeliverList,
  DeliveryCompletedList,
} from "./templates/delivery";
import {
  FittingUpcomingList,
  FittingOngoingList,
  FittingTodayList,
} from "./templates/fittings";

*/
import {
  FactoryUnallocatedList,
  FactoryTailorList,
  FactoryLiveList,
  FactoryQCList,
  FactoryTailorScanner,
  FactoryCompletedList,
} from "./templates/factory";

//import { CutToCutList, CutCompletedList } from "./templates/cutter";

import bebeTheme from "./templates/layout/theme";
//import Dashboard from "./templates/dashboard";
import LoginWithTheme from "./login";

import orders from "./order";
import payments from "./payment";
import hotels from "./util/hotel";
import commisions from "./order_commission";
import garments from "./garment/type";
import garment_options from "./garment/option";
import garment_option_values from "./garment/value";
import garment_fabrics from "./garment/fabric";
import garment_option_value_fabrics from "./garment/value/fabric";
//import fabric_cuts from "./fabric/cut";
import swatchbooks from "./util/swatchbook";
import cuts from "./fabric/cut";

import locations from "./util/location";
import items from "./item";

import staffs from "./util/staff";
import order_payments from "./order/payment";
import collections from "./util/collection";
import measurements from "./util/measurement";

import { CustomerCreate } from "./customer/CustomerCreate";

//import { OrderList } from "./order/OrderList";
//import authProvider from "./authProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import vietnameseMessages from "ra-language-vietnamese";
import customVietnameseMessages from "./i18n/vn";
import customEnglishMessages from "./i18n/en";

const messages = {
  vn: { ...vietnameseMessages, ...customVietnameseMessages },
  en: { ...englishMessages, ...customEnglishMessages },
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

//const authProviders = authProvider(api_uri + "/login");

const uploadCapableClient = addUploadFeature(dataProvider);

const App = () => (
  <ThemeProvider theme={bebeTheme}>
    <Admin
      title="BeBe Tailor"
      dataProvider={uploadCapableClient}
      i18nProvider={i18nProvider}
      layout={Layout}
      //authProvider={authProvider}
      authProvider={authClient}
      //authProvider={authProviders}
      //dashboard={Dashboard}
      loginPage={LoginWithTheme}
    >
      {/* ORDERS */}
      {/*<Resource name="orders/allorders" list={OrdersAllList} />
      <Resource name="orders/unpaid" list={OrdersUnpaidList} />
      <Resource name="orders/final_payment" list={OrdersFinalPaymentList} />
      <Resource name="orders/live" list={OrdersLiveList} />
      
      
      <Resource name="orders/completed" list={OrdersCompletedList} />*/}

      <Resource name="orders/all" {...orders} />
      <Resource name="orders/pending" {...orders} />
      <Resource name="orders/detail" {...orders} />
      <Resource name="orders/unpaid" {...orders} />
      <Resource name="orders/completed" {...orders} />
      <Resource name="orders/huy_bill" {...orders} />
      <Resource name="items/all" {...items} />
      <Resource name="items/unallocated" {...items} />
      <Resource name="items/live" {...items} />
      <Resource name="fabric_cuts/to_cut" {...cuts} />
      <Resource name="fabric_cuts/cut" {...cuts} />

      <Resource name="orders" {...orders} />
      <Resource name="hotels" {...hotels} />
      <Resource name="order_hotels" />
      <Resource name="staff_payments" {...payments} />
      <Resource name="bas_order_commissions/unpaid" {...commisions} />
      <Resource name="bas_order_commissions/pending" {...commisions} />
      <Resource name="bas_order_commissions/approved" {...commisions} />
      <Resource name="bas_order_commissions/rejected" {...commisions} />
      <Resource name="bas_order_commissions" {...commisions} />
      <Resource name="garments" {...garments} />
      <Resource name="garment_options" {...garment_options} />
      <Resource name="garment_option_values" {...garment_option_values} />
      <Resource name="garment_fabrics" {...garment_fabrics} />
      <Resource
        name="garment_option_value_fabrics"
        {...garment_option_value_fabrics}
      />
      <Resource name="garment_garment_options" />

      <Resource name="locations" {...locations} />
      <Resource name="payment_types" />
      <Resource name="staff_types" />
      <Resource name="status_commissions" />
      <Resource name="staffs" {...staffs} />
      <Resource name="payments" {...order_payments} />
      <Resource name="customers" list={ListGuesser} create={CustomerCreate} />
      <Resource name="order_disableds" />
      <Resource name="fabric_locations" />
      <Resource name="fabrics" />
      <Resource name="fabric_types" />
      <Resource name="swatchbooks" {...swatchbooks} />
      <Resource name="fabrics_swatchbooks" />

      <Resource name="collections" {...collections} />
      <Resource name="fabric_collections" />
      <Resource name="measurements" {...measurements} />

      {/*<Resource name="fabric_cuts" {...fabric_cuts} /> */}

      <Resource name="bas_factory" list={FactoryUnallocatedList} />
      <Resource name="factory/tailors" list={FactoryTailorList} />
      <Resource name="factory/tailor_scan" list={FactoryTailorScanner} />
      <Resource name="factory/liveorders" list={FactoryLiveList} />
      <Resource name="factory/completed" list={FactoryCompletedList} />
      <Resource name="factory/qc" list={FactoryQCList} />
      <Resource name="cuts" />

      {/* DELIVERY 
      <Resource name="deliveries/complete" list={DeliveryCompletedList} />
      <Resource name="deliveries/todeliver" list={DeliveryToDeliverList} />

       CUTTER 
      <Resource name="cutter/tocut" list={CutToCutList} />
      <Resource name="cutter/completed" list={CutCompletedList} />


       FITTING 
      <Resource name="fitting/today" list={FittingTodayList} />
      <Resource name="fitting/upcoming" list={FittingUpcomingList} />
      <Resource name="fitting/ongoing" list={FittingOngoingList} />
      */}
      {/* <Resource name="staffs/sales" /> */}
      <Resource name="staff_types" />
      <Resource name="stores" />
      <Resource name="sellers" />
      <Resource name="origins" />
      <Resource name="users" />
      <Resource name="order_origins" />
      <Resource name="order_filters" />
      <Resource name="order_sellers" />
      <Resource name="exchange_rates" />
      <Resource name="app_staffs" />
      <Resource name="order_options" />
      <Resource name="item_statuses" />
      <Resource name="stock_locations" />

      {/* <Resource name="app_staffs" list={StaffList} create={StaffCreate} /> */}
      {/*  <Resource
        name="staffs"
        create={StaffCreate}
        edit={StaffEdit}
        show={StaffShow}
    /> */}
    </Admin>
  </ThemeProvider>
);

/*
const App = () => (
    <Admin
    menu={Menu}
    dashboard={Welcome}
    dataProvider={uploadCapableClient}
    loginPage={login}
    authProvider={authClient}
    messages={translations}
    customRoutes={customRoutes}
  >
        <Resource name="fittings/summary" show={OrderShow} />

    <Resource
      name="fabrics"
      list={FabricList}
      edit={FabricEdit}
      show={FabricShow}
      create={FabricCreate}
      
    />
    
    <Resource
      name="types"
      list={TypeList}
      edit={TypeEdit}
      create={TypeCreate}
      
    />
    <Resource
      name="colors"
      list={ColorList}
      edit={ColorEdit}
      create={ColorCreate}
      
      show={ColorShow}
    />
    <Resource
      name="suppliers"
      list={SupplierList}
      create={SupplierCreate}
      show={SupplierShow}
      
      edit={SupplierEdit}
    />
    <Resource name="color_depths" list={ColorDepthList} />
    <Resource
      name="locations"
      list={LocationList}
      create={LocationCreate}
      show={LocationShow}
      edit={LocationEdit}
      
    />
    <Resource name="fabric_locations" />
    <Resource
      name="swatchbook_locations"
      list={SwatchbookLocationList}
      create={SwatchbookLocationCreate}
      
    />

    <Resource name="stock_locations" />
    <Resource name="total_stocks" />

    <Resource
      name="stocks"
      create={StockCreate}
      show={StockShow}
      list={StockList}
      
      edit={StockEdit}
    />

    <Resource name="fabrics_suppliers" create={StockCreate} />
    <Resource name="fabrics_locations" />
    <Resource name="fabrics_types" />
    <Resource name="fabrics_colors" />

    <Resource
      name="measurements"
      create={MeasurementCreate}
      list={MeasurementList}
      edit={MeasurementEdit}
      show={MeasurementShow}
      
    />
    <Resource
      name="garments"
      list={GarmentList}
      show={GarmentShow}
      edit={GarmentEdit}
      create={GarmentCreate}
      
    />
    <Resource
      name="garment_garment_options"
      create={GGOCreate}
      list={GGOList}
      
    />
    <Resource
      name="garment_options"
      list={GarmentOptionList}
      create={GarmentOptionCreate}
      edit={GarmentOptionEdit}
      show={GarmentOptionShow}
      
    />
    <Resource
      name="garment_option_option_values"
      list={GOOList}
      create={GOOCreate}
      
    />
    <Resource
      name="garment_option_values"
      list={GarmentOptValueList}
      show={GarmentOptionValueShow}
      edit={GarmentOptionValueEdit}
      create={GarmentOptionValueCreate}
      
    />
    
   
    FactoryUnallocatedList
    <Resource
      name="exchange_rates"
      list={CurrencyList}
      create={CurrencyCreate}
      edit={CurrencyEdit}
    />

    
    
    <Resource name="factory/summary" show={OrderShow} />
    <Resource name="orders/summary" show={OrderShow} />
    
    <Resource name="orders/add" list={AddList} />
    <Resource name="summary" show={OrderShow} />
    <Resource name="orders/clear" list={AddList} />
    <Resource name="orders/unpaid" list={OrdersUnpaidList} show={OrderShow} />
    

    
    <Resource name="ifus" list={IFUList} create={IFUCreate} />
    <Resource name="status_logs" list={SLList} />


    <Resource
      name="hotels"
      list={HotelList}
      create={HotelCreate}
      edit={HotelEdit}
      show={HotelShow}
      
    />

    <Resource name="customers" />

    <Resource
      name="dictionaries"
      list={DictionaryList}
      create={DictionaryCreate}
      edit={DictionaryEdit}
      show={DictionaryShow}
      
    />
    <Resource
      name="garment_measurements"
      list={GarmentMeasurementList}
      create={GarmentMeasurementCreate}
      edit={GarmentMeasurementEdit}
      show={GarmentMeasurementShow}
      
    />
    
    <Resource name="tailors" />
    </Admin>
);
*/

export default App;
