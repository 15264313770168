import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  SaveButton,
  Toolbar
} from "react-admin";

const StaffEdit = props => {
  const required = (message = "Required") => value =>
    value ? undefined : message;
  return (
    <Edit {...props} title="resources.staff.fields.edit_title">
      <SimpleForm toolbar={<UserEditToolbar {...props} />}>
        <TextInput
          source="first_name"
          label="resources.staff.fields.first_name"
          validate={required()}
        />
        <TextInput
          source="middle_name"
          label="resources.staff.fields.middle_name"
          validate={required()}
        />
        <TextInput
          source="last_name"
          label="resources.staff.fields.family_name"
          validate={required()}
        />
        <TextInput
          source="nick_name"
          label="resources.staff.fields.nick_name"
          validate={required()}
        />
        <ReferenceInput
          validate={required()}
          label="resources.staff.fields.type"
          source="type_id"
          reference="staff_types"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          validate={required()}
          label="resources.staff.fields.store"
          source="store_id"
          reference="stores"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          validate={required()}
          source="phone_number"
          label="resources.staff.fields.phone_number"
        />
        <BooleanInput source="status" />
      </SimpleForm>
    </Edit>
  );
};

const UserEditToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  permissions,
  ...props
}) => (
  <Toolbar {...props}>
    <SaveButton label="ra.action.edit" redirect="show" />
  </Toolbar>
);

export default StaffEdit;
