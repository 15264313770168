import React from "react";

import {
  Filter,
  SearchInput,
  useTranslate,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const GarmentFabricFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput
        source="description.like"
        alwaysOn
        resettable
        placeholder={translate("table_columns.description")}
      />
      <ReferenceInput
        reference="garments"
        source="garment_id"
        sort={{ field: "alias", order: "ASC" }}
        allowEmpty={false}
      >
        <SelectInput optionText="alias" />
      </ReferenceInput>
    </Filter>
  );
};

export default GarmentFabricFilter;
