import React from "react";

import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ReferenceField,
  FunctionField,
  ImageField,
} from "react-admin";
import GarmentOptionValueFabricFilter from "./GarmentOptionValueFabricFilter";
import { CustomPagination, fabric_thumbnail } from "../../../globals";
import { Typography } from "@material-ui/core";

const GarmentOptionValueFabricList = (props) => (
  <List
    {...props}
    filters={<GarmentOptionValueFabricFilter />}
    title="resources.garment_types.list"
    sort={{ field: "id", order: "DESC" }}
    pagination={<CustomPagination />}
    bulkActionButtons={false}
    style={{ maxWidth: 1280, height: "100%" }}
  >
    <Datagrid>
      <TextField source="garment_option_value_id" label="value_id" />
      <ReferenceField
        reference="garment_option_values"
        source="garment_option_value_id"
        label="table_columns.description"
      >
        <TextField source="description" label="Option Values" />
      </ReferenceField>
      {/*
      <ReferenceField
        reference="fabrics"
        source="fabric_id"
        label="table_columns.fabric_code"
      >
        <TextField source="unique_code" />
      </ReferenceField>

      <TextField source="fabric_id" label="fabric_id" />
*/}
      <ReferenceField
        reference="fabrics"
        source="fabric_id"
        label="table_columns.fabric"
      >
        <FunctionField
          render={(record) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                width={100}
                src={fabric_thumbnail + record.image}
                alt={record.unique_code}
              />
              <Typography variant="caption" component="b">
                {record.unique_code}
              </Typography>
            </div>
          )}
        />
      </ReferenceField>

      <ReferenceField
        reference="garment_option_values"
        label="Icon"
        source="garment_option_value_id"
      >
        <ImageField source="image" />
      </ReferenceField>

      <ImageField
        source="preview_front"
        title="Preview Front"
        label="Preview Front"
      />
      <ImageField
        source="preview_back"
        title="Preview Back"
        label="Preview Back"
      />
      <ImageField
        source="preview_detail"
        title="Preview Detail"
        label="Preview Detail"
      />

      <TextField source="description" />

      <EditButton />
    </Datagrid>
  </List>
);

export default GarmentOptionValueFabricList;
