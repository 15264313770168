import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
/*
import OrderCreate from "./OrderCreate";
import OrderEdit from "./OrderEdit";
import OrderList from "./OrderList";
import OrderShow from "./OrderShow";
*/
import HotelList from "./HotelList";
import HotelEdit from "./HotelEdit";
import HotelShow from "./HotelShow";
import HotelCreate from "./HotelCreate";

export default {
  create: HotelCreate,
  edit: HotelEdit,
  list: HotelList,
  show: HotelShow,
  icon: ChatBubbleIcon
};
