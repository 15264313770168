import React from "react";

import {
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  ArrayField,
  FunctionField,
  ImageField,
} from "react-admin";

import { Actions } from "./GarmentComponent";
import { ReferenceField } from "react-admin";

const GarmentShow = (props) => {
  //const translate = useTranslate();

  return (
    <Show
      {...props}
      title="resources.garment.show"
      actions={<Actions />}
      style={{ maxWidth: 1280, height: "100%" }}
      basePath="/garments"
    >
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="alias" />
        <TextField source="vietnamese" />
        <FunctionField
          label="table_columns.gender"
          render={(record) => (record.gender === 1 ? "Men" : "Women")}
        />

        <TextField source="order" />
        <ImageField source="image" title="name" />
        <ImageField source="preview" title="name" />

        <ArrayField source="option_ids">
          <Datagrid>
            <TextField label="table_columns.id" source="option.id" />

            <ReferenceField
              source="option.id"
              reference="garment_options"
              label="table_columns.name"
            >
              <TextField source="name" />
            </ReferenceField>

            <TextField
              label="table_columns.description"
              source="option.description"
            />
            <TextField
              label="table_columns.vietnamese"
              source="option.vietnamese"
            />
            <TextField source="index" label="table_columns.index" />
            <TextField source="default_preview" label="Preview" />

            <ArrayField source="option.value_ids" label="table_columns.values">
              <SingleFieldList
                style={{
                  display: "block",
                  marginTop: 5,
                  marginBottom: 5,
                }}
                linkType={false}
              >
                <FunctionField
                  link={"show"}
                  render={(record) => {
                    return record.value ? (
                      <div>{`${record.value.id}: ${record.value.description}`}</div>
                    ) : (
                      "-"
                    );
                  }}
                />
              </SingleFieldList>
            </ArrayField>
            <ArrayField source="option.value_ids" label="value name">
              <SingleFieldList
                style={{
                  display: "block",
                  marginTop: 5,
                  marginBottom: 5,
                }}
                linkType={false}
              >
                <ReferenceField
                  link="show"
                  source="value.id"
                  reference="garment_option_values"
                >
                  <FunctionField
                    render={(record) => <div>{record.name}</div>}
                  />
                </ReferenceField>
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </ArrayField>

        <ArrayField source="measurements">
          <Datagrid>
            <TextField source="type_eng" label="table_columns.english" />

            <TextField source="type_viet" label="table_columns.vietnamese" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default GarmentShow;
