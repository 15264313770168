import React from "react";

import { Filter, SearchInput, useTranslate } from "react-admin";

const GarmentOptionValueFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput
        source="description.like"
        alwaysOn
        resetteable="true"
        placeholder={translate("table_columns.garment_option_values")}
      />
    </Filter>
  );
};

export default GarmentOptionValueFilter;
