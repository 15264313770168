import React from "react";

import { Filter, SearchInput, useTranslate } from "react-admin";

const LocationFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput
        source="name.like"
        alwaysOn
        resetteable="true"
        placeholder={translate("table_columns.location")}
      />
    </Filter>
  );
};

export default LocationFilter;
