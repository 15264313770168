import React from "react";
import {
  TextField,
  FunctionField,
  ReferenceField,
  SimpleShowLayout,
  Show,
  TopToolbar,
  EditButton,
  ListButton
} from "react-admin";

import { styles, api_uri } from "../../globals";
import { Typography } from "@material-ui/core";

const StaffShow = props => (
  <Show
    {...props}
    title="resources.staff.fields.show_title"
    actions={<ShowActions />}
  >
    <SimpleShowLayout style={styles.row}>
      <SimpleShowLayout style={styles.column1}>
        <TextField
          source="first_name"
          label="resources.staff.fields.first_name"
        />

        <TextField
          label="resources.staff.fields.middle_name"
          source="middle_name"
        />

        <TextField
          label="resources.staff.fields.family_name"
          source="last_name"
        />
        <TextField
          label="resources.staff.fields.nick_name"
          source="nick_name"
        />

        <ReferenceField
          label="resources.staff.fields.type"
          source="type_id"
          reference="staff_types"
        >
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          label="resources.staff.fields.store"
          source="store_id"
          reference="stores"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField
          source="phone_number"
          label="resources.staff.fields.phone_number"
        />

        <FunctionField
          label="Status"
          render={record => (
            <Typography>{record.status ? "Active" : "Not Active"}</Typography>
          )}
        />

        {/* <TextField
            source="base_salary"
            label="resources.staff.fields.base_salary"
          /> */}
      </SimpleShowLayout>

      <SimpleShowLayout style={styles.column2}>
        <ImageUrlField style={styles.img} source="image" />
      </SimpleShowLayout>
    </SimpleShowLayout>
  </Show>
);
const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
const ImageUrlField = ({ record = {} }) =>
  record.image ? (
    <img
      src={api_uri + "/containers/bebe-staff/download/" + record.image}
      alt="Staff"
      width="300px"
    />
  ) : (
    <img
      width="100px"
      height="100px"
      src={api_uri + "/containers/bebe-staff/download/placeholder.jpg"}
      alt="Staff"
    />
  );
ImageUrlField.defaultProps = { label: "image" };

export default StaffShow;
