import React from "react";

import {
  TextInput,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SelectInput
} from "react-admin";

const statuses = [
  { id: "unpaid", name: "Unpaid Orders" },
  { id: "with_cutter", name: "Cutter" },
  { id: "with_factory", name: "Factory" },
  { id: "with_first_qc", name: "First QC" },
  { id: "with_fitting", name: "Fitting" },
  { id: "with_final_qc", name: "Final QC" },
  { id: "for_delivery", name: "For Delivery" },
  { id: "completed", name: "Completed" },
  { id: "pending_final_payment", name: "Final Payment" }
];

export const OrderFilters = props => (
  <Filter {...props} variant="standard">
    {Number(localStorage.getItem("store_id")) === 0 && (
      <ReferenceInput
        label="table_columns.store"
        source="store_id"
        reference="stores"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}

    <TextInput label="table_columns.order" source="id" />
    <TextInput label="Customer Name" source="customer_name" />

    <SelectInput
      source="order+status"
      label="Status"
      defaultValue={3}
      choices={statuses}
    />

    <ReferenceInput
      {...props}
      label="table_columns.staff"
      source="staff_id"
      reference="staffs"
      perPage={10000}
      //helperText="Select Staff"
      //sort={{ field: "staff", order: "ASC" }}
      //filterToQuery={searchText => ({ staffs: searchText })}
      limitChoicesToValue={true}
    >
      <AutocompleteInput optionText="nick_name" />
    </ReferenceInput>
  </Filter>
);

export const ColorFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="resources.fabrics.fields.color"
      source="id"
      reference="colors"
      allowEmpty={true}
    >
      <AutocompleteInput optionText="description" />
    </ReferenceInput>
  </Filter>
);

export const FactoryFilters = props => (
  <Filter {...props}>
    {Number(localStorage.getItem("store_id")) === 0 && (
      <ReferenceInput
        label="table_columns.store"
        source="store_id"
        reference="stores"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
    <TextInput label="Order Number" source="item+order_id" />
    <TextInput label="Item Number" source="item" />
    <ReferenceInput
      {...props}
      label="table_columns.staff"
      source="staff_id"
      reference="staffs"
      perPage={10000}
      sort={{ field: "nick_name", order: "ASC" }}
      filterToQuery={searchText => ({ nick_name: searchText })}
      limitChoicesToValue={true}
    >
      <AutocompleteInput optionText="nick_name" />
    </ReferenceInput>
  </Filter>
);
export const CutFilters = props => (
  <Filter {...props}>
    <TextInput label="Order Number" source="order_id" />
    <TextInput label="Item Number" source="item_id" />
    <ReferenceInput
      label="Sales Staff"
      source="staff_id"
      reference="staffs/sales"
      allowEmpty
      sort={{ field: "nick_name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="nick_name"
        //filter={AutoComplete.caseInsensitiveFilter}
        options={{
          //filter: AutoComplete.fuzzyFilter,
          listStyle: { overflow: "auto", maxHeight: 200 }
        }}
      />
    </ReferenceInput>
    <ReferenceInput
      label="table_columns.store"
      source="order+store_id"
      reference="stores"
      allowEmpty
    >
      <AutocompleteInput
        optionText="name"
        // filter={AutoComplete.caseInsensitiveFilter}
        options={{
          // filter: AutoComplete.fuzzyFilter,
          listStyle: { overflow: "auto", maxHeight: 200 }
        }}
      />
    </ReferenceInput>
  </Filter>
);

export const QCFilters = props => (
  <Filter {...props}>
    <TextInput label="Order Number" source="order_id" />
    <TextInput label="Item Number" source="item_id" />
    <ReferenceInput
      label="Sales Staff"
      source="staff_id"
      reference="staffs/sales"
      allowEmpty
      sort={{ field: "nick_name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="nick_name"
        //filter={AutoComplete.caseInsensitiveFilter}
        options={{
          //filter: AutoComplete.fuzzyFilter,
          listStyle: { overflow: "auto", maxHeight: 200 }
        }}
      />
    </ReferenceInput>

    <ReferenceInput
      label="table_columns.store"
      source="order+store_id"
      reference="stores"
      allowEmpty
    >
      <AutocompleteInput
        optionText="name"
        //filter={AutoComplete.caseInsensitiveFilter}
        options={{
          //filter: AutoComplete.fuzzyFilter,
          listStyle: { overflow: "auto", maxHeight: 200 }
        }}
      />
    </ReferenceInput>

    <SelectInput
      source="type"
      label="QC Type"
      choices={[
        { id: "first", name: "First QC" },
        { id: "final", name: "Final QC" }
      ]}
    />
  </Filter>
);
