// in src/garments.js
import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  ListButton,
  TopToolbar,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from "react-admin";

const GarmentFabricEdit = (props) => {
  return (
    <Edit {...props} title="Edit Garment Option Value" actions={<Actions />}>
      <SimpleForm>
        <TextInput source="name" label="table_columns.name" />
        <TextInput source="description" label="table_columns.description" />

        <ReferenceInput
          reference="garments"
          source="garment_id"
          sort={{ field: "alias", order: "ASC" }}
        >
          <SelectInput optionText="alias" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div>
              <ReferenceInput
                reference="fabric_types"
                source="type_id"
                sort={{ field: "alias", order: "ASC" }}
                perPage={-1}
                {...rest}
                onChange={() => (formData.unique_code = 7822)}
              >
                <AutocompleteInput optionText="alias" {...rest} />
              </ReferenceInput>
              <ReferenceInput
                {...rest}
                reference="fabrics"
                source="fabric_id"
                filter={{ type_id: formData.type_id }}
                sort={{ field: "unique_code", order: "ASC" }}
                perPage={-1}
                options={{
                  maxHeight: 200,
                }}
              >
                <AutocompleteInput optionText="unique_code" />
              </ReferenceInput>
            </div>
          )}
        </FormDataConsumer>

        <ImageInput
          source="previews"
          label="table_columns.preview"
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="preview" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.previews) {
              return (
                <div>
                  <Labeled label="Original image">
                    <ImageField source="preview" {...rest} />
                  </Labeled>
                </div>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentFabricEdit;
