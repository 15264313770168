import React, { useEffect } from "react";
import { useTranslate, useQuery, useDataProvider } from "react-admin";
import Link from "@material-ui/core/Link";

import { CustomDateField, formatDecimals, thumbnail_url } from "../../globals";
import { OrderStatus, BookCodeField } from "../../components";
import { Typography, Divider, LinearProgress } from "@material-ui/core";
//import classnames from "classnames";
import _ from "lodash";
import red from "@material-ui/core/colors/red";

import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

const Aside = ({ order_id }) => {
  const dataProvider = useDataProvider();

  const [order, setOrder] = React.useState();

  useEffect(() => {
    dataProvider
      .getOne("orders", {
        id: order_id,
      })
      .then(({ data }) => {
        setOrder(data);
      });
  }, []);

  const translate = useTranslate();

  const useStyles = makeStyles({
    root: {
      minWidth: 300,
      maxWidth: 300,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    unpaid: { color: "white", backgroundColor: "#e53935" },
    completed: { color: "white", backgroundColor: "#43a047" },
    pending_final_payment: { color: "white", backgroundColor: "#fb8c00" },
    flat: { boxShadow: "none" },
  });
  const classes = useStyles();

  return order ? (
    <Card
      className={classes.root}
      variant="outlined"
      style={{ marginLeft: 10 }}
    >
      <CardContent>
        {/* STORE */}
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {translate("table_columns.store")}
            </Typography>
            <Tooltip title={order.store ? order.store.name : "Bebe"}>
              <Avatar
                alt={order.store && order.store.name}
                style={{
                  backgroundColor: order.store && order.store.color,
                  color: "white",
                }}
              >
                {order.store && order.store.alias}
              </Avatar>
            </Tooltip>
          </div>
          <div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {translate("table_columns.status")}
            </Typography>
            <OrderStatus record={order} />
          </div>
          <div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Created by
            </Typography>
            {order.staff_id && <StaffAvatar staff_id={order.staff_id} />}
          </div>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        {/* ID */}
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {translate("table_columns.id")}
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {order.id}
        </Typography>

        <Divider style={{ marginTop: 10, marginBottom: 20 }} />

        {/* BOOK/CODE */}
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {translate("table_columns.book_code")}{" "}
        </Typography>
        <BookCodeField record={order} link={false} />

        <Divider style={{ marginTop: 10, marginBottom: 20 }} />

        {/* DATE */}
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {translate("table_columns.creation_date")}
        </Typography>
        <CustomDateField
          source="timestamp"
          formatedDate={true}
          utc={false}
          record={order}
          //order={{ source: order.timestamp }}
        />

        <Divider style={{ marginTop: 10, marginBottom: 20 }} />

        {/* CUSTOMER */}

        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {translate("table_columns.customer")}
        </Typography>
        <Typography variant="subtitle1">{`${order.customer_first_name} ${order.customer_last_name}`}</Typography>
        <Typography variant="caption" component="b">{`${
          order.customer_email ? order.customer_email : "No e-mail"
        }`}</Typography>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />

        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {translate("table_columns.order_summary")} [USD]
        </Typography>

        {order.discount > 0 ? (
          <div>
            <Typography variant="subtitle1">{`Total Before discount: $${formatDecimals(
              order.total_price
            )}`}</Typography>
            <Typography variant="subtitle1">{`${translate(
              "table_columns.discount"
            )} $${formatDecimals(order.discount)}`}</Typography>
            <Typography
              style={{ marginTop: 10, marginBottom: 10, color: red["600"] }}
              variant="h6"
            >{`Amount to pay: $${formatDecimals(
              order.total_price - order.discount
            )}`}</Typography>
          </div>
        ) : (
          <Typography
            style={{ marginTop: 10, color: red["600"] }}
            variant="h6"
          >{`Amount to pay: $${formatDecimals(
            order.total_price - order.discount
          )}`}</Typography>
        )}
        <Typography variant="subtitle1">{`${translate(
          "table_columns.balance"
        )}: $${order && order.balance}`}</Typography>

        {/* LINKED ORDER */}
        {order.link && order.link.length ? (
          <div>
            <Divider style={{ marginTop: 10, marginBottom: 20 }} />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {translate("table_columns.linked_order")}
            </Typography>
            {order.link.map(
              (o) =>
                o.active &&
                o.order &&
                !o.order.disabled && (
                  <div key={o.id}>
                    <Typography
                      variant="subtitle1"
                      component={Link}
                      to={`/orders/${o.linked_order_id}/show`}
                      style={{ color: red["A600"], textAlign: "right" }}
                    >
                      {o.order && o.order.book && o.order.code
                        ? `${o.order.book}/${_.padStart(o.order.code, 4, "0")}`
                        : "Pending"}
                    </Typography>
                  </div>
                )
            )}
          </div>
        ) : null}
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />

        {/* CUSTOMER ORIGIN */}
        {order.origin_ids && order.origin_ids.length && (
          <div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {translate("table_columns.origin")}
            </Typography>
            {order.origin_ids.map((o) => (
              <Typography key={o.id}>{`${
                o.origin ? o.origin.description : ""
              } ${o.description ? `: ${o.description}` : ""}  `}</Typography>
            ))}
            <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          </div>
        )}

        {/* NOTES */}
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {translate("table_columns.notes")}
        </Typography>

        <Typography>
          {order.notes
            ? order.notes.replace(/<[^>]*>?/gm, "")
            : translate("table_columns.no_notes")}
        </Typography>

        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      </CardContent>
    </Card>
  ) : (
    <Card
      className={classes.root}
      variant="outlined"
      style={{ marginLeft: 10 }}
    >
      <LinearProgress />
    </Card>
  );
};
const StaffAvatar = ({ staff_id }) => {
  //const { data, loading, error } = useQuery({
  const { data, loading } = useQuery({
    type: "getOne",
    resource: "staffs",
    payload: { id: staff_id },
  });

  return !loading ? (
    <Tooltip title={data ? data.nick_name : "loading..."}>
      {data ? (
        <Avatar
          alt={data && data.nick_name}
          src={data && thumbnail_url + data.thumbnail}
        />
      ) : (
        <Avatar>S</Avatar>
      )}
    </Tooltip>
  ) : (
    "loading"
  );
};
export default Aside;
