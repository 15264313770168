// in src/garments.js
import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  ListButton,
  TopToolbar,
  FormDataConsumer,
  Labeled,
} from "react-admin";

import { Divider } from "@material-ui/core";

const GarmentOptionValueEdit = (props) => {
  return (
    <Edit {...props} title="Edit Garment Option Value" actions={<Actions />}>
      <SimpleForm>
        <TextInput source="name" label="Garment Option Value Name" />
        <TextInput source="description" label="Description" />
        <TextInput source="vietnamese" label="Vietnamese" />
        <TextInput
          source="subheading"
          label="table_columns.subheading"
          fullWidth
        />

        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="image" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.images) {
              return <ImageField source="image" {...rest} />;
            }
          }}
        </FormDataConsumer>
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>

        <ImageInput
          source="previews"
          label="table_columns.preview"
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="preview" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.previews) {
              return (
                <div>
                  <Labeled label="Original image">
                    <ImageField source="preview" {...rest} />
                  </Labeled>
                </div>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentOptionValueEdit;
