import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  SaveButton,
  Toolbar,
  TopToolbar,
  ListButton,
  FormDataConsumer,
} from "react-admin";
import PropTypes from "prop-types";

import { PaymentInput } from "./components";
import { Divider } from "@material-ui/core";

import { useCreate, useNotify, useRedirect } from "react-admin";
import { useFormState } from "react-final-form";

import { parse } from "query-string";

import Aside from "../aside";

const ListActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const PaymentCreate = (props) => {
  const { order_id: order_id_string } = parse(props.location.search);
  const { type_id: type_id_string } = parse(props.location.search);

  const order_id = order_id_string ? parseInt(order_id_string, 10) : "";
  const type_id = type_id_string ? parseInt(type_id_string, 10) : "";

  const redirect = order_id ? `/orders/${order_id}/show/4` : false;

  return (
    <Create
      {...props}
      actions={<ListActions />}
      title="Payment"
      style={{ maxWidth: 1280 }}
      aside={order_id ? <Aside order_id={order_id} /> : null}
      component="div"
    >
      {order_id ? (
        <SimpleForm
          validate={PaymentValidation}
          toolbar={<UserCreateToolbar order_id={order_id} />}
          redirect={redirect}
          initialValues={{
            store_id: Number(localStorage.getItem("store_id")),
            currency_id: null,
            staff_id: Number(localStorage.getItem("staff_id")),
            order_id,
            type_id,
            rate: 1,
            amount_usd: null,
          }}
        >
          <Fragment>
            <FormDataConsumer>
              {(formDataProps) => (
                <Fragment>
                  <PaymentInput order_id={order_id} {...formDataProps} />
                </Fragment>
              )}
            </FormDataConsumer>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
          </Fragment>
        </SimpleForm>
      ) : (
        <SimpleForm>
          <Fragment>Select an order to make a Payment</Fragment>
        </SimpleForm>
      )}
    </Create>
  );
};

const UserCreateToolbar = ({
  invalid,
  disabled,
  permissions,
  order_id,
  ...props
}) => (
  <Toolbar {...props}>
    <SaveCustomButton
      order_id={order_id}
      label="PAY"
      redirect="list"
      //disabled={disabled}
      disabled={invalid}
    />
    {/*  <SaveButton /> */}
  </Toolbar>
);

const SaveCustomButton = ({ invalid, record, order_id, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath } = props;
  const [create] = useCreate("payments");
  const [create_shipping] = useCreate("shipping_commissions");

  // const dataProvider = useDataProvider();

  const handleClick = () => {
    console.log("payload", formState.values);
    if (!formState.valid) {
      return;
    }

    create(
      {
        payload: {
          data: {
            is_cc: formState.values.currency_id === (7 || 8) ? 1 : 0,
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          formState.values.type_id =
            3 &&
            create_shipping({
              payload: {
                data: {
                  staff_id: formState.values.shipping_seller_id,
                  payment_id: newRecord.id,
                },
              },
            });
          redirectTo(
            `/orders/${order_id}/show/3`,
            basePath,
            newRecord.id,
            newRecord
          );
        },
      }
    );
  };

  /*, [
    formState.valid,
    formState.values,
    create,
    notify,
    redirectTo,
    redirect,
    basePath
  ]*/
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};
SaveCustomButton.propTypes = {
  invalid: PropTypes.bool,
};
const PaymentValidation = async (values) => {
  const errors = {};
  //AMOUNT USD < BALANCE
  if (values.amount_usd > values.balance) {
    errors.amount_usd = ["Amount should be less than the balance to pay"];
  }

  return errors;
};

export default PaymentCreate;
