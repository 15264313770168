import React, { Fragment } from "react";

import {
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  ArrayField,
  TopToolbar,
  EditButton,
  ListButton,
  useTranslate,
  ReferenceField,
} from "react-admin";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const GarmentOptionShow = (props) => {
  const translate = useTranslate();

  return (
    <Show
      {...props}
      title="resources.garment.show"
      actions={<ShowActions />}
      style={{ maxWidth: 1280, height: "100%" }}
    >
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="vietnamese" />
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.values").toUpperCase()}
          </Typography>
        </Fragment>
        <ArrayField source="value_ids" label="">
          <Datagrid>
            <TextField label="table_columns.id" source="value.id" />
            <ReferenceField
              source="value.id"
              reference="garment_option_values"
              label="table_columns.name"
            >
              <TextField source="name" />
            </ReferenceField>

            <TextField
              label="table_columns.description"
              source="value.description"
            />
            <TextField
              label="table_columns.vietnamese"
              source="value.vietnamese"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    {data && <EditButton basePath={basePath} record={data} />}
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentOptionShow;
