import React, { Fragment, useEffect } from "react";

import {
  SimpleForm,
  SelectInput,
  useTranslate,
  useUpdate,
  useRefresh,
  useNotify,
  useQueryWithStore,
  useDataProvider,
} from "react-admin";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import classnames from "classnames";
import { Typography, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { thumbnail_url } from "./globals";
import { mdiAccountEdit } from "@mdi/js";
import Icon from "@mdi/react";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//COLORS
import red from "@material-ui/core/colors/red";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import indigo from "@material-ui/core/colors/indigo";
import orange from "@material-ui/core/colors/orange";

import grey from "@material-ui/core/colors/grey";
import blueGrey from "@material-ui/core/colors/blueGrey";

const color_level = "600";
const useStyles = makeStyles({
  //ITEMS
  iPending: { color: "white", backgroundColor: red[color_level] },

  iToCut: { color: "white", backgroundColor: purple[color_level] },
  iCut: { color: "white", backgroundColor: purple[color_level] },

  unallocated: { color: "white", backgroundColor: orange[color_level] },

  with_tailor: { color: "white", backgroundColor: blue[color_level] },

  fqc: { color: "white", backgroundColor: indigo[color_level] },
  scq: { color: "white", backgroundColor: indigo[color_level] },

  fitting: { color: "white", backgroundColor: blueGrey[color_level] },
  icompleted: { color: "white", backgroundColor: green[color_level] },

  //ORDERS

  unpaid: { color: "white", backgroundColor: red[color_level] },
  completed: { color: "white", backgroundColor: green[color_level] },
  paid_with_tailor: { color: "white", backgroundColor: blue[color_level] },
  paid_with_cutter: { color: "white", backgroundColor: purple[color_level] },
  paid_with_factory: { color: "white", backgroundColor: orange[color_level] },

  paid: { color: "white", backgroundColor: orange[color_level] },

  pending_final_payment: {
    color: "white",
    backgroundColor: orange[color_level],
  },

  with_factory: { color: "white", backgroundColor: orange[color_level] },
  with_cutter: { color: "white", backgroundColor: purple[color_level] },

  flat: { boxShadow: "none" },
});

export const StaffField = ({ record, source }) => {
  switch (source) {
    case "order":
      record = record.order;
      break;
    case "item":
      record = record.item.order;
      break;
    default:
      break;
  }

  const staff_ids = record.staff_ids;

  return (
    <div
      style={{ display: "flex" }}
      onClick={(event) => event.stopPropagation()}
    >
      {staff_ids
        ? _.sortBy(staff_ids, (x) => x.is_main)
            .reverse()
            .map(
              (s) =>
                s.staff && (
                  <Tooltip title={s.staff.name} key={s.id}>
                    <Avatar
                      component={Link}
                      to={`/staffs/${s.staff.id}/show`}
                      alt={s.staff.name}
                      src={s.staff.image}
                      style={{ marginRight: 5 }}
                    />
                  </Tooltip>
                )
            )
        : "NO STAFF"}
    </div>
  );
};

export const ItemStatus = ({ record }) => {
  const status = record.status.status;
  const id = record.status.id;
  const classes = useStyles();
  return (
    <Tooltip title={status.toUpperCase()}>
      <Avatar
        className={classnames({
          [classes.iPending]: id === 1,
          [classes.iToCut]: id === 2,
          [classes.iCut]: id === 3,
          [classes.unallocated]: id === 4,
          [classes.with_tailor]: id === 5,
          [classes.fqc]: id === 6,
          [classes.scq]: id === 7,
          [classes.fitting]: id === 8,
          [classes.icompleted]: id === 11,
        })}
      >
        <Typography>{record.status.alias}</Typography>
      </Avatar>
    </Tooltip>
  );
};

export const OrderStatus = ({ record }) => {
  const status = record.status.status;
  const id = record.status.id;

  const classes = useStyles();
  return (
    <Tooltip title={status}>
      <Avatar
        className={classnames({
          [classes.unpaid]: id === 1,
          [classes.with_cutter]: id === 2,
          [classes.paid_with_cutter]: id === 5,
          [classes.with_factory]: id === 3,
          [classes.paid_with_factory]: id === 6,
          [classes.completed]: id === 10,
          [classes.paid]: id === 7,

          // [classes.completed]: status === "completed",
          //[classes.pending_final_payment]: status === "pending_final_payment",
          //[classes.with_factory]: status === "with_factory",
        })}
      >
        <Typography>
          {id === 1 && "UNP"}
          {id === 2 && "WC"}
          {id === 3 && "WF"}
          {id === 4 && "PFP"}
          {id === 5 && "PWC"}
          {id === 6 && "PWF"}
          {id === 7 && "P"}
          {id === 10 && "C"}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};

export const CustomerField = ({ record }) => {
  return (
    <div>
      <Typography>
        {`${record.customer_first_name ? record.customer_first_name : ""} ${
          record.customer_last_name ? record.customer_last_name : ""
        }`}
      </Typography>
      <Typography variant="caption" component="b">
        {record.customer_email ? record.customer_email : "No e-mail"}
      </Typography>
    </div>
  );
};

export const OrderField = ({ record, source, link = true }) => {
  let notes;

  switch (source) {
    case "id":
      notes = record.notes;
      break;
    case "order_id":
      notes = record.order.notes;
      break;
    default:
      break;
  }

  const order_id = record[source].order_id
    ? record[source].order_id
    : record[source];
  return (
    <Tooltip title={notes ? `Order includes notes` : ""}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography
          component={link ? Link : Typography}
          to={`/orders/${order_id}/show/4`}
        >
          {order_id}
        </Typography>
        {notes && <Typography color="error">*</Typography>}
      </div>
    </Tooltip>
  );
};

export const ItemField = ({ record, source }) => {
  return (
    <Typography component={Link} to={`/items/${record.id}/show`}>
      {record[source]}
    </Typography>
  );
};

export const GarmentField = ({ record, source }) => {
  switch (source) {
    case "item":
      record = record[source].garment;
      break;
    case "garment":
      record = record[source];
      break;
    case undefined:
      break;
    default:
      break;
  }
  const alias = record.alias;

  const image = record.image;

  return (
    <div style={{ display: "flex" }}>
      <Avatar src={image} />
      <Typography style={{ alignSelf: "center", marginLeft: 5 }}>
        {alias}
      </Typography>
    </div>
  );
};

export const BookCodeField = ({ record, source, link = true }) => {
  switch (source) {
    case "item":
      record = record.order;
      break;
    case "cut":
      record = record.item.order;
      break;
    case "payment":
      record = record;
      break;
    case undefined:
      break;
    default:
      break;
  }

  const book = record.book;
  const code = record.code;

  let storeAlias = record.store.alias;
  const orderId = record.id;

  let flag = link && source !== "order";

  return (
    <Typography
      component={flag ? Link : null}
      to={`/orders/${record.order_id ? record.order_id : record.id}/show`}
    >
      {`${storeAlias}/${orderId}`}
    </Typography>
  );
};

export const TailorList = ({ record, source }) => {
  let { tailor } = record.garment;

  const { id } = record;
  const translate = useTranslate();
  const [tailor_id, setTailor] = React.useState(null);
  const [updateItem] = useUpdate("items");
  const refresh = useRefresh();
  const notify = useNotify();

  const Tailor = ({ record }) => {
    let { tailor_load } = record;
    return (
      <div style={{ display: "flex" }}>
        <Avatar src={thumbnail_url + record.thumbnail} />
        <div key={record.id}>
          <Typography style={{ marginLeft: 5, alignSelf: "center" }}>
            {record.nick_name}
          </Typography>
          <Typography
            style={{ marginLeft: 5, alignSelf: "center" }}
            component={"b"}
          >
            {tailor_load
              ? `${tailor_load.items} items${
                  tailor_load.last_item !== null
                    ? `, last item on: ${tailor_load.last_item}`
                    : ""
                }`
              : "NO ITEMS"}
          </Typography>
        </div>
      </div>
    );
  };

  const handleAllocate = () => {
    updateItem(
      {
        payload: {
          id,
          data: {
            tailor_id,
            status_id: 5,
          },
        },
      },
      {
        onSuccess: () => {
          refresh();
          notify(
            "ra.notification.created",
            "info"
            //`Cut ${length}m of fabric ${props.record.fabric_code} from location ${location}`
          );
        },
      }
    );
  };

  return (
    <SimpleForm toolbar={<NulledToolbar />}>
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <SelectInput
            id={record.id.toString()}
            label={translate("table_columns.tailor")}
            optionText={<Tailor />}
            choices={tailor}
            source="id"
            optionValue="id"
            onChange={(e) => {
              setTailor(e.target.value);
            }}
          />
          <Button
            type="button"
            action={null}
            onClick={() => handleAllocate()}
            disabled={!tailor_id}
          >
            <Typography>Allocate</Typography>
          </Button>
        </div>
      </Fragment>
    </SimpleForm>
  );
};

export const TailorField = ({ record, source }) => {
  const { tailor } = record;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Avatar
        src={thumbnail_url + tailor.thumbnail}
        style={{ marginBottom: 5, alignSelf: "center" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{tailor.nick_name}</Typography>
        <TailorDialog record={record} />
      </div>
    </div>
  );
};

export const TailorDialog = ({ record }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };
  return (
    <div>
      <Tooltip
        title={
          record.status_id === 5 ? "Edit Tailor" : "Item is not with Tailor"
        }
      >
        <span>
          <IconButton
            disabled={record.status_id !== 5} //status has to be not with_tailor
            onClick={(event) => {
              event.stopPropagation();
              setOpen(true);
            }}
            size="small"
          >
            <Icon
              path={mdiAccountEdit}
              size={1.5}
              color={record.status_id !== 5 ? grey[600] : indigo[500]}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Asign new Tailor</DialogTitle>
        <DialogContent>
          <TailorList record={record} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const StoreField = ({ record }) => {
  return record.store ? (
    <Tooltip title={record.store.name}>
      <Avatar
        alt={record.store.name}
        style={{
          backgroundColor: record.store.color,
          color: "white",
        }}
      >
        <Typography>{record.store.alias}</Typography>
      </Avatar>
    </Tooltip>
  ) : (
    "-"
  );
};

export const Status = ({ record }) => {
  const dataProvider = useDataProvider();
  const [options, setOptions] = React.useState();
  const [status, setStatus] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const { id, status_id } = record;

  useEffect(() => {
    dataProvider
      .getMany("item_statuses", {
        ids: [5, 6, 8, 11],
      })
      .then(({ data }) => {
        setOptions(data);
      });
  }, []);

  const refresh = useRefresh();
  const notify = useNotify();
  const [updateItem] = useUpdate("items");

  /*
  5: WITH TAILOR
  6: FIRST QC
  8: FITTING
  11: COMPLETED
  */

  const getChoices = (status_id, options) => {
    let aux = [...options];
    aux.find((x) => x.id === status_id).disabled = true;

    switch (status_id) {
      case 11:
        console.log("completed");
        aux.forEach((s) => {
          s.disabled = true;
        });
        break;
      case 5:
        console.log("TAILOR");
        aux.find((x) => x.id === 11).disabled = true;
        //aux.find((x) => x.id === 6).disabled = true;

        break;
      case 8:
        console.log("FITTING");

        aux.find((x) => x.id === 11).disabled = true;
        aux.find((x) => x.id === 6).disabled = true;

        break;
      case 6:
        console.log("QC");
        break;
      default:
        aux.forEach((s) => {
          s.disabled = true;
        });
        break;
    }

    return aux;
  };
  const StatusField = ({ record }) => {
    return <Typography>{record.status}</Typography>;
  };

  const changeStatus = () => {
    updateItem(
      {
        payload: {
          id,
          data: {
            status_id: status,
          },
        },
      },
      {
        onSuccess: () => {
          refresh();
          notify(
            "ra.notification.created",
            "info"
            //`Cut ${length}m of fabric ${props.record.fabric_code} from location ${location}`
          );
        },
      }
    );
  };
  const handleChangeStatus = () => {
    console.log(status, status_id);
    if (status === 5 && status_id === 8) {
      setOpen(true);
    }

    //Modal if goes from FITTING to TAILOR
  };

  return (
    <SimpleForm toolbar={<NulledToolbar />}>
      {
        <Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <SelectInput
              label="Move to:"
              choices={options && getChoices(status_id, options)}
              source="status_id"
              optionText={<StatusField />}
              onChange={(e) => setStatus(e.target.value)}
            />
            <Button
              type="button"
              action={null}
              onClick={() => handleChangeStatus()}
              disabled={!status}
            >
              <Typography>Move</Typography>
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              maxWidth={"sm"}
              fullWidth
              style={{ padding: 20 }}
            >
              <DialogTitle>Return to Tailor</DialogTitle>
              <DialogContent>
                <form>
                  <FormControl style={{ flex: 1, display: "flex" }}>
                    <TextField
                      id="standard-multiline-static"
                      multiline
                      rows={10}
                      //value={fabrics}
                      //onChange={(e) => setFabrics(e.target.value)}
                      fullWidth
                    />

                    <TextField
                      id="date"
                      label="Next Fitting"
                      type="date"
                      defaultValue={new Date()}
                      //className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </Fragment>
      }
    </SimpleForm>
  );
};

export const NulledToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate = null,
  permissions,
  ...props
}) => null;

export const HotelField = ({ record, source }) => {
  switch (source) {
    case "payment":
      record = record.order;
      break;
    default:
      break;
  }

  return record.store_id === 4
    ? "Online"
    : record.hotel_ids.map(
        (h) =>
          h.active &&
          !h.disabled && (
            <Tooltip key={h.id} title={h.notes ? h.notes : "Hotel"}>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography>{h.hotel ? h.hotel.name : "NO HOTEL"}</Typography>
                  {h.notes && <Typography color="error">*</Typography>}
                </div>
                {h.room && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                  >{`Room: ${h.room}`}</Typography>
                )}
              </div>
            </Tooltip>
          )
      );
};
