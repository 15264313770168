import React, { Fragment } from "react";
import {
  Show,
  TextField,
  TopToolbar,
  EditButton,
  ListButton,
  SimpleShowLayout,
  Labeled,
  ReferenceField,
  ArrayField,
  Datagrid,
  FunctionField,
} from "react-admin";
import { CustomDateField, FabricThumbnail } from "../../globals";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { bas_url } from "../../globals";
import { Typography } from "@material-ui/core";

const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CollectionShow = (props) => (
  <Show {...props} actions={<ShowActions />} component="div">
    <SimpleShowLayout>
      <TextField source="name" label="table_columns.name" />
      <TextField source="description" label="table_columns.description" />
      <Labeled label="table_columns.creation_date">
        <CustomDateField source="creation_date" formatedDate={true} />
      </Labeled>

      <ReferenceField
        reference="staffs"
        source="staff_id"
        label="table_columns.staff"
      >
        <TextField source="nick_name" />
      </ReferenceField>
      <Fragment>
        <Divider style={{ margin: 20 }} />
      </Fragment>

      <ArrayField source="fabric_ids" label="table_columns.fabric">
        <Datagrid>
          <TextField label="table_columns.id" source="fabric.id" />

          <FunctionField
            label="table_columns.fabric_code"
            render={(record) => {
              console.log(record);
              return (
                <Link
                  href={`${bas_url}/f/${record.fabric.unique_code}`}
                  color="primary"
                  target="_blank"
                >
                  <Typography>{record.fabric.unique_code}</Typography>
                </Link>
              );
            }}
          />
          <FunctionField
            label="table_columns.swatchbook"
            render={(record) => {
              console.log(record);
              return (
                <Link
                  href={`${bas_url}/s/${record.fabric.swatchbook.unique_code}`}
                  color="primary"
                  target="_blank"
                >
                  <Typography>
                    {record.fabric.swatchbook.unique_code}
                  </Typography>
                </Link>
              );
            }}
          />
          <FabricThumbnail
            label="table_columns.thumbnail"
            source="fabric.image"
          />
          <FunctionField
            label="resources.fabrics.fields.stock"
            render={(record) =>
              record.fabric && record.fabric.total_stock > 0
                ? record.fabric.total_stock + "[m]"
                : "No Stock"
            }
          />
          <TextField label="table_columns.order" source="order" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
export default CollectionShow;
