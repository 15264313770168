import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  FunctionField,
  //SimpleShowLayout,
  //RichTextField,
  TextField,

  // Labeled
} from "react-admin";
import {
  CustomerField,
  //OrderField,
  GarmentField,
  BookCodeField,
  ItemStatus,
  //ItemField,
  StaffField,
  TailorList,
  TailorField,
  Status,
} from "../components";
import { Typography } from "@material-ui/core";
import purple from "@material-ui/core/colors/purple";
import { ItemFilter } from "./ItemFilter";

import { CustomDateField, CustomPagination } from "../globals";

export const ItemList = ({ permissions, ...props }) => {
  /*const paymentRowStyle = (record, index) => ({
    // backgroundColor: record.balance === 0 ? "#e8f5e9" : "white"
  });*/
  return (
    <List
      {...props}
      filters={<ItemFilter />}
      sort={{ field: "creation_date", order: "DESC" }}
      bulkActionButtons={false}
      //actions={<ListActions />}
      //aside={<Aside />}
      //bulkActionButtons={<BulkActionButtons />}
      pagination={<CustomPagination />}
    >
      <Responsive
        //small={}
        medium={
          <Datagrid //rowStyle={paymentRowStyle} expand={<ExpandPanel />}
          >
            {/* <ItemField source="id" />
            <OrderField source="order_id" /> */}
            <TextField source="id" />
            <BookCodeField label="table_columns.book_code" source="item" />
            <CustomDateField
              source="creation_date"
              label="table_columns.creation_date"
              formatedDate={true}
              utc={false}
            />
            <StaffField label="table_columns.staff" source="order" />
            <GarmentField source="garment" />
            <FunctionField
              label="table_columns.customer"
              render={(record) => <CustomerField record={record.order} />}
            />

            {props.resource !== "items/live" && (
              <FunctionField
                label="table_columns.last_day"
                render={(record) =>
                  record.order.store_id !== 4 ? (
                    <CustomDateField
                      record={record.order}
                      source="last_day"
                      alert
                      format={"MMM DD,YY"}
                    />
                  ) : (
                    <Typography style={{ color: purple["600"] }}>
                      Online Order
                    </Typography>
                  )
                }
              />
            )}
            <FunctionField
              label="table_columns.fitting"
              render={(record) =>
                record.order.store_id !== 4 ? (
                  <CustomDateField
                    record={record.order}
                    source="fitting"
                    alert
                    formatedDate
                    utc={false}
                  />
                ) : (
                  <Typography style={{ color: purple["600"] }}>
                    Online Order
                  </Typography>
                )
              }
            />
            <CustomDateField
              source="allocation_date"
              utc={false}
              label="Allocation Date"
              formatedDate={true}
            />

            {props.resource === "items/live" && (
              <TailorField label="table_columns.tailor" />
            )}

            {props.resource === "items/unallocated" && (
              <TailorList label="table_columns.allocate" />
            )}

            {(props.resource === "items/all" ||
              props.resource === "items/live") && (
              <ItemStatus label="table_columns.status" />
            )}

            {props.resource === "items/live" && <Status label="Action" />}
          </Datagrid>
        }
      />
    </List>
  );
};

export default ItemList;
/*
const ExpandPanel = props => {
  return (
    <SimpleShowLayout {...props}>
      {props.record.notes ? (
        <RichTextField source="notes" />
      ) : (
        <Labeled label="Notes">
          <Typography>No Notes</Typography>
        </Labeled>
      )}
    </SimpleShowLayout>
  );
};
*/
/*
const BulkActionButtons = ({ resource, selectedIds }) => <Fragment></Fragment>;

const Aside = ({ data, ids, selectedIds }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Summary</Typography>
    <Typography variant="body2">
      {`Total Income: 
      $${selectedIds
        .map((id) => data[id])
        .reduce((sum, post) => sum + post.amount, 0)} `}
    </Typography>
  </div>
);
*/
