export default {
  menu: {
    dashboard: "Dashboard",
    fabrics: "Vải",
    stock: "Số lượng dự trữ",
    swatchbooks: "Tập mẫu vải",
    locations: "Vị trí",
    suppliers: "Nhà cung cấp",
    types: "Loại vải",
    colors: "Màu sắc",
    swatchbook_locations: " Vị trí - Tập mẫu vải",
    configuration: "Cấu hình",
    vietnamese: "Tiếng Việt",
    english: "Tiếng Anh",
    language: "ngôn ngữ",
    inventory: "Hàng tồn kho",
    sales: "Bán hàng",
    orders: "Đặt hàng",
    measurements: "Số đo",
    util: "Util",
    dictionary: "Dictionary",
    payment: {
      main: "Giá tiền",
    },
    cashier: {
      order_payments: "Order Payments",

      main: "Thu ngân",
      all_orders: "Tất cả các",
      unpaid_orders: "Chưa trả",
      final_payment: "Sau cùng",
      live_orders: "Trực tiếp",
      completed: "Đã hoàn thành",
      delivery: "Chuyển",
      to_deliver: "Giao hàng",
      delivery_complete: "Giao hàng hoàn tất",
      payments: "giá tiền",
    },
    cutter: {
      main: "Máy cắt",
      to_cut: "Cắt",
      completed: "Đã hoàn thành",
    },
    factory: {
      main: "Nhà máy",
      unallocated_orders: "Chưa phân bổ",
      unallocated: "Chưa có thợ may",
      tailor_status: "Tình trạng thợ may",
      tailor_scan: "quét thợ may",
      live_orders: "Đơn đặt hàng trực tiếp",
      qc: "QC",
      completed: "Đã hoàn thành",
      items: "Mục",
      live: "Live Items",
    },
    fitting: {
      main: "Lắp",
      fittings_today: "Phụ kiện hôm nay",
      upcoming_fittings: "Phụ kiện sắp tới",
      ongoing_fittings: "Phụ kiện đang thực hiện",
    },
    commission: {
      main: "Tiền hoa hồng",
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected",
    },
  },

  page_headers: {
    all_orders: "Tất cả các đơn đặt hàng",
    unpaid_orders: "Đơn hàng chưa thanh toán",
    final_payment: "Thanh toán cuối cùng",
    cashier_live_orders: "Đơn hàng thu ngân trực tiếp",
    order_complete: "Đơn hàng đã hoàn thành",
    to_deliver: "Giao hàng",
    delivery_complete: "Giao hàng đã hoàn thành",
    to_cut: "Cắt",
    cut_complete: "Cắt hoàn thành",
    unallocated_orders: "Đơn đặt hàng chưa được phân bổ",
    tailor_status: "Tình trạng thợ may",
    factory_live_orders: "Đơn đặt hàng trực tiếp tại nhà máy",
    factory_completed_orders: "Nhà máy đã hoàn thành đơn đặt hàng",
    qc: "QC",
    factory_complete: "",
    fittings_today: "Phụ kiện hôm nay",
    upcoming_fittings: "Phụ kiện sắp tới",
    ongoing_fittings: "Phụ kiện đang thực hiện",
  },

  table_columns: {
    instructions: "Instructions",
    descriotion: "Description",
    collection: "Collection",
    alias: "Tên hiệu",

    num_fabrics: "# Vải",

    item_price: "Item Price",
    swatchbook: "Tập mẫu vải",
    supplier_code: "Mã vải cũ",

    subheading: "Subheading",
    fabric: "Vải",

    search_customer: "Search Customer",
    men: "Nam",
    women: "Phụ nữ",
    index: "Index",
    base_preview: "Preview",
    preview: "Preview",
    gender: "Giới tính",
    garment_options: "Options",
    garment_option_values: "Option Values",

    garment_type: "Type",
    measurements: "Số đo",
    image: "hình ảnh",
    values: "values",
    option: "Option",
    options: "Options",

    vietnamese: "Tiếng Việt",
    english: "Tiếng Anh",

    name: "Name",
    active: "Kích hoạt",
    no_notes: "Không ghi chú",

    currency: "Tiền tệ",
    book_number: "Quyển bill",
    code: "mã số bill",
    book_code: "Quyển / Mã số bill",
    linked_order: "Chung Bill",
    id: "id",
    paid_with: "hình thức thanh toán",
    main_seller: "nhân viên số 1",
    payments: "giá tiền",
    order_summary: "thông tin",
    save: "lưu",
    save_and_pay: "lưu và trả tiền",
    discount: "giảm giá",
    commission: "tiền hoa hồng",
    seller: "nv bán",
    shipping: "gửi hàng",
    deposit: "đặt cọc",
    final_payment: "số tiền phải trả",
    cash: "tiền mặt",
    credit_card: "tiền thẻ",
    amount: "đã trả",
    amount_to_pay: "Số tiền",
    amount_usd: "Số tiền USD",

    method: "ht thanh toán",
    is_main: "Is Main?",
    hotel_name: "Hotel Name",
    address: "địa chỉ",
    phone: "điện thoại",
    payment_type: "Kiểu",
    cashier: "Thu ngân",
    order_info: "Order Info",
    email: "Email",
    first_name: "tên của khách",
    last_name: "họ của khách",
    origin: "làm sao khách biết bebe",
    hotel_room: "Số phòng",
    other: "Other",
    total_bill: "Toàn bộ",

    how_did_you_find_us: "khách nói thông tin từ đâu đến?",
    notes: "Ghi chú",
    date: "Ngày",
    staff: "Cán bộ",
    customer: "khách hàng",
    status: "Trạng thái",
    price: "Giá phải trả",
    balance: "số tiền còn lại",
    created: "Tạo",
    receipts: "Biên lai",
    order: "Gọi món",
    store: "Cửa hàng",
    pay_now: "Thanh toán ngay",
    balance_usd: "số tiền còn lại USD",
    receipt_reprint: "In lại biên lai",
    deadline: "Hạn chót",
    tailor_deadline: "Thời hạn may",
    items: "# Mặt hàng",
    courier: "Chuyển phát nhanh",
    hotel: "Khách sạn",
    confirm: "Xác nhận",
    print_slip: "In trượt",
    delivery_deadline: "Thời hạn giao hàng",
    undo_delivery: "Hoàn tác giao hàng",
    timestamp: "Dấu thời gian",
    order_number: "Số thứ tự",
    sales_staff: "Nhân viên Ban hang",
    item_number: "Số mặt hàng",
    description: "Sự miêu tả",
    fabric_code: "Mã vải",
    location: "Vị trí",
    cut_location: "Cắt từ",
    thumbnail: "Hình nhỏ",
    cut: "Cắt tỉa",
    extra: "Thêm",
    cut_uncut: "Cắt tỉa / không bị thương",
    undo_cut: "Hoàn tác cắt",
    item: "Mục",
    allocate: "Chỉ định",
    garment: "Quần áo",
    fitting: "Lắp",
    tailor: "Thợ may",
    specialty: "Đặc sản",
    today: "Hôm nay",
    week: "Tuần",
    now: "Hiện nay",
    latest_garment: "May mới nhất",
    last_day: "Ngày cuối",
    received: "Nhận",
    next_fit: "Phụ kiện tiếp theo",
    first_qc: "Đầu tiên QC",
    final_qc: "Sau cùng QC",
    schedule: "Lịch trình",
    alert: "Thông báo",
    arrived: "Đã đến",
    garment_ok: "Quần áo OK",
    creation_date: "Ngày thành lập",
    update_date: "Update Date",

    cutter: "Cutter",
    cut_date: "Cut Date",
  },

  overlay: {
    payment: {
      total: "Toàn bộ",
      deposit: "Tiền gửi",
      linked_order: "Thứ tự liên kết",
      remaining: "Còn lại",
      cancel: "Hủy bỏ",
      print_receipt: "Biên lai in",
      cashier_notes: "Ghi chú nhận",
      delivery_notes: "Phiếu giao hàng",
      amount: "Số tiền",
      notif_rel_orders: "Khách hàng cũng có đơn hàng liên quan",
      payments: "Giá tiền",
      deposit_paid: "Deposit Paid",
      paid_with: "Paid With",
      balance: "Balance",
      delivery_deadline: "Delivery Deadline",
      in_store_pick_up: "In Store Pick Up",
    },
    cutter: {
      verify_cuts: "Xác minh vết cắt",
      ppt: "Vui lòng chuyển qua",
      confirmed_by: "Được xác nhận bởi",
      confirm_cuts: "Xác nhận vết cắt",
      cancel: "Hủy bỏ",
      validation_error: "Ai xác nhận những cắt giảm này?",
    },
    factory: {
      unallocated: {
        assign_tailor_to_item: "Chỉ định thợ may cho mục",
        confirm_allocation: "Xác nhận phân bổ",
        item_number: "Mục #",
        allocated_to: "Phân bổ cho",
        tailor_deadline: "Thời hạn may",
        sapi: "Tương tự như mục trước",
        allocate: "Chỉ định",
      },
      items: "Mục",
    },
  },

  resources: {
    welcome: "Vui lòng sử dụng menu bên trái",
    measurements: {
      name: "Số đo",
      fields: {
        type_viet: "Tiếng Việt",
        type_eng: "Type English",
        alias: "Tên hiệu",
        minimum: "Minimum Value",
        maximum: "Maximum Value",
        step: "Step",
        order: "Order",
      },
    },
    staff: {
      fields: {
        staff_list: "Staff List",
        first_name: "First Name",
        last_name: "Last Name",
        type: "Staff Type",
        store: "Store",
        staff_image: "Staff Image",
        create_title: "Create new Staff",
        edit_title: "Edit Staff",
        show_title: "Staff Details",
        phone_number: "Phone Number",
        base_salary: "Base Salary",
        middle_name: "Middle Name",
        family_name: "Family Name",
        nick_name: "Nick Name",
        image: "Image",
        specialties: "Specialty",
        staff_image_thumbnail: "Thumbnail Staff Image",
        username: "username",
      },
    },
    fabrics: {
      name: "Danh sách vải",
      fields: {
        unique_code: "Mã",
        type: "Loại vải",
        supplier_code: "Mã vải cũ",
        location: "Vị trí",
        locations: "Vị trí",
        swatchbook: "Tập mẫu vải",
        total_stock: "Tổng số lượng dự trữ",
        total_extra_fabric: "Tổng số lượng vải được thêm",
        stock: "Số lượng dự trữ",
        extra_fabric: "Thêm vải",
        image: "hình ảnh",
        price: "giá",
        supplier: "Nhà cung cấp",
        color: "Màu sắc",
        color_depth: "độ đậm của màu",
        notes: "Ghi chú",
        creation_date: "Ngày thành lập",
        update_date: "Cập nhật ngày tháng",
        fabric: "Vải",
        quantity: "lượng số",
        extra: "Thêm vải",
        create_stock: "Tạo",
        create_swatchbook: "Tạo",
        create_swatchbook_location: "Thêm vị trí tập mẩu vải",
        stock_list: "Danh sách",
        fabric_list: "Danh sách Vải",
        location_list: "Danh sách Vị trí",
        swatchbook_list: "Danh sách Tập mẫu vải",
        supplier_list: "Danh sách Nhà cung cấp",
        type_list: "Danh sách Loại vải",
        color_list: "",
        swatchbook_location_list: "Danh sách list",
        stock_by_fabric_location: "Số lượng dự trữ vải và vị trí",
        description: "Mô tả",
        name: "Tên",
        alias: "Tên hiệu",
        num_fabrics: "# Vải",
        width: "Width",
      },
    },
    garment: {
      show_title: "Garment Option",
      name: "Option Name",
      order: "Option Order",
      option_values: "Option Values",
      option_value: "Option Value",
      option: "Option Value Name",
      image: "Image",
      description: "Description",
      option_description: "Option Description",
      show: "Garment Type",
      measurements: "Số đo",
      vietnamese: "Tiếng Việt",
      garment_name: "Garment Name",
      alias: "Tên hiệu",
      create_option: "Create Option",
      value_name: "Option Value Name",
      option_value_title: "Option Values",
      create_value: "Create Option Value",
      edit_value: "Edit Option Value",
    },
    garment_types: {
      list: "Garment Types List",
      name: "Tên",
      description: "Description",
      gender: "Gender",
      image: "Image",
      alias: "Tên hiệu",
      vietnamese: "Tiếng Việt",
    },
    garment_option: {
      list: "Garment Option List",

      name_value: "Garment Option Value List",
      name_option: "Garment Option List",
      fields: {
        name: "Tên",
        description: "Description",
        order: "Order",
        image: "Image",
        create_value: "Create Garment Option Value",
        edit_value: "Edit Garment Option Value",
        shot_value: "Garment Option Value",
        create_option: "Create Garment Option",
        edit_option: "Edit Garment Option",
        shot_option: "Garment Option",
        option: "Option",
        option_values: "Option Values",
        vietnamese: "Tiếng Việt",
      },
    },
    hotel: {
      id: "Id",
      hotel_list: "Hotels",
      name: "Name",
      contact: "Contact",
      address: "Address",
      phone: "Phone",
      url: "URL",
      commission: "Commission",
      email: "email",
      create_hotel: "Create a new Hotel",
      show_hotel: "Hotel",
    },
    garment_measurement: {
      garment: "Garment Type",
      type_viet: "Measurement Tiếng Việt",
      type_eng: "Measurement English",
      list: "Garment - Measurement Relationship",
      create: "Create a garment type - measurement relationship",
      show: "Garment - Measurement",
      measurement: "Số đo",
      edit: "Edit a garment type - measurement relationship",
      order: "Order",
    },
    tailor_specialties: {
      list: "Tailor - Specialty list",
      name: "Name",
      nick_name: "Nick Name",
      garment: "Garment Specialty",
      edit: "Edit",
      show: "Show",
      creation_date: "Creation Date",
      create: "Create",
    },
    dictionary: {
      id: "id",
      key: "key",
      english: "english",
      vietnamese: "Tiếng Việt",
      dictionary_list: "Words",
    },
    swatchbooks: {
      fabric_type: "Loại vải",
    },
  },
  grid: {
    code: "Mã",
  },
};
