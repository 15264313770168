import React, { Fragment } from "react";
import {
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SaveButton,
  Toolbar,
  email,
  maxLength,
  Edit,
  useNotify,
  useRedirect,
  useUpdate,
  Labeled,
  FormDataConsumer,
  BooleanInput,
  FunctionField,
  useCreate,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  useDelete,
} from "react-admin";
import _ from "lodash";

import RichTextInput from "ra-input-rich-text";

import { useFormState } from "react-final-form";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const OrderEdit = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;

  const validateEmail = [required(), email()];
  const translate = useTranslate();

  return (
    <Edit {...props} title="Edit">
      <SimpleForm toolbar={<UserEditToolbar {...props} />}>
        <FunctionField
          addLabel={false}
          render={(record) =>
            record.disabled && (
              <Typography style={{ color: "#e53935" }} variant="h5">
                {`HUY BILL ${record.reason && `(${record.reason})`}`}
              </Typography>
            )
          }
        />
        <Fragment>
          <Grid direction="column" item container xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.order_info")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item md>
                <TextInput
                  source="book"
                  label="table_columns.book_number"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextInput
                  source="code"
                  label="table_columns.code"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <NumberInput
                  fullWidth
                  source="total_price"
                  label="table_columns.price"
                  helperText="USD"
                  validate={required()}
                  disabled
                />
              </Grid>
              <Grid item md>
                <NumberInput
                  fullWidth
                  source="discount"
                  label="table_columns.discount"
                  helperText="USD"
                  disabled
                />
              </Grid>
              <Grid item md>
                <ReferenceInput
                  label="table_columns.store"
                  source="store_id"
                  reference="stores"
                  fullWidth
                >
                  <SelectInput
                    optionText="name"
                    helperText="table_columns.store"
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
        <Fragment>
          <Grid container direction="column" spacing={1}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.staff")}
            </Typography>
            <ArrayInput source="staff_ids">
              <SimpleFormIterator>
                <ReferenceInput
                  source="staff_id"
                  label="table_columns.staff"
                  reference="sellers"
                  perPage={100000}
                  validate={required()}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <AutocompleteInput
                    helperText="Staff"
                    optionText={(record) =>
                      `${record && record.name} - ${
                        record && record.staff_type
                      }`
                    }
                  />
                </ReferenceInput>
                <NumberInput
                  source="amount"
                  label="table_columns.amount"
                  helperText="USD"
                  validate={required()}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.linked_order")}
            </Typography>
            <ArrayInput source="link_ids">
              <SimpleFormIterator>
                <ReferenceInput
                  label="table_columns.linked_order"
                  source="linked_order_id"
                  reference="order_filters"
                  perPage={200}
                  validate={required()}
                  sort={{ field: "creation_date", order: "DESC" }}
                >
                  <AutocompleteInput
                    optionText={(record) =>
                      record &&
                      `${record.book} / ${_.padStart(record.code, 4, "0")}`
                    }
                  />
                </ReferenceInput>
                {/* <BooleanInput
                  source="active"
                  label="table_columns.active"
                  defaultValue={true}
               /> */}
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid direction="column" item container xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.customer")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item md>
                <TextInput
                  source="customer_email"
                  label="table_columns.email"
                  helperText="E-mail"
                  validate={validateEmail}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextInput
                  source="customer_first_name"
                  label="table_columns.first_name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextInput
                  source="customer_last_name"
                  label="table_columns.last_name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item container direction="column" xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.hotel")}
            </Typography>

            <Grid container>
              <ArrayInput source="hotels" label={" "}>
                <SimpleFormIterator>
                  <ReferenceInput
                    label="table_columns.hotel"
                    source="hotel_id"
                    reference="hotels"
                    perPage={100000}
                    validate={required()}
                    sort={{ field: "name", order: "ASC" }}
                    fullWidth
                  >
                    <AutocompleteInput
                      helperText="Search"
                      optionText={(record) =>
                        record && `${record.name} - ${record.address}`
                      }
                    />
                  </ReferenceInput>
                  <TextInput source="room" label="table_columns.hotel_room" />
                  <BooleanInput
                    source="active"
                    label="table_columns.active"
                    defaultValue={true}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item container direction="column" xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.how_did_you_find_us")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <ArrayInput source="origin_ids">
                <SimpleFormIterator>
                  <ReferenceInput
                    source="origin_id"
                    reference="origins"
                    label="table_columns.origin"
                  >
                    <SelectInput
                      helperText="table_columns.origin"
                      optionText={(record) => record.source}
                    />
                  </ReferenceInput>
                  <TextInput
                    source="description"
                    label="table_columns.description"
                  />
                  {/* <BooleanInput
                        defaultValue={true}
                        source="active"
                        label="table_columns.active"
                  /> */}
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item container spacing={1} direction="column" xs={12} md={8}>
            <Grid item>
              <Typography variant="h6">
                {translate("table_columns.notes")}
              </Typography>
            </Grid>
            <Grid item>
              <RichTextInput
                multiline
                fullWidth
                source="notes"
                label="table_columns.notes"
                validate={maxLength(999)}
              />
            </Grid>
          </Grid>
        </Fragment>

        <Labeled label="Huy Bill">
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <Fragment>
                  <BooleanInput
                    {...rest}
                    source="disabled"
                    label=""
                    defaultValue={formData.disabled}
                    //disabled
                  />
                  {formData.disabled && (
                    <TextInput
                      {...rest}
                      validate={required()}
                      source="reason"
                      fullWidth
                    />
                  )}
                </Fragment>
              );
            }}
          </FormDataConsumer>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
const UserEditToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  permissions,
  ...props
}) => (
  <Toolbar {...props}>
    <EditCustomButton label="ra.action.edit" redirect="show" />
  </Toolbar>
);

const EditCustomButton = ({ record, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;
  const [update] = useUpdate("orders");
  const [createDisable] = useCreate("order_disableds");
  const [deleteDisable] = useDelete("order_disableds");

  const [createHotel] = useCreate("order_hotels");
  const [updateHotel] = useUpdate("order_hotels");
  //const [deleteHotel] = useDelete("order_hotels");

  const [createStaff] = useCreate("order_sellers");
  const [updateStaff] = useUpdate("order_sellers");
  const [deleteStaff] = useDelete("order_sellers");

  const [createLink] = useCreate("order_links");
  const [updateLink] = useUpdate("order_links");
  const [deleteLink] = useDelete("order_links");

  const [createOrigin] = useCreate("order_origins");
  const [updateOrigin] = useUpdate("order_origins");
  const [deleteOrigin] = useDelete("order_origins");

  //const [createOrigin] = useCreate("order_origins");
  //const [createSellers] = useCreate("order_sellers");
  //const [updateSellers] = useUpdate("order_sellers");

  // const dataProvider = useDataProvider();

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    let hotels = formState.values.hotels ? formState.values.hotels : [];
    let staffs = formState.values.staff_ids ? formState.values.staff_ids : [];
    let links = formState.values.link ? formState.values.link : [];
    let disabled = formState.values.disabled;
    let reason = disabled ? formState.values.reason : null;
    let disableds = formState.values.disable_ids
      ? formState.values.disable_ids
      : [];
    let origins = formState.values.origin_ids
      ? formState.values.origin_ids
      : [];

    update(
      {
        payload: {
          id: formState.values.id,
          data: {
            ...formState.values,
            reason,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          if (disabled) {
            createDisable({
              payload: {
                data: {
                  order_id: newRecord.id,
                  reason,
                  staff_id: Number(localStorage.getItem("staff_id")),
                },
              },
            });
          } else {
            disableds.map((d) =>
              deleteDisable({
                payload: { id: d.id },
              })
            );
          }
          record.staff_ids.forEach((s) => {
            if (!_.find(staffs, (ss) => ss.id === s.id)) {
              deleteStaff({
                payload: { id: s.id },
              });
            }
          });

          if (staffs.length > 0) {
            staffs.forEach((s) => {
              s.id &&
                updateStaff({
                  payload: {
                    id: s.id,
                    data: {
                      staff_id: s.staff_id,
                      amount: s.amount,
                    },
                  },
                });
              !s.id &&
                createStaff({
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      staff_id: s.staff_id,
                      amount: s.amount,
                    },
                  },
                });
            });
          }

          if (hotels.length > 0) {
            hotels.forEach((h) => {
              h.id &&
                updateHotel({
                  payload: {
                    id: h.id,
                    data: {
                      hotel_id: h.hotel_id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                      room: h.room,
                      active: h.active,
                    },
                  },
                });
              !h.id &&
                createHotel({
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      hotel_id: h.hotel_id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                      room: h.room,
                      active: h.active,
                    },
                  },
                });
            });
          }

          record.link_ids.forEach((l) => {
            if (!_.find(links, (ol) => ol.id === l.id)) {
              deleteLink({
                payload: { id: l.id },
              });
            }
          });

          if (links.length > 0) {
            links.forEach((l) => {
              if (l.id) {
                updateLink({
                  payload: {
                    id: l.id,
                    data: {
                      linked_order_id: l.linked_order_id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                      active: l.active,
                    },
                  },
                });
              } else {
                createLink({
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      linked_order_id: l.linked_order_id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                      active: l.active,
                    },
                  },
                });

                createLink({
                  payload: {
                    data: {
                      linked_order_id: newRecord.id,
                      order_id: l.linked_order_id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                      active: l.active,
                    },
                  },
                });
              }
            });
          }

          if (links.length > 0) {
            links.forEach((l) =>
              links.forEach(
                (m) =>
                  l !== m &&
                  createLink({
                    payload: {
                      data: {
                        linked_order_id: l.linked_order_id,
                        order_id: m.linked_order_id,
                        staff_id: Number(localStorage.getItem("staff_id")),
                        active: l.active,
                      },
                    },
                  })
              )
            );
          }

          record.origin_ids.forEach((o) => {
            if (!_.find(origins, (oo) => oo.id === o.id)) {
              deleteOrigin({
                payload: { id: o.id },
              });
            }
          });

          origins.forEach((o) => {
            if (o.id) {
              updateOrigin({
                payload: {
                  id: o.id,
                  data: {
                    order_id: newRecord.id,
                    origin_id: o.origin_id,
                    staff_id: Number(localStorage.getItem("staff_id")),
                    description: o.description,
                    active: o.active,
                  },
                },
              });
            } else {
              createOrigin({
                payload: {
                  data: {
                    order_id: newRecord.id,
                    staff_id: Number(localStorage.getItem("staff_id")),
                    origin_id: o.origin_id,
                    description: o.description,
                    active: o.active,
                  },
                },
              });
            }
          });

          notify("ra.notification.updated", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  /*, [
    formState.valid,
    formState.values,
    create,
    notify,
    redirectTo,
    redirect,
    basePath
  ]*/
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};
//export default withTranslate(OrderEdit);

export default OrderEdit;
