// in src/garments.js
import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  useTranslate,
  Toolbar,
  useCreate,
  useRedirect,
  useNotify,
  useDelete,
  SaveButton,
  useUpdate,
  AutocompleteInput,
} from "react-admin";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import _ from "lodash";

import { useFormState } from "react-final-form";

const GarmentOptionCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create {...props} title="Create New Garment Option">
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <TextInput source="name" label="resources.garment.name" />
        <TextInput source="description" label="resources.garment.description" />
        <TextInput source="vietnamese" label="resources.garment.vietnamese" />

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.values").toUpperCase()}
          </Typography>
        </Fragment>
        <ArrayInput source="value_ids" label="">
          <SimpleFormIterator>
            <ReferenceInput
              source="garment_option_value_id"
              reference="garment_option_values"
              label="Value"
              sort={{ field: "description", order: "ASC" }}
              perPage={-1}
            >
              <AutocompleteInput optionText="description" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const UserCreateToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveCustomButton label="ra.action.save" redirect="list" />
  </Toolbar>
);

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;

  const [create] = useCreate("garment_options");
  const [createValue] = useCreate("garment_option_option_values");
  const [deleteOption] = useDelete("garment_option_option_values");
  const [updateOption] = useUpdate("garment_option_option_values");

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }

    let values = formState.values.value_ids ? formState.values.value_ids : [];

    create(
      {
        payload: {
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.value_ids &&
            record.value_ids.forEach((o) => {
              if (!_.find(values, (oo) => oo.id === o.id)) {
                deleteOption({
                  payload: { id: o.id },
                });
              }
            });

          values.forEach((o) => {
            if (o.id) {
              updateOption({
                payload: {
                  id: o.id,
                  data: {
                    garment_option_id: newRecord.id,
                    garment_option_value_id: o.garment_option_value_id,
                  },
                },
              });
            } else {
              createValue({
                payload: {
                  data: {
                    garment_option_id: newRecord.id,
                    garment_option_value_id: o.garment_option_value_id,
                  },
                },
              });
            }
          });
          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default GarmentOptionCreate;
