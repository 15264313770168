import React from "react";
//import DateTimePicker from "react-datetime-picker";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import _ from "lodash";

import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import { Link } from "react-router-dom";

import swal from "sweetalert2";

import moment from "moment-timezone";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Logo from "./assets/logo-receipt.png";
import NumberFormat from "react-number-format";
import { CircularProgress } from "@material-ui/core";
import MonetizationOnSharpIcon from "@material-ui/icons/MonetizationOnSharp";
import { Button, Pagination } from "react-admin";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
const store = {
  curOrder: { altDepositPaid: "" },
  curIFUrefID: 0,
  curIFUcallback: function () {},
  order: {},
  usd_paid: 0,
  usd_rem: 0,
  alt_paid: 0,
  alt_rem: 0,
  balance: 0,
  currency: "usd",
  forex: {},
  /*
  forex: {
    vnd: 22727.2727,
    aud: 1.33493526,
    gbp: 0.754651483,
    euro: 0.867144752,
    yen: 108.589423
  },
  */
  courriers: [],
  sales_staff: [],
  hotels: [],
  fp_selected_hotel: {
    id: 0,
    name: "",
    address: "",
    room_number: 0,
    pickup: false,
  },
  fp_notes: "",
  liveCustomers: [],
};
export default store;

export const api_uri = "http://api.bebetailor.com/api";
export const bas_url = "http://bas.bebetailor.com";

//export const bas_url = "http://192.168.1.200:3001";
//export const api_uri = "http://192.168.1.200:5000/api";
export const bucket_url = "http://api.bebetailor.com/";

export const garment_bucket =
  api_uri + "/containers/bebe-garment-options/upload";

export const location_bucket = api_uri + "/containers/bebe-locations/upload";

export const measurement_bucket =
  api_uri + "/containers/bebe-measurements/upload";

export const garment_url =
  "https://s3-ap-southeast-1.amazonaws.com/bebe-garment-options/";

export const thumbnail_url =
  "https://s3-ap-southeast-1.amazonaws.com/bebe-staff-thumbnails/";

export const fabric_thumbnail =
  api_uri + "/containers/bebe-fabrics-thumbnails/download/";
export const fabric_image = api_uri + "/containers/bebe-fabrics/download/";

export const location_thumbnail =
  "https://bebe-locations.s3-ap-southeast-1.amazonaws.com/";

//export const print_server_IP = "http://localhost"
export const print_server_IP = "http://192.168.1.110";

// export const print_server_IP = (localStorage.getItem("store_id") > 1) ? "http://localhost" : "http://192.168.1.100";

export const formatDecimals = (figure) => {
  return figure && figure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertViToEn = (str) => {
  if (str != null) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    //str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    str = str.replace(/ + /g, " ");
    str = str.trim();
  } else str = "";
  return str;
};
/*
export const formatNumber = x => {
  if (typeof x === "string") x = parseFloat(x);

  // if decimal, separate
  if (!x) x = 0;
  var dec = "00";
  var val = x;
  if (x.toString().indexOf(".") > 0) {
    x = x.toFixed(2);
    dec = x.split(".")[1];
    val = x.split(".")[0];
  }

  return (
    val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (dec === "00" ? "" : "." + dec)
  );
};
*/
export const dateFormat = "MMM DD,YY hh:mm A";
export const dateFormatviet = "DD/MM/YY hh:mm A";
export const timeFormat = "hh:mm A";

export const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />
);

export const PayButton = ({ record, disabled }) => {
  return (
    <Tooltip title="Pay" link="none">
      <IconButton
        type="button"
        component={Link}
        action={null}
        to={
          record.type_id
            ? `/payments/create?order_id=${record.id}&type_id=${record.type_id}`
            : `/payments/create?order_id=${record.id}`
        }
        onClick={(event) => event.stopPropagation()}
      >
        <MonetizationOnSharpIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};
export const AddItemButton = ({ record, disabled }) => {
  return (
    <Tooltip title="Add Item" link="none">
      <IconButton
        type="button"
        component={Link}
        action={null}
        to={`/items/create?order_id=${record.id}`}
        onClick={(event) => event.stopPropagation()}
      >
        <AddCircleOutlineIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};

export const ShowGarments = ({ record = {} }) => {
  return (
    <Button
      color="primary"
      onClick={() => {
        fetchGarments(record.order_id ? record.order_id : record.id);
      }}
    >
      <Typography
        style={
          record.delivery_status === "items_pending_qc" ||
          record.done_items !== record.items
            ? { color: "red", fontWeight: "bold" }
            : null
        }
      >
        {record.items}
      </Typography>
    </Button>
  );
};

export function CustomDateField({
  source,
  record = {},
  utc = true,
  format,
  alert,
  formatedDate,
}) {
  const [flag, toggleFlag] = React.useState(!formatedDate);
  let date = utc
    ? moment(moment(record[source]).utc().format(dateFormat), dateFormat)
    : moment(moment(record[source]).format(dateFormat), dateFormat);
  let now = moment(moment().format(dateFormat), dateFormat);

  return record[source] ? (
    <Typography
      onClick={(event) => {
        event.stopPropagation();
        toggleFlag(!flag);
      }}
      style={alert && now > date ? { color: "red" } : {}}
    >
      {flag ? date.from(now) : date.format(format ? format : dateFormat)}
    </Typography>
  ) : (
    <Typography>Not set</Typography>
  );
}

export const StaffLink = ({ source, record = {} }) => {
  return (
    <Tooltip title={record["name"] ? record["name"] : "staff"}>
      <Avatar alt={record["name"]} src={record["staff_thumbnail"]} />
    </Tooltip>
  );
};

export const StoreLink = ({ source, record = {} }) => {
  return (
    <Tooltip title={record["store"] ? record["store"] : ""}>
      <Avatar
        alt={record["store"]}
        style={{ backgroundColor: record["store_color"], color: "white" }}
      >
        {record["store_alias"]}
      </Avatar>
    </Tooltip>
  );
};

export const Store = ({ source, record = {} }) => {
  return (
    <Tooltip title={record.stores ? record.stores.name : "Bebe"}>
      <Avatar
        alt={record["store"]}
        style={{
          backgroundColor: record.stores && record.stores.color,
          color: "white",
        }}
      >
        {record.stores && record.stores.alias}
      </Avatar>
    </Tooltip>
  );
};

export const Staff = ({ source, record = {} }) => {
  return (
    <div
      style={{ display: "flex" }}
      onClick={(event) => event.stopPropagation()}
    >
      {record[source]
        ? _.sortBy(record[source], (x) => x.is_main)
            .reverse()
            .map(
              (s) =>
                s.staff && (
                  <Tooltip title={s.staff.name} key={s.id}>
                    <Avatar
                      component={Link}
                      to={`/staffs/${s.staff.id}/show`}
                      alt={s.staff.name}
                      src={s.staff.image}
                      style={{ marginRight: 5 }}
                    />
                  </Tooltip>
                )
            )
        : "NO STAFF"}
    </div>
  );
};

export const Customer = ({ source, record = {} }) => {
  return (
    <div style={{ display: "flex" }}>
      <Typography>{`${record.customer_last_name}, ${record.customer_first_name}`}</Typography>
    </div>
  );
};

export const FabricThumbnail = ({ source, record }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return record.fabric ? (
    <div>
      <Avatar
        src={fabric_thumbnail + record.fabric.image}
        onClick={handleClickOpen}
        style={{ width: 50, height: 50, borderRadius: 5 }}
      />
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <img
            src={fabric_image + record.fabric.image}
            alt={record.unique_code}
            onClick={handleClose}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

export const TailorLoad = ({ source, record = {} }) => (
  <Typography style={record[source] < 1 ? { color: "red" } : null}>
    {record[source]}
  </Typography>
);

export const StatusText = ({ source, record = {} }) => (
  <Typography
    style={record[source] === "unallocated" ? { color: "red" } : null}
  >
    {record[source]}
  </Typography>
);

export const TailorLink = ({ source, record = {} }) =>
  record["tailor_id"] && (
    <Tooltip title={record[source]}>
      <Link to={{ pathname: "/staffs/" + record["tailor_id"] + "/show" }}>
        <Avatar alt={record["source"]} src={record["tailor_thumbnail"]} />
      </Link>
    </Tooltip>
  );

export const CutterLink = ({ source, record = {} }) => (
  <Tooltip title={record[source]}>
    <Link to={{ pathname: "/staffs/" + record["cutter_id"] + "/show" }}>
      <Avatar alt={record["source"]} src={record["cutter_thumbnail"]} />
    </Link>
  </Tooltip>
);

export const ItemLink = ({ source, record = {} }) => (
  <Button
    color="primary"
    target="_blank"
    href={bas_url + "/i/" + record["item"]}
  >
    <Typography>{record["item"]}</Typography>
  </Button>
);

export const OrderLink = ({ source, record = {} }) => (
  <Button
    color="primary"
    target="_blank"
    href={bas_url + "/o/" + record[source]}
  >
    <Typography>{record[source]}</Typography>
  </Button>
);

export const FabricLink = ({ source, record = {} }) => (
  <Button
    color="primary"
    target="_blank"
    href={bas_url + "/f/" + record.fabric_code}
  >
    <div>
      <Typography gutterBottom>{record["fabric_code"]}</Typography>
      <Typography variant="caption">{`(${record["old_code"]})`}</Typography>
    </div>
  </Button>
);

export const PriceSpan = ({ source, record = {} }) => (
  <Typography style={{ display: "flex", justifyContent: "flex-end" }}>
    ${formatDecimals(record[source])}
  </Typography>
);

export const StatusSpan = ({ source, record = {} }) => {
  switch (record[source]) {
    case "unpaid":
      return <Typography style={{ color: "red" }}>{record[source]}</Typography>;
    case "completed":
      return (
        <Typography style={{ color: "green" }}>{record[source]}</Typography>
      );
    default:
      return <Typography>{record[source]}</Typography>;
  }
};

export const styles = {
  container: {
    display: "flex",
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "10px",
  },
  column2: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    padding: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  item: {
    marginRight: "1rem",
  },
  border: {
    borderColor: "black",
    borderWidth: "1px",
    borderStyle: "solid",
    flex: 1,
    justifyContent: "center",

    width: "100%",
  },
  img: {
    overflow: "hidden",
    width: "300px",
    height: "300px",
    flex: 1,
  },
  thumbnail: {
    width: "150px",
    height: "150px",
    overflow: "hidden",
  },
  span: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  basactionicons: {
    fontSize: 25,
    cursor: "pointer",
    marginLeft: "10px",
    hover: {
      color: "#467FD7",
    },
  },
  modalclose: {
    fontSize: 20,
    cursor: "pointer",
    float: "right",
  },
  lightbox: {
    display: "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 555,
    bg: {
      background: "black",
      width: "100%",
      height: "100%",
      opacity: "0.3",
      position: "absolute",
      top: 0,
      left: 0,
    },
    body: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      content: {
        margin: "auto",
        marginTop: "80px",
        width: "80%",
        background: "white",
        padding: "30px",
        borderRadius: "15px",
        receipt: {
          width: "100mm",
          border: "1px solid #e0e0e0",
          background: "white",
          padding: "30px",
          borderRadius: "15px",
          margin: "auto",
        },
      },
      smallcontent: {
        margin: "auto",
        marginTop: "180px",
        width: "40%",
        background: "white",
        padding: "20px",
        borderRadius: "15px",
      },
    },
  },
};

export const refreshRecord = () => {
  console.error("reresh");

  var aTags = document.getElementsByTagName("button");
  var searchText = "Refresh";
  var found;

  for (var i = 0; i < aTags.length; i++) {
    if (aTags[i].title === searchText) {
      found = aTags[i];
      break;
    }
  }

  if (found) found.click();
};

export const searchElement = (el, text) => {
  var aTags = document.querySelectorAll(el);
  var searchText = text;
  var found = [];

  for (var i = 0; i < aTags.length; i++) {
    if (typeof searchText === "string") {
      if (aTags[i].textContent.toLowerCase() === searchText.toLowerCase()) {
        found.push(aTags[i]);
      }
    } else {
      if (searchText.indexOf(aTags[i].textContent.toLowerCase()) > -1) {
        found.push(aTags[i]);
      }
    }
  }

  return found;
};

export const fetchHotel = (id) => {
  for (var i = 0; i < store.hotels.length; i++) {
    if (Number(id) === Number(store.hotels[i].value)) {
      return store.hotels[i];
    }
  }
  return null;
};

export const fetchCourier = (id) => {
  for (var i = 0; i < store.courriers.length; i++) {
    if (Number(id) === Number(store.courriers[i].id)) {
      return store.courriers[i];
    }
  }
  return null;
};

export const initializeForex = () => {
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      var tmp = JSON.parse(this.responseText);
      for (var i = 0; i < tmp.length; i++) {
        var ci = tmp[i].currency;
        if (ci === "EUR") ci = "eur";
        if (ci === "JPY") ci = "jpy";
        store.forex[ci] = tmp[i].rate;
      }
    }
  };
  xhttp.open("GET", api_uri + "/exchange_rates", true);
  xhttp.send();
};

export const clearClearables = () => {
  var clearEls = document.querySelectorAll("html .clearable");
  for (var i = 0; i < clearEls.length; i++) {
    if (clearEls[i].tagName === "SELECT") {
      clearEls[i].querySelector("option").selected = "selected";
    } else {
      clearEls[i].innerHTML = "";
      clearEls[i].value = "";
    }
  }
};
/*
export class DTPicker extends React.Component {
  state = {
    date: new Date(),
    target: null
  };

  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }

  handleLoad() {
    this.onChange(new Date());
  }

  onChange = date => {
    this.setState({ date });
    var e = this.state.target;
    // var e = document.getElementById("dtp-time");
    try {
      //var time = e.options[e.selectedIndex].value;
    } catch (err) {
      //var time = "12:00 PM";
    }
    var inptDate = new Date(date);
    inptDate =
      inptDate.getMonth() +
      1 +
      "/" +
      inptDate.getDate() +
      "/" +
      inptDate.getFullYear() +
      " " +
      time;
    //var parsedDate = new Date(inptDate);
    //this.props.callback(parsedDate);
  };
  
  onTimeChange = e => {
    this.state.target = e.target;
    this.setState(this.state);
    this.onChange(this.state.date);
  };
  

  setTarget = e => {
    //this.state.target = e.target.closest(".full-width").querySelector(".dtp-time");
    this.setState(this.state);
    this.onChange(this.state.date);
  };

  render() {
    return (
      <DateTimePicker
        onChange={this.onChange.bind(this)}
        value={this.state.date}
        minDate={new Date()}
        clearIcon={null}
        required={true}
        disableClock={true}
      />
    );
  }
}
*/
export const ReceiptPrint = (type, order_id) => {
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      //console.dir(this.responseText);
      var phRes = JSON.parse(this.responseText);
      phRes["delivery_deadline"] = store.curOrder.delivery_deadline;
      phRes["delivery_type"] = store.curOrder.delivery_type;
      phRes["store_id"] = store.curOrder.store_id;
      reprintReceipt(type, phRes);
    }
  };
  xhttp.open("POST", api_uri + "/payments/history", true);
  xhttp.setRequestHeader("Content-Type", "application/json");
  var payload = {
    order_id: order_id,
  };
  console.dir(payload);
  xhttp.send(JSON.stringify(payload));
};

const reprintReceipt = (type, record) => {
  var receipt_types = [0, "deposit", "final"];
  var receipt_type = receipt_types[type];

  var payment = 0;
  for (var i = 0; i < record.payments.length; i++) {
    if (record.payments[i].type === receipt_type) {
      payment = record.payments[i];
    }
  }

  if (payment === 0) {
    swal({
      title: "Error",
      text: "No payment records found",
      type: "error",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Confirm",
    }).then((result) => {});
  } else {
    print(receipt_type, record, payment);
  }
};
/*
export const toUnixTimestamp = date => {
  if (typeof date !== "object") date = new Date(date);
  var formatted_date =
    date.getFullYear() +
    "-" +
    (date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
    "-" +
    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
    " " +
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
    ":" +
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":00";
  return formatted_date;
};
*/
/*
const print = (type, order, payment) => {
  if (payment.delivery) {
    if (payment.delivery.indexOf("T") > 0) {
      payment.delivery = payment.delivery.replace("T", " ").slice(0, -8);
      payment.delivery = new Date(payment.delivery).toLocaleString();
    }
  }

  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
      //console.dir(this.responseText);
    }
  };
  if (type === "final_payment") order.fitting_time = false;
  xhttp.open("POST", print_server_IP+"/printer/", true);
  order.fitting_time = new Date(order.fitting_time).toLocaleString();

  var payload = {
  date: moment().format('DD/MM/YYYY, h:mm:ss a'),//order.timestamp,
  total: order.total_price,
  usdDepositPaid: type === "deposit" ? payment.amount : 0,
  altDepositPaid: payment.alt_amount,
  currency: payment.currency.toUpperCase(),
  usdRem: order.balance,
  altRem: 0,
  receipt_type: type === "final" ? "final_payment" : type,
  order_number: order.id,
  summary: order,
  discount: order.discount,
  payment: payment,
  includingFee: payment.fee,
  store_id: order.store_id,
  store:order.store,
  fitting: order.fitting_time,
  store_address: order.store_address,
  store_phone: order.store_phone
};

  console.dir(payload);
  xhttp.send(JSON.stringify(payload));
  printLoad();
};

const printLoad = () => {
  let timerInterval;
  swal({
    title: "Printing...",
    timer: 2000,
    onOpen: () => {
      swal.showLoading();
      timerInterval = setInterval(() => {}, 100);
    },
    onClose: () => {
      clearInterval(timerInterval);
    }
  }).then(result => {
    if (
      result.dismiss === swal.DismissReason.timer
    ) {
    }
  });
};
*/
export const pushIFU = (
  entity_id,
  entity,
  amount,
  operand,
  currency,
  notes,
  screen
) => {
  /*var fetchTimer = swal({
    title: "Validating " + entity + " ID...",
    timer: 10000,
    onOpen: () => {
      swal.showLoading();
      // this.state.total - this.state.curOrder.usdRem;
    },
    onClose: () => {}
  }).then(result => {
    if (
      // Read more about handling dismissals
      result.dismiss === swal.DismissReason.timer
    ) {
    }
  });
*/
  var id_validation = new XMLHttpRequest();
  id_validation.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      try {
        document.querySelector(".swal2-container").click();
      } catch (err) {}
      var idv_response = JSON.parse(this.responseText);
      if (idv_response.data.length <= 0) {
        swal({
          title: entity + " ID not found",
          type: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Confirm",
        }).then((result) => {});
        return;
      }
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          //console.dir(this.responseText);
          store.curIFUcallback();
          store.curIFUcallback = function () {};

          swal({
            title: "IFU for " + entity + " " + entity_id + " sent",
            type: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Confirm",
          }).then((result) => {});
        }
      };

      xhttp.open("POST", api_uri + "/ifus", true);
      xhttp.setRequestHeader("Content-Type", "application/json");
      var payload = {
        entity_id: entity_id,
        entity: entity,
        amount: amount,
        currency: currency,
        operand: operand,
        notes: notes,
        screen: screen,
        user_id: localStorage.getItem("user_id"),
      };

      //console.dir(payload);
      xhttp.send(JSON.stringify(payload));
    }
  };

  var ep = "orders";
  if (entity === "item") ep = "items";
  id_validation.open(
    "GET",
    api_uri + "/" + ep + "/validateID/" + entity_id,
    true
  );
  id_validation.send();
};

var flash_interval = null;
export const relOrderFlash = () => {
  if (flash_interval) {
    return;
  }
  var isRed = false;
  flash_interval = setInterval(function () {
    if (window.location.href.indexOf("final_payment") < 1) {
      clearInterval(flash_interval);
    } else {
      var strip = document.getElementById("p-rel-orders");
      if (isRed) strip.classList.remove("bg-danger");
      else strip.classList.add("bg-danger");
      isRed = !isRed;
    }
  }, 600);
};

export class GlobalIFU extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      special_fields: "",
      entity: "cashier",
      classes: "",
    };

    if (!props.classes) {
      this.state.classes = "";
    } else {
      this.state.classes = props.classes;
    }

    if (window.location.href.indexOf("/cutter/") > 0) {
      this.state.entity = "item";
      // this.state.special_fields =
      //   "<div>" +
      //   '    <center style="margin: auto" class="half">' +
      //   '       <div class="half pull-left">' +
      //   '          <input type="radio" name="operand" value="+" /> <strong class="text-operand">+</strong>' +
      //   "       </div> " +
      //   '       <div class="half pull-right">' +
      //   '          <input type="radio" name="operand" value="-" /> <strong class="text-operand">-</strong>' +
      //   "       </div> " +
      //   "    </center> " +
      //   '    <div class="half pull-left">' +
      //   '       <input id="gifu-currency" class="form-control" placeholder="Fabric Code" type="text" /> ' +
      //   "    </div>" +
      //   '    <div class="half pull-right"> ' +
      //   '       <input class="form-control" id="gifu-amount" class="clearable" type="number" placeholder="Meters" />' +
      //   "    </div>" +
      //   "</div>";
    } else if (window.location.href.indexOf("/orders/") > 0) {
      this.state.entity = "order";
      // this.state.special_fields =
      //   "<div>" +
      //   '    <center style="margin: auto" class="half">' +
      //   '       <div class="half pull-left">' +
      //   '          <input type="radio" name="operand" value="+" /> <strong class="text-operand">+</strong>' +
      //   "       </div> " +
      //   '       <div class="half pull-right">' +
      //   '          <input type="radio" name="operand" value="-" /> <strong class="text-operand">-</strong>' +
      //   "       </div> " +
      //   "    </center> " +
      //   '    <div class="half pull-left">' +
      //   '       <select class="form-control" name="currency" placeholder="Currency" id="gifu-currency">' +
      //   '         <option value="USD">USD</option>' +
      //   '         <option value="VND">VND</option>' +
      //   '         <option value="GBP">GBP</option>' +
      //   '         <option value="AUD">AUD</option>' +
      //   '         <option value="YEN">YEN</option>' +
      //   '         <option value="EURO">EURO</option>' +
      //   "       </select>" +
      //   "    </div>" +
      //   '    <div class="half pull-right"> ' +
      //   '       <input class="form-control" id="gifu-amount" class="clearable" type="number" placeholder="Amount" />' +
      //   "    </div>" +
      //   "</div>";
    } else if (window.location.href.indexOf("/factory/") > 0) {
      this.state.entity = "item";
    } else if (window.location.href.indexOf("/delivery/") > 0) {
      this.state.entity = "order";
    } else if (window.location.href.indexOf("/fitting/") > 0) {
      this.state.entity = "order";
    } else if (window.location.href.indexOf("/fitting/ongoing") > 0) {
      this.state.entity = "item";
    }
  }

  showIFUForm = () => {
    var ref_id =
      this.state.entity === "order" ? store.curOrder.id : store.curIFUrefID;
    // if(this.state.entity === "item" || this.state.entity) {
    //   ref_id = store.curIFUrefID;
    // }
    //this.state.ref_id = ref_id;

    this.setState({ ref_id });
    this.setState(this.state);
    swal({
      title: "IFU Record",
      text: "Note",
      type: "warning",
      html:
        '<div><input id="gifu-oid" value="' +
        this.state.ref_id +
        '" placeholder="Order Number (Required)" type="number" class="form-control"/></div><div><textarea placeholder="Notes" id="gifu-note" class="form-control" rows=4></textarea></div>' +
        this.state.special_fields,
      inputPlaceholder: "Type your message here...",
      showCancelButton: true,
      preConfirm: (note) => {
        var oid = document.getElementById("gifu-oid").value;
        if (oid < 1) {
          swal.showValidationMessage(
            "Please Enter an " +
              (this.state.entity === "cashier" ? "Order" : "Item") +
              " number"
          );
          return;
        }

        var amount = 0;
        var op = "";
        var cy = "";
        // var note = document.getElementById("gifu-note").value;

        // check for special fields
        if (this.state.special_fields.length > 2) {
          try {
            amount = document.getElementById("gifu-amount").value;
          } catch (err) {
            amount = 0;
          }
          op = document.querySelector("input[name='operand']:checked");
          if (!op) op = "";
          else op = op.value;
          cy = document.querySelector("#gifu-currency");
          if (cy.nodeName === "SELECT") {
            cy = cy.options[cy.selectedIndex].value;
          } else if (cy.nodeName === "INPUT") {
            cy = cy.value;
          } else {
            cy = "";
          }
        }
        pushIFU(
          oid,
          this.state.entity,
          amount,
          op,
          cy,
          note,
          this.props.screen
        );
      },
    });
  };

  render() {
    return (
      <div>
        <button
          id="btn-globalifu"
          className={"btn btn-danger btn-ifu" + this.state.classes}
          onClick={this.showIFUForm}
        >
          IFU
        </button>
      </div>
    );
  }
}

export class ForceStaffUnlink extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    setTimeout(function () {
      window.addEventListener("load", this.handleLoad);
    }, 2000);
  }

  handleLoad() {
    var staffWrappers = document.querySelectorAll(".column-staff");
    for (var i = 0; i < staffWrappers.length; i++) {
      var wrapper = staffWrappers[i];
      wrapper.innerHTML = wrapper.textContent;
    }
  }

  render() {
    return <span />;
  }
}

const createGarmentRow = (item) => {
  var tr = document.createElement("tr");
  var itn = document.createElement("td");
  itn.innerHTML = item.item_alias;

  var type = document.createElement("td");
  type.innerHTML = item.description;
  var el_status = document.createElement("td");
  if (item.status_id === "pending")
    el_status.innerHTML =
      "<span style='color:red'>" + item.status_id + "</span>";
  else if (item.status_id === "done")
    el_status.innerHTML =
      "<span style='color:green'>" + item.status_id + "</span>";
  else el_status.innerHTML = item.status_id;

  tr.appendChild(itn);
  tr.appendChild(type);
  tr.appendChild(el_status);

  return tr;
};
// singleton handler
export const singletonProcess = {
  isInitcutProcessing: false,
};

const showGarmentsModal = (cb = 0) => {
  var lb = document.getElementById("lightbox-garments");
  lb.style.display = "block";
  if (cb) {
    document.getElementById("btn-cb").onclick = cb;
  }
};

export const fetchGarments = (order_id) => {
  fetch(api_uri + "/orders/summary_list/" + order_id)
    .then((res) => res.json())
    .then((garments) => {
      garments = _.sortBy(garments.items, (i) => i.index);
      var tbl = document.getElementById("garments-tblbody");
      if (tbl) {
        tbl.innerHTML = "";
        garments.map((i) => tbl.appendChild(createGarmentRow(i)));
        document.getElementById("span-order-num").innerHTML = order_id;
        showGarmentsModal();
        return garments;
      }
    });
};

const hideGarmentsModal = () => {
  var lb = document.getElementById("lightbox-garments");
  lb.style.display = "none";
};

export const ModalGarments = (props) => (
  <div style={styles.lightbox} id="lightbox-garments">
    <div style={styles.lightbox.bg} />
    <div style={styles.lightbox.body} className="lb-body">
      <center style={styles.lightbox.body.content}>
        <h3>
          Garments for order #<span id="span-order-num" />
        </h3>
        <span onClick={hideGarmentsModal} style={styles.modalclose}>
          <i className="fas fa-times" />
        </span>
        <div className="half">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Item Number</th>
                <th>Garment</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody id="garments-tblbody" />
          </table>
        </div>
      </center>
    </div>
  </div>
);

export const DeliveryPreview = ({ record, onClose }) => {
  const handleClose = (flag) => onClose(flag);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ width: "80mm" }}
    >
      <Grid container justify="space-between">
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <IconButton
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            printDeliverySlip(record, handleClose);
          }}
        >
          <PrintIcon />
        </IconButton>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={10}
      >
        <img style={{ margin: 20 }} src={Logo} width="150" alt="Bebe Logo" />
        <Typography gutterBottom variant="h5" component="h5">
          Delivery Slip order #{record.order_id}
        </Typography>
        {record.for_pickup === 1 && (
          <Typography gutterBottom variant="h6" component="h6">
            FOR PICK UP
          </Typography>
        )}

        <Typography gutterBottom>
          {moment().format("DD/MM/YYYY h:mm:ss a")}
        </Typography>
      </Grid>

      <Divider style={{ margin: 20 }} />

      {record.hotel && (
        <Grid container spacing={10}>
          <Grid item>
            <Typography>{`Customer Name: ${record.customer_name}`}</Typography>
            <Typography>{`Hotel: ${record.hotel.name}`}</Typography>
            <Typography>{`Address:  ${record.hotel.address}`}</Typography>
            <Typography>{`Room:  ${record.hotel.room}`}</Typography>
            <Typography>{`Sales Assistant:  ${record.staff}`} </Typography>
            {record.courier && (
              <Typography>{`Courier:  ${record.courier}`} </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Divider style={{ margin: 10 }} />

      <Grid container direction="column">
        <Typography>{`Items (${record.items}):`}</Typography>
        <Grid item style={{ paddingLeft: 40 }}>
          {record.itemList &&
            record.itemList.map((i) => (
              <Typography key={i.id}>{`${i.garment} ${i.detail}`}</Typography>
            ))}
        </Grid>
      </Grid>
      <Divider style={{ margin: 20 }} />

      {record.storeData && (
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography style={{ fontWeight: "bolder" }}>
            PLEASE CHECK ALL ITEMS
          </Typography>
          <Typography>Any problems please call the number below.</Typography>
          <Typography style={{ marginBottom: 10 }}>
            After 9pm call +84 089 964 1605.
          </Typography>
          <Typography>{record.storeData.name}</Typography>
          <Typography>{record.storeData.address}</Typography>
          <Typography>{record.storeData.phone}</Typography>

          <Typography>Hoi An, Vietnam</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const getGarmentData = async (record) => {
  const measurements = await fetch(
    api_uri +
      "/web_order_measurement_items?filter[where][item_id]=" +
      record.item_id
  );

  const mres = await measurements.json();
  record.measurements = _.sortBy(mres, (i) => i.order);
  record.sub_customer_name = mres.length ? mres[0].customer_name : "";

  const customer = await fetch(
    api_uri + "/web_order_customers/" + record.order_id
  );
  const cres = await customer.json();
  record.customer = cres;

  const fabrics = await fetch(
    api_uri + "/web_order_fabrics?filter[where][item_id]=" + record.item_id
  );
  const fres = await fabrics.json();

  for (let i = 0; i < fres.length; i++) {
    const locations = await fetch(
      api_uri + "/stock_locations?filter[where][fabric_id]=" + fres[i].fabric_id
    );
    const lres = await locations.json();
    fres[i].locations = _.filter(
      lres,
      (l) => l.unique_code === fres[i].unique_code
    );
  }

  /*
  fres.forEach(async f => {
    const locations = await fetch(
      api_uri + "/stock_locations?filter[where][fabric_id]=" + f.fabric_id
    );
    const lres = await locations.json();
    f.locations = [{ stock: 1, location: 2 }]; //_.filter(lres, l => l.unique_code === f.unique_code);
  });
*/
  //record.fabrics = fres;

  /* fres.map(async f => {
    f.locations = await getLocations(f.fabric_id);
  });*/

  record.fabrics = fres;

  return record;
};

export const GarmentPreview = ({ record, onClose }) => {
  if (!record) {
    return <CircularProgress />;
  } else
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ width: "80mm" }}
      >
        <Grid container justify="space-between">
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <IconButton color="primary">
            <PrintIcon onClick={() => printGarmentSlip(record, onClose)} />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={10}
        >
          <img style={{ margin: 20 }} src={Logo} width="150" alt="Bebe Logo" />
          <Typography gutterBottom variant="h5" component="h5">
            Phiếu chi tiết sản phẩm
          </Typography>
          <Typography gutterBottom variant="h6" component="h6">
            {` Item #${record.item}`}
          </Typography>

          <Typography gutterBottom>
            {moment().format("DD/MM/YYYY h:mm:ss a")}
          </Typography>
        </Grid>
        <Divider style={{ margin: 20 }} />
        {record.customer && (
          <Grid container spacing={10}>
            <Grid item>
              <Typography>{`Tên nhân viên: ${record.staff}`}</Typography>
              <Typography>{`Tên sản phẩm: ${record.garment_viet}`}</Typography>
              <Typography>{`Ngày thử đồ:  ${moment(
                record.customer.fitting_day
              ).format(dateFormatviet)}`}</Typography>
            </Grid>
          </Grid>
        )}
        <Divider style={{ margin: 10 }} />
        <Typography gutterBottom style={{ fontWeight: "bold" }}>
          Vải
        </Typography>
        <Grid container direction="column">
          {record.fabrics &&
            record.fabrics.map((f, i) => (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography gutterBottom>{`Vải ${
                  i + 1
                }: ${f.unique_code.toUpperCase()}`}</Typography>
                <Typography
                  gutterBottom
                >{`Số mét vải cắt: ${f.fabric_cut}m`}</Typography>
                {f.locations &&
                  f.locations.map((l) => (
                    <Grid
                      gutterBottom
                      container
                      direction="row"
                      justify="space-between"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginBottom: 10,
                      }}
                    >
                      <Typography>{`Khu vực: ${l.location}`}</Typography>
                      <Typography>{`Kho: ${l.stock}m`}</Typography>
                    </Grid>
                  ))}
              </Grid>
            ))}
        </Grid>

        <Divider style={{ margin: 10 }} />
        <Typography
          style={{ alignSelf: "flex-start" }}
          gutterBottom
        >{`Phép Đo [cm] (${record.sub_customer_name}):`}</Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ paddingLeft: 20, paddingRight: 20 }}
          spacing={2}
        >
          {record.measurements &&
            record.measurements.map((i) => (
              <Grid
                item
                container
                xs={5}
                direction="row"
                justify="space-between"
                key={i.id}
              >
                <Typography>{`${i.type_viet}: `}</Typography>
                <Typography>
                  {i.value !== "0" ? i.value + "cm" : "-"}
                </Typography>
              </Grid>
            ))}
          <Grid>
            {record.measurements[0].note && (
              <Typography>{`Notes: ${record.measurements[0].note}`}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid></Grid>
        <Divider style={{ margin: 20 }} />
        <Typography>Bebe Tailor, Hoi An, Vietnam</Typography>
      </Grid>
    );
};

const getitemList = (record) => {
  let items = _(record.itemList)
    .groupBy((i) => i.group + i.customer_name)
    .map((value, key) => ({
      price:
        value[0].price % 1 === 0
          ? formatDecimals(value[0].price.toFixed(0))
          : formatDecimals(value[0].price.toFixed(2)),
      garments: value,
      customer_name: value[0].customer_name,
      garment: value[0].garment,
      id: value[0].id,
      is_suit: value[0].is_suit,
      detail: value[0].is_suit
        ? "(" +
          value[0].num_jackets +
          " Jackets, " +
          value[0].num_pants +
          " Trousers)"
        : "",
    }))
    .value();
  return items;
};

const getPayments = (record) => {
  let payments = _(record.payments)
    .groupBy((i) => i.type_id)
    .map((value, key) => ({
      value,
      type_id: value[0].type_id,
    }))
    .value();

  return payments;
};

export const DepositPreview = ({ record, onClose, reprint }) => {
  const itemList = getitemList(record);
  const payments = getPayments(record);
  let total_deposit = 0;
  let deposit = payments.filter((p) => p.type_id === 1)[0].value;
  //final = payments.filter((p) => p.type_id === 2)[0].value;

  deposit.map((i) => (total_deposit += i.amount / i.currency.rate));
  total_deposit = total_deposit.toFixed(total_deposit % 2 === 0 ? 0 : 2);

  //console.log(record);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ width: "80mm" }}
    >
      <Grid container direction="column" justify="center" alignItems="center">
        <img style={{ margin: 20 }} src={Logo} width="150" alt="Bebe Logo" />
        <Typography variant="h6" component="h6">
          Deposit Receipt
        </Typography>
        <Typography variant="h6" component="h6">
          Order #{record.id}
        </Typography>

        {/*reprint && (
          <Typography variant="h6" component="h6">
            REPRINT
          </Typography>
        )*/}

        <Typography gutterBottom>
          {moment().format("DD/MM/YYYY h:mm:ss a")}
        </Typography>
      </Grid>

      <Divider style={{ marginTop: 20 }} />

      <Grid container>
        <Grid item>
          <Typography>{`Customer Name: ${record.customer_first_name} ${record.customer_last_name}`}</Typography>
          {record.store_id !== 4 ? (
            <div>
              <Typography>{`Hotel: ${record.hotel_ids[0].hotel.name}`}</Typography>
              <Typography>{`Room:  ${record.hotel_ids[0].room}`}</Typography>
              <Typography>{`Fitting Time:  ${moment(record.fitting)
                .utc()
                .format(dateFormat)}`}</Typography>
            </div>
          ) : (
            <Typography>{`Hotel: Online Order`}</Typography>
          )}

          <Typography>
            {`Sales Assistant:  ${record.staff_ids[0].staff.name}`}
          </Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: 10 }} />

      <Grid container direction="column">
        <Typography>{`Items (${record.items.length}):`}</Typography>
        <Grid item style={{ paddingLeft: 40 }}>
          {itemList.map((i) => (
            <div key={i.id}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography>{`${i.garment} - ${i.customer_name}`}</Typography>
                <Typography>{`$${i.price}`}</Typography>
              </Grid>
              <Typography
                style={{ paddingLeft: 20 }}
              >{` ${i.detail}`}</Typography>
            </div>
          ))}
        </Grid>
      </Grid>

      <div>
        {record.discount > 0 && (
          <div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <Grid container direction="column">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography>Total:</Typography>
                <Typography>{`$${record.total_price}`}</Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography>Discount:</Typography>
                <Typography>{`$${record.discount}`}</Typography>
              </Grid>
            </Grid>
          </div>
        )}

        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Grid container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography style={{ fontWeight: "bold" }}>Total Bill:</Typography>

            <Typography style={{ fontWeight: "bold" }}>{`$${
              record.total_price - record.discount
            }`}</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography
              style={{ fontWeight: "bold" }}
            >{`Deposit Paid:`}</Typography>

            <Typography
              style={{ fontWeight: "bold" }}
            >{`$${total_deposit}`}</Typography>
          </Grid>

          {deposit.is_cc === 1 && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              {deposit.currency === "USD" ? (
                <Typography style={{ fontWeight: "bold" }}>
                  Credit Card Fee:
                </Typography>
              ) : (
                <PaymentFormat
                  style={{ fontWeight: "bold" }}
                  displayType="text"
                  value={deposit.alt_fee}
                  prefix="Credit Card Fee: ($"
                  suffix={` ${deposit.currency})`}
                />
              )}

              <Typography
                style={{ fontWeight: "bold" }}
              >{`$${deposit.fee}`}</Typography>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            <Typography style={{ fontWeight: "bold" }}>Balance Due:</Typography>
            <Typography style={{ fontWeight: "bold" }}>{`$${(
              record.total_price -
              record.discount -
              total_deposit
            ).toFixed(
              (record.total_price - record.discount - total_deposit) % 2 === 0
                ? 0
                : 2
            )}`}</Typography>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      </div>

      <Grid container direction="column" justify="center" alignItems="center">
        <Typography>{record.store.name}</Typography>
        <Typography>{record.store.address}</Typography>
        <Typography>{record.store.phone}</Typography>
        <Typography>Hoi An, Vietnam</Typography>
      </Grid>
    </Grid>
  );
};

const print = async (record, handleClose, reprint, type) => {
  let timerInterval;
  swal({
    title: "Printing Slip...",
    timer: 2000,
    onOpen: () => {
      swal.showLoading();
      timerInterval = setInterval(() => {}, 100);

      let payload = {
        receipt_type: type === "final" ? "final_payment" : type,
        date: moment().format("DD/MM/YYYY, h:mm:ss a"),
        fitting_time: moment(record.fitting_time)
          .utc()
          .format("DD/MM/YYYY, h:mm:ss a"),
        data: record,
        order_id: record.id,
        order_number: record.id,
        reprint,
      };

      fetch(print_server_IP + "/printer/", {
        body: JSON.stringify(payload),
        method: "POST",
      }).then((res) => {
        refreshRecord();
        handleClose(true);
      });
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    if (result.dismiss === swal.DismissReason.timer) {
    }
  });
};

const printDeliverySlip = (record, handleClose) => {
  let timerInterval;
  swal({
    title: "Printing Slip...",
    timer: 2000,
    onOpen: () => {
      swal.showLoading();
      timerInterval = setInterval(() => {}, 100);

      let payload = {
        receipt_type: "delivery",
        date: moment().format("DD/MM/YYYY, h:mm:ss a"),
        data: record,
        order_id: record.id,
        order_number: record.id,
      };

      fetch(print_server_IP + "/printer/", {
        body: JSON.stringify(payload),
        method: "POST",
      })
        .then((res) => res.json())
        .then((res) => {
          handleClose(true);
        });
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    if (result.dismiss === swal.DismissReason.timer) {
    }
  });
};

const printGarmentSlip = (record, handleClose) => {
  let timerInterval;
  swal({
    title: "Printing Slip...",
    timer: 2000,
    onOpen: () => {
      swal.showLoading();
      timerInterval = setInterval(() => {}, 100);

      let payload = {
        receipt_type: "garment_slip",
        date: moment().format("DD/MM/YYYY, h:mm:ss a"),
        data: record,
        order_id: record.id,
        order_number: record.id,
        fitting_day: moment(record.customer.fitting_day).format(dateFormat),
      };

      fetch(print_server_IP + "/printer/", {
        body: JSON.stringify(payload),
        method: "POST",
      })
        .then((res) => res.json())
        .then((res) => {
          handleClose();
        });
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    if (result.dismiss === swal.DismissReason.timer) {
    }
  });
};

export function PaymentFormat(props) {
  const { inputRef, onChange, rem, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowLeadingZeros={false}
    />
  );
}
