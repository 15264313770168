// in src/garments.js
import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ListButton,
  TopToolbar,
  ImageField,
  ImageInput,
} from "react-admin";

const GarmentOptionValueCreate = (props) => {
  return (
    <Create
      {...props}
      title="Create New Garment Option Value"
      actions={<Actions />}
    >
      <SimpleForm>
        <TextInput source="name" label="resources.garment.value_name" />
        <TextInput source="description" label="resources.garment.description" />
        <TextInput source="vietnamese" label="resources.garment.vietnamese" />
        <TextInput source="subheading" label="table_columns.subheading" />

        <ImageInput
          source="images"
          label="table_columns.image"
          accept="image/*"
        >
          <ImageField source="image" title="title" />
        </ImageInput>

        <ImageInput
          source="previews"
          label="table_columns.preview"
          accept="image/*"
        >
          <ImageField source="preview" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentOptionValueCreate;
