import React, {Component} from "react";
import QrReader from "react-qr-reader";
import swal from "sweetalert2";
import {refreshRecord, api_uri} from "../../globals";
import mp3_file from "../../assets/soundpool/beep.mp3";

export default class QRScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 300,
      result: "No result"
    };
    this.handleScan = this.handleScan.bind(this);
  }
  handleScan(data) {
    if (data) {
      var tmp = data.split("/");
      tmp = tmp[tmp.length - 1];
      var item_id = tmp.split(".")[0];
      if (item_id === this.state.result) return;
      this.setState({
        result: item_id
      });
      document.getElementById("item-inpt").value = item_id;
      this.fireQCAdd();
    }
  }
  handleError(err) {
  }

  addQCItem = event => {
    if (event.key !== "Enter") return;
    var inpt = document.getElementById("item-inpt").value;
    if(inpt.indexOf("/")) {
      var tmp = inpt.split("/");
      tmp = tmp[tmp.length - 1];
      var item_id = tmp.split(".")[0];
      document.getElementById("item-inpt").value = item_id;
    }
    this.fireQCAdd();
    document.getElementById("item-inpt").value = "";
  };

  fireQCAdd() {




    var xhttp = new XMLHttpRequest();

    this.playBeep();
    var item = this.refs.item.value
      ? this.refs.item.value
      : document.getElementById("item-inpt").value;

      item = item.replace(/\s+/g, '');
    if( (item.indexOf('-') === item.lastIndexOf('-')) && item.indexOf('-') >0 )
    {
      var temp =  item.split('-');
      fetch(api_uri + "/orders/getItem/"+temp[0]+"/"+temp[1])
        .then(function(response) { return response.json(); })
        .then(function(res) {
    
      xhttp.onreadystatechange = function() {
        //console.dir(this.responseText);
        if (this.readyState === 4 && this.status === 200) {
          var d = JSON.parse(this.responseText);
          swal({
            title: "Item " + item + " added for " + d.dest,
            type: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Confirm"
          }).then(result => {
            refreshRecord();
          });
        } else {
          try {
            var res = JSON.parse(this.responseText);
            swal({
              title: "Error",
              type: "warning",
              html: res.error.message,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Confirm"
            }).then(result => {
              refreshRecord();
            });
          } catch (e) {}
        }
      };
       
      if(res.id !== null && Number(res.id))
      {
        xhttp.open("GET",api_uri + "/factory/addqcitem?item_id=" + (res.id) + "&bypass_first_qc=0",false);
        xhttp.send();  
      }
      else
      {
        swal({
          title: "Error",
          type: "warning",
          html: "Item not found",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Confirm" })
      }

    }); }
    else
    {
    xhttp.onreadystatechange = function() {
      //console.dir(this.responseText);
      if (this.readyState === 4 && this.status === 200) {
        var d = JSON.parse(this.responseText);
        swal({
          title: "Item " + item + " added for " + d.dest,
          type: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Confirm"
        }).then(result => {
          refreshRecord();
        });
      } else {
        try {
          var res = JSON.parse(this.responseText);
          swal({
            title: "Error",
            type: "warning",
            html: res.error.message,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Confirm"
          }).then(result => {
            refreshRecord();
          });
        } catch (e) {}
      }
    };

      var reg = new RegExp('^[0-9]+$');

if(reg.test(item))
{
    
    xhttp.open(
      "GET",
      api_uri +
        "/factory/addqcitem?item_id=" +
        item +
        "&bypass_first_qc=0",
      false
    );
    xhttp.send();
    
    }
    else{
      swal({
        title: "Error",
        type: "warning",
        html: "Item not found",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Confirm" })

  }
 

  }
  }

  playBeep() {
    document.getElementById("qc-beep").play();
  }

  reset = () => {
    this.setState({result: ""});
    this.setState(this.state);
  };

  render() {
    return (
      <div className="qr-scanner-cam">
        <input
          onKeyUp={this.addQCItem}
          id="item-inpt"
          ref="item"
          className="form-control"
          type="text"
          name="order_item"
          placeholder="Add item"
        />
        <input
          id="reset-scanner"
          type="button"
          className="el-hide"
          onClick={this.reset}
        />
        <div>
          <QrReader
            delay={this.state.delay}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{width: "100%"}}
          />
        </div>
        <AudioPlayer />
      </div>
    );
  }
}

const AudioPlayer = function(props) {
  return <audio id="qc-beep" src={mp3_file} controls="" autoPlay="" />;
};
