import React, { useEffect, useRef } from "react";
import {
  number,
  TextInput,
  ReferenceInput,
  SelectInput,
  useTranslate,
  useDataProvider,
  Button,
} from "react-admin";
import NumberFormat from "react-number-format";
import Grid from "@material-ui/core/Grid";
import { LinearProgress } from "@material-ui/core";
import { DepositPreview, FinalPreview } from "../../print";
import { Button as ButtonUI } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import DialogActions from "@material-ui/core/DialogActions";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import CreditCardIcon from "@material-ui/icons/CreditCard";

import _ from "lodash";
import { useForm } from "react-final-form";
import { Typography } from "@material-ui/core";

export const PaymentInput = ({ order_id, formData, ...rest }) => {
  const translate = useTranslate();

  const dataProvider = useDataProvider();
  const [rates, setRates] = React.useState([]);
  const [balance, setBalance] = React.useState(0);

  const [paymentTypes, setPaymentTypes] = React.useState();
  const [paymentType, setPaymentType] = React.useState();

  const [order, setOrder] = React.useState(null);
  //const [staffs, setStaffs] = React.useState([]);

  const form = useForm();
  useEffect(() => {
    dataProvider
      .getOne("orders", {
        id: order_id,
      })
      .then(({ data }) => {
        setBalance(data.balance);
        form.change("balance", data.balance);
        setOrder(data);
      });
  }, []);

  useEffect(() => {
    dataProvider
      .getMany("exchange_rates", {
        ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      })
      .then(({ data }) => {
        setRates(data);
      });
  }, []);

  useEffect(() => {
    dataProvider
      .getMany("payment_types", {
        ids: [1, 2, 3],
      })
      .then(({ data }) => {
        setPaymentTypes(data);
      });
  }, []);

  //console.log(formData);
  const [rate, setRate] = React.useState(formData.rate);
  const [currencyId, setCurrencyId] = React.useState(null);
  const [currency, setCurrency] = React.useState(null);

  const [amount, setAmount] = React.useState(formData.amount);

  const required =
    (message = "Required") =>
    (value) => {
      return value ? undefined : message;
    };
  const validateNumber = [required(), number("Must be a number")];

  //console.log({ order, formData, rates });

  /*
    if (formData.type === "final") {
      form.change("amount", order.balance);
      form.change("amount_alt", order.balance);
  
      form.change("rate", 1);
    }*/

  // const rate = useGetOne("exchange_rates", formData.currency_id);
  //rate.data && console.log(rate.data.rate);

  const handleAmountChange = (e) => {
    if (paymentType !== 3) {
      //console.log("handleAmountChange", e.target.value);
      let value = e.target.value;
      //e.floatValue
      //console.log(e);
      let decimals = currencyId === 1 ? 0 : 2;
      //console.log(currencyId);
      currencyId && form.change("amount_usd", (value / rate).toFixed(decimals));
      //console.log(rate, currencyId, value.target.value);
      setAmount(value);
    }
  };

  const handleCurrencyChange = (e) => {
    //console.log(rates, rates[e.target.value]);
    //console.log("handleCurrencyChange", e.target.value);
    setCurrencyId(Number(e.target.value));
    console.log(rates, e.target.value);
    let currentRate = _.find(rates, (x) => x.id === e.target.value).rate;
    let currentCurrency = _.find(rates, (x) => x.id === e.target.value);
    setCurrency(currentCurrency);

    setRate(currentRate);
    if (paymentType === 1 || paymentType === 2) {
      let decimals = Number(e.target.value) === 1 ? 0 : 2;
      form.change("rate", rate);
      if (formData.type_id === 1 || formData.type_id === 4) {
        formData.amount &&
          form.change(
            "amount_usd",
            Number((amount / currentRate).toFixed(decimals))
          );
      } else {
        form.change(
          "amount",
          Number((order.balance * currentRate).toFixed(decimals))
        );
      }
    }
  };
  const handleShippingChange = (e) => {
    //console.log(e.target.value);
  };
  const handlePaymentChange = (e) => {
    form.change("amount", null);
    form.change("amount_usd", null);
    form.change("currency_id", null);
    setPaymentType(e.target.value);
  };

  const getPaymentTypes = () => {
    if (order.status_id > 4) {
      paymentTypes.forEach((e) => {
        if (e.id === 1 || e.id === 2) e.disabled = true;
      });
    }
    return paymentTypes;
  };

  return (
    <Grid container direction="row">
      <Grid container item xs={6} direction="column">
        <Grid item>
          {order ? (
            <SelectInput
              choices={getPaymentTypes()}
              source="type_id"
              onChange={(e) => handlePaymentChange(e)}
              optionText={(choice) => choice.description}
              validate={required()}
            />
          ) : (
            <LinearProgress />
          )}
        </Grid>
        <Grid item>
          <CurrencyDropDown
            required={required}
            rates={rates}
            handleCurrencyChange={handleCurrencyChange}
          />
        </Grid>
        {(paymentType === 1 || paymentType === 3) && (
          <Grid item>
            <TextInput
              source="amount"
              onChange={(e) => handleAmountChange(e)}
              validate={validateNumber}
            />
          </Grid>
        )}
        <Grid item>
          <PaymentAmount
            source="amount_usd"
            data={formData}
            currency={currency}
          />
        </Grid>
        <Grid item>
          <ReferenceInput
            label="table_columns.store"
            source="store_id"
            reference="stores"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item>
          <TextInput
            label={translate("table_columns.notes")}
            source="notes"
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={6} justify="center">
        <Grid item>
          {order && paymentType && (
            <div
              style={{
                border: "dashed",
                borderWidth: "thin",
                padding: 10,
                borderColor: "grey",
              }}
            >
              {paymentType === 1 && (
                <DepositPreview
                  record={order}
                  reprint={false}
                  total_deposit={
                    amount && currency
                      ? (amount / currency.rate).toFixed(
                          (amount / currency.rate) % 1 === 0 ? 0 : 2
                        )
                      : 0
                  }
                />
              )}
              {paymentType === 2 && (
                <FinalPreview
                  reprint={false}
                  record={order}
                  final_payment={balance}
                />
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const CurrencyDropDown = ({
  rates,
  handleCurrencyChange,
  required,
  ...rest
}) => (
  <ReferenceInput
    source="currency_id"
    label="table_columns.currency"
    reference="exchange_rates"
    {...rest}
    onChange={(value) => rates && handleCurrencyChange(value)}
    validate={required()}
    sort={{ field: "order", order: "ASC" }}
  >
    <SelectInput
      optionText={(record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography>{record.alias}</Typography>
          {record.fee > 0 && <CreditCardIcon style={{ marginLeft: 10 }} />}
          {record.rate !== 1 && (
            <Typography
              style={{ marginLeft: 10 }}
            >{`(rate: ${record.rate})`}</Typography>
          )}
        </div>
      )}
    />
  </ReferenceInput>
);

const PaymentAmount = ({ data, currency }) => {
  //return <Typography>{data.amount_usd && `${data.amount_usd} USD`}</Typography>;
  return currency ? (
    <div style={{ display: "flex", marginBottom: 15 }}>
      {data.type_id === 1 && (
        <NumberFormat
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          value={data.amount_usd}
          prefix={"Paying $"}
          suffix={" USD"}
          renderText={(value) => (
            <Typography variant="subtitle2">{value}</Typography>
          )}
          displayType="text"
          decimalScale={data.amount_usd % 1 === 0 ? 0 : 2}
        />
      )}
      {data.type_id === 2 && (
        <NumberFormat
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          value={data.balance}
          prefix={"Paying $"}
          suffix={" USD"}
          renderText={(value) => (
            <Typography variant="subtitle2">{value}</Typography>
          )}
          displayType="text"
          decimalScale={data.balance % 1 === 0 ? 0 : 2}
        />
      )}
      {data.type_id === 3 && (
        <NumberFormat
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          value={data.amount / currency.rate}
          prefix={"Paying $"}
          suffix={" USD for Shipping"}
          renderText={(value) => (
            <Typography variant="subtitle2">{value}</Typography>
          )}
          displayType="text"
          decimalScale={data.balance % 1 === 0 ? 0 : 2}
        />
      )}
      {data.amount && (
        <NumberFormat
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          value={data.amount}
          prefix={` with ${currency.symbol}`}
          suffix={` ${currency.alias}`}
          renderText={(value) => (
            <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
              {value}
            </Typography>
          )}
          displayType="text"
          decimalScale={data.amount_usd % 1 === 0 ? 0 : 2}
        />
      )}
    </div>
  ) : null;
};

export const PaymentSlipPreview = ({ record = {} }) => {
  //console.log("PAYMENT SLIP PREVIEW", record);
  const [open, setOpen] = React.useState(false);
  const [depositOpen, setDepositOpen] = React.useState(false);
  const [finalOpen, setFinalOpen] = React.useState(false);
  const [receipt, setReceipt] = React.useState("deposit");
  const [isLoading, setIsLoading] = React.useState(false);
  const componentRef = useRef();
  const handleChange = (event) => {
    //console.log(event.target.value);
    event.stopPropagation();
    setReceipt(event.target.value);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleCloseReceipt = (event) => {
    event.stopPropagation();
    setDepositOpen(false);
    setFinalOpen(false);
  };

  const handlePreview = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    //await getData(record);
    setIsLoading(false);
    if (receipt === "deposit") setDepositOpen(true);
    else setFinalOpen(true);

    //receipt === "deposit" ? setDepositOpen(true) : setFinalOpen(true);
  };

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <IconButton
        disabled={record.status_id === 1}
        color="primary"
        //onClick={() => setOpen(true)}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(true);
        }}
      >
        <PrintIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>Select Receipt</DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset">
            <RadioGroup value={receipt} onChange={handleChange}>
              <FormControlLabel
                value={"deposit"}
                control={<Radio />}
                label="Deposit"
              />
              <FormControlLabel
                disabled={record.status_id < 5 || record.status_id > 7}
                value={"final"}
                control={<Radio />}
                label="Final Payment"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            <Typography>Cancel</Typography>
          </Button>
          <Button autoFocus color="primary" onClick={handlePreview}>
            <Typography>OK</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={depositOpen}
        onClose={handleCloseReceipt}
        disableBackdropClick
      >
        <DialogContent dividers>
          <div>
            <ReactToPrint
              trigger={() => <ButtonUI color="primary">PRINT</ButtonUI>}
              content={() => componentRef.current}
            />
            <DepositPreview
              ref={componentRef}
              record={record}
              total_deposit={null}
              reprint={true}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonUI onClick={handleCloseReceipt} color="primary">
            Close
          </ButtonUI>
        </DialogActions>
      </Dialog>
      <Dialog open={finalOpen} onClose={handleClose}>
        <DialogContent>
          <div>
            <ReactToPrint
              trigger={() => <ButtonUI color="primary">PRINT</ButtonUI>}
              content={() => componentRef.current}
            />
            <FinalPreview
              ref={componentRef}
              record={record}
              final_payment={null}
              reprint={true}
            />
          </div>{" "}
        </DialogContent>
        <DialogActions>
          <ButtonUI onClick={handleCloseReceipt} color="primary">
            Close
          </ButtonUI>
        </DialogActions>
      </Dialog>
    </div>
  );
};
