import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";

//import indigo from "@material-ui/core/colors/indigo";
//import pink from "@material-ui/core/colors/pink";
//import red from "@material-ui/core/colors/red";
/*
const BebeTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    fontSize: "0.875rem"
    // Use the system font instead of the default Roboto font.
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "white" // Some CSS
      }
    }
  }
});
*/
const BebeTheme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      head: {
        fontWeight: "bold",
      },
    },
  },
  sidebar: {
    width: 170, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
  typography: {
    fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 24,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    body1: {
      fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1.4rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    raRichTextInput: {
      fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1.4rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    subtitle1: {
      fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "1.4rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: indigo["500"],
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    rows: {
      ok: "#e8f5e9",
    },
  },
});
export default BebeTheme;
