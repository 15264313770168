import React, { useState } from "react";
import {
  FormDataConsumer,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  BooleanInput,
  Error,
  useQuery,
  SaveButton,
  Toolbar,
  TopToolbar,
  ListButton,
} from "react-admin";
import { useForm } from "react-final-form";
import { CircularProgress } from "@material-ui/core";

const SwatchbookCreate = (props) => {
  return (
    <Create {...props} actions={<ListActions />}>
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => <Swatchbook {...formData} />}
        </FormDataConsumer>

        <TextInput source="description" label="table_columns.description" />
        <BooleanInput source="is_virtual" label="Is Virtual?" />
      </SimpleForm>
    </Create>
  );
};

const Swatchbook = ({ formData, ...rest }) => {
  const [fabricTypeId, setFabricTypeId] = useState();
  const required = (message = "Required") => (value) =>
    value ? undefined : message;

  return (
    <div>
      <ReferenceInput
        {...rest}
        reference="fabric_types"
        source="type_id"
        sort={{ field: "alias", order: "ASC" }}
        allowEmpty={false}
        perPage={-1}
        onChange={(value) => setFabricTypeId(value)}
        validate={required()}
      >
        <AutocompleteInput
          optionText={(record) =>
            record && `${record.alias} - ${record.description}`
          }
        />
      </ReferenceInput>
      {fabricTypeId && <UniqueCode fabricTypeId={fabricTypeId} {...rest} />}
    </div>
  );
};
const UniqueCode = ({ fabricTypeId }) => {
  const form = useForm();

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "getCodes",
    payload: { id: fabricTypeId ? fabricTypeId : 1 },
  });
  data && form.change("unique_code", data.code);

  if (loading) return <CircularProgress />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <TextInput
      source="fabricTypeId"
      value={data.code}
      disabled
      label="table_columns.swatchbook"
    />
  );
};

const UserCreateToolbar = ({ permissions, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="ra.action.save" redirect="list" />
    </Toolbar>
  );
};
const ListActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default SwatchbookCreate;
