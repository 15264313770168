import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  NumberField,
  BooleanField,
  EditButton,
} from "react-admin";
import Link from "@material-ui/core/Link";
import { CustomPagination, CustomDateField, bas_url } from "../../globals";
import { SwatchbookFilter } from "./SwatchbookFilter";

const SwatchbookList = (props) => (
  <List
    {...props}
    title="resources.fabrics.fields.swatchbook_list"
    perPage={10}
    sort={{ field: "unique_code", order: "ASC" }}
    bulkActionButtons={false}
    pagination={<CustomPagination />}
    filters={<SwatchbookFilter />}
  >
    <Datagrid>
      <FunctionField
        label="table_columns.swatchbook"
        render={(record) => (
          <Link
            component={Link}
            href={`${bas_url}/s/${record.unique_code}`}
            color="primary"
            target="_blank"
          >
            {record.unique_code}
          </Link>
        )}
      />
      <TextField source="description" label="table_columns.description" />

      <ReferenceField
        label="resources.swatchbooks.fabric_type"
        source="type_id"
        reference="fabric_types"
        link={false}
      >
        <FunctionField
          render={(record) => `${record.alias} - ${record.description}`}
        />
      </ReferenceField>
      <CustomDateField source="creation_date" formatedDate={true} />

      <NumberField source="fabrics.fabrics" label="table_columns.num_fabrics" />

      <BooleanField source="is_virtual" label="Is Virtual" />
      <EditButton />
    </Datagrid>
  </List>
);

export default SwatchbookList;
