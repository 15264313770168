import React from "react";
import {
  Show,
  TextField,
  TopToolbar,
  EditButton,
  ListButton,
  SimpleShowLayout
} from "react-admin";
import { CustomDateField } from "../../globals";

const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const HotelShow = props => (
  <Show {...props} actions={<ShowActions />} component="div">
    <SimpleShowLayout>
      <TextField source="name" label="table_columns.hotel_name" />
      <TextField source="address" label="table_columns.address" />
      <TextField source="phone" label="table_columns.phone" />
      <CustomDateField
        source="creation_date"
        label="table_columns.creation_date"
        formatedDate={true}
      />
    </SimpleShowLayout>
  </Show>
);
export default HotelShow;
