import React from "react";

import {
  Filter,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  TextInput
} from "react-admin";
import { convertViToEn } from "../globals";

export const StaffFilter = props => (
  <Filter {...props} variant="standard">
    <ReferenceInput
      label="table_columns.staff"
      source="staff_id"
      reference="sellers"
      perPage={10000}
      resettable
      sort={{ field: "name", order: "ASC" }}
      suggestionLimit={5}
    >
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
    <DateInput source="date.gt" label="Date from" key="gt" />
    <ReferenceInput
      label="table_columns.hotel"
      source="hotel_id"
      reference="hotels"
      perPage={10000}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText={record =>
          record &&
          `${convertViToEn(record.name)} - ${convertViToEn(record.address)}`
        }
        resettable
      />
    </ReferenceInput>

    <TextInput source="book" label="table_columns.book_number" />
    <TextInput source="code" label="table_columns.code" />
  </Filter>
);
