import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  Responsive,
  FunctionField,
  ReferenceField,
} from "react-admin";
import LinkIcon from "@material-ui/icons/Link";

import Tooltip from "@material-ui/core/Tooltip";

import { StaffFilter } from "./StaffFilter";

import { CustomDateField, StaffLink, CustomPagination } from "../globals";
import { BookCodeField, HotelField } from "../components";
//import { PaymentFilter } from "./PaymentFilter";
import { Typography } from "@material-ui/core";

export const StaffList = ({ permissions, ...props }) => {
  /*const paymentRowStyle = (record, index) => ({
    // backgroundColor: record.balance === 0 ? "#e8f5e9" : "white"
  });*/
  return (
    <List
      {...props}
      filters={<StaffFilter />}
      sort={{ field: "date", order: "DESC" }}
      // bulkActionButtons={false}
      //actions={<ListActions />}
      aside={<Aside />}
      bulkActionButtons={<BulkActionButtons />}
      pagination={<CustomPagination />}
    >
      <Responsive
        //small={}
        medium={
          <Datagrid //rowStyle={paymentRowStyle} expand={<ExpandPanel />}
          >
            <CustomDateField
              source="date"
              label="table_columns.date"
              formatedDate={true}
            />
            <BookCodeField label="table_columns.book_code" source="item" />
            <HotelField label="table_columns.hotel" source="payment" />
            <ReferenceField
              reference="sellers"
              source="staff_id"
              sortable={false}
            >
              <FunctionField
                render={(record) => (
                  <StaffLink
                    record={{
                      staff_id: record.id,
                      staff_thumbnail: record.image,
                      name: record.name,
                    }}
                  />
                )}
              />
            </ReferenceField>
            <FunctionField
              label="table_columns.total_bill"
              render={(record) => (
                <Typography>{`$${record.total_bill}`}</Typography>
              )}
            />
            <FunctionField
              label="table_columns.amount"
              render={(record) => (
                <div>
                  <Typography component="b">{`$${record.amount}`}</Typography>
                  <Typography variant="caption">
                    {` (${record.percentage}%)`}
                  </Typography>
                </div>
              )}
            />

            <FunctionField
              label="Linked Order"
              render={(record) =>
                record.order.linked_order_id && (
                  <Tooltip title="Linked Order" label="linked order">
                    <LinkIcon style={{ color: "#ff5252" }} />{" "}
                  </Tooltip>
                )
              }
            />
          </Datagrid>
        }
      />
    </List>
  );
};

export default StaffList;
/*
const ExpandPanel = props => {
  return (
    <SimpleShowLayout {...props}>
      {props.record.notes ? (
        <RichTextField source="notes" />
      ) : (
        <Labeled label="Notes">
          <Typography>No Notes</Typography>
        </Labeled>
      )}
    </SimpleShowLayout>
  );
};
*/
const BulkActionButtons = ({ resource, selectedIds }) => <Fragment></Fragment>;

const Aside = ({ data, ids, selectedIds }) => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Summary</Typography>
    <Typography variant="body2">
      {`Total Income: 
      $${selectedIds
        .map((id) => data[id])
        .reduce((sum, post) => sum + post.amount, 0)} `}
    </Typography>
  </div>
);
