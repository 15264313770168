import React from "react";
import {
  Datagrid,
  List,
  Responsive,
  EditButton,
  TextField,
  FunctionField
} from "react-admin";

import { HotelFilter } from "./HotelFilter";

import { CustomDateField } from "../../globals";
import { Typography } from "@material-ui/core";

const Pagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />
);

export const HotelList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<HotelFilter />}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
    >
      <Responsive
        //small={}
        medium={
          <Datagrid>
            <TextField source="name" label="table_columns.hotel_name" />
            <TextField source="address" label="table_columns.address" />
            <FunctionField
              render={record =>
                record.phone ? (
                  <Typography>{record.phone}</Typography>
                ) : (
                  <Typography>Unknown</Typography>
                )
              }
            />
            <CustomDateField
              source="creation_date"
              formatedDate={true}
              label="table_columns.creation_date"
            />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default HotelList;
