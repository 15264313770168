import React from "react";
import {
  TextField,
  SimpleShowLayout,
  Show,
  TopToolbar,
  EditButton,
  ListButton,
  DateField,
  ArrayField,
  Datagrid,
  Labeled,
} from "react-admin";
import Divider from "@material-ui/core/Divider";
import { ImageURLField, DisplayField } from "./components";

import { styles } from "../../globals";

const MeasurementShow = (props) => (
  <Show
    {...props}
    title="resources.staff.fields.show_title"
    style={{ maxWidth: 1280, height: "100%" }}
    actions={<ShowActions />}
  >
    <SimpleShowLayout style={styles.row}>
      <TextField label="table_columns.name" source="type_eng" />
      <TextField label="table_columns.vietnamese" source="type_viet" />
      <TextField label="table_columns.order" source="order" />
      <Divider style={{ margin: 20 }} />

      <TextField label="table_columns.description" source="description" />
      <Divider style={{ margin: 20 }} />

      <TextField label="table_columns.instructions" source="instructions" />
      <Labeled label="Display on">
        <DisplayField source="scope" />
      </Labeled>
      <Divider style={{ margin: 20 }} />

      <ImageURLField source="image" label="table_columns.image" />
      <Divider style={{ margin: 20 }} />

      <ArrayField source="garment_ids">
        <Datagrid>
          <TextField source="garment.id" label="table_columns.id" />

          <TextField
            source="garment.description"
            label="table_columns.garment"
          />
        </Datagrid>
      </ArrayField>
      <Divider style={{ margin: 20 }} />

      <DateField
        label="table_columns.creation_date"
        source="creation_date"
        showTime
      />
      <DateField
        label="table_columns.update_date"
        source="update_date"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);
const ShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default MeasurementShow;
