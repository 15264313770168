import CollectionList from "./CollectionList";
import CollectionShow from "./CollectionShow";
import CollectionEdit from "./CollectionEdit";
import CollectionCreate from "./CollectionCreate";

export default {
  create: CollectionCreate,
  edit: CollectionEdit,
  list: CollectionList,
  show: CollectionShow,
  //icon: ChatBubbleIcon
};
