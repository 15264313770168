import React from "react";
import { FunctionField } from "react-admin";
import {
  Datagrid,
  List,
  EditButton,
  ShowButton,
  TextField,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { TextField as MUITextInput } from "@material-ui/core";

import LinkIcon from "@material-ui/icons/Link";
import { CustomPagination, CustomDateField } from "../../globals";
import Link from "@material-ui/core/Link";
import { bas_url } from "../../globals";

export const CollectionList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      sort={{ field: "creation_date", order: "DESC" }}
      bulkActionButtons={false}
      pagination={<CustomPagination />}
    >
      <Datagrid>
        <TextField source="id" label="table_columns.id" />
        <TextField source="name" label="table_columns.name" />
        <TextField source="description" label="table_columns.description" />
        <TextField source="fabrics" label="table_columns.num_fabrics" />

        <FunctionField
          label={"URL"}
          render={(record) => (
            <span>
              <MUITextInput
                style={{ width: 300 }}
                value={`${bas_url}/c/${record.name}`}
              />
              <Link
                style={{ marginLeft: 5 }}
                href={`${bas_url}/c/${record.name}`}
                color="primary"
                target="_blank"
              >
                <LinkIcon />
              </Link>
            </span>
          )}
        />
        <CustomDateField
          source="creation_date"
          formatedDate={true}
          utc={false}
          label="table_columns.creation_date"
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default CollectionList;
const ListActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} record={data} />
  </TopToolbar>
);
