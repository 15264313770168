import React from "react";

import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ImageField,
  ReferenceField,
  FunctionField,
} from "react-admin";

import { CustomPagination, fabric_thumbnail } from "../../globals";

import GarmentFabricFilter from "./GarmentFabricFilter";
const GarmentFabricList = (props) => (
  <List
    {...props}
    filters={<GarmentFabricFilter />}
    title="resources.garment_option.list"
    sort={{ field: "creation_date", order: "DESC" }}
    pagination={<CustomPagination />}
    bulkActionButtons={false}
    style={{ maxWidth: 1280, height: "100%" }}
  >
    <Datagrid>
      <TextField source="name" label="table_columns.name" />
      <TextField source="description" label="table_columns.description" />
      <ReferenceField source="garment_id" reference="garments">
        <TextField source="alias" />
      </ReferenceField>
      <ReferenceField source="fabric_id" reference="fabrics">
        <TextField source="unique_code" />
      </ReferenceField>

      <ReferenceField source="fabric_id" reference="fabrics" label="thumbnail">
        <FunctionField
          render={(record) => (
            <img
              width={100}
              src={fabric_thumbnail + record.image}
              alt={record.unique_code}
            />
          )}
        />
      </ReferenceField>
      <ImageField source="preview" title="title" label="Preview" />
      <EditButton />
    </Datagrid>
  </List>
);
export default GarmentFabricList;
