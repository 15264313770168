import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  SaveButton,
  Toolbar,
  email,
  //withTranslate,
  TopToolbar,
  ListButton,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  maxLength,
  useTranslate,
  useCreate,
  useNotify,
  useRedirect,
  useDelete,
} from "react-admin";
import { Divider } from "@material-ui/core";

import RichTextInput from "ra-input-rich-text";
import { makeStyles } from "@material-ui/core/styles";
import "../assets/css.css";
import _ from "lodash";
import { Typography } from "@material-ui/core";

import { useFormState } from "react-final-form";

//import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const ListActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const useStyles = makeStyles({
  text: {
    fontWeight: "bold",
    fontSize: "large",
  },
});
export const OrderCreate = (props) => {
  const required = (message = "Required") => (value) =>
    value ? undefined : message;

  const validateEmail = [required(), email()];
  //const { translate } = props;

  const translate = useTranslate();

  const classes = useStyles();

  return (
    <Create {...props} actions={<ListActions />}>
      <SimpleForm
        variant="standard"
        margin="dense"
        toolbar={<UserCreateToolbar />}
        initialValues={{
          store_id: Number(localStorage.getItem("store_id")),
        }}
      >
        <Fragment>
          <Grid direction="column" item container xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.order_info")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item md>
                <TextInput
                  source="book"
                  label="table_columns.book_number"
                  className={classes.text}
                  validate={maxLength(9)}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextInput
                  source="code"
                  label="table_columns.code"
                  validate={maxLength(9)}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <NumberInput
                  fullWidth
                  source="total_price"
                  label="table_columns.price"
                  helperText="USD"
                  validate={required()}
                />
              </Grid>
              <Grid item md>
                <NumberInput
                  fullWidth
                  source="discount"
                  label="table_columns.discount"
                  helperText="USD"
                />
              </Grid>
              <Grid item md>
                <ReferenceInput
                  label="table_columns.store"
                  source="store_id"
                  reference="stores"
                  fullWidth
                >
                  <SelectInput
                    optionText="name"
                    helperText="table_columns.store"
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Fragment>
              <Divider style={{ marginTop: 10, marginBottom: 20 }} />
            </Fragment>
            <Grid container direction="column" spacing={1}>
              <Typography variant="h6" gutterBottom>
                {translate("table_columns.staff")}
              </Typography>

              <ArrayInput
                defaultValue={[
                  {
                    staff_id: null,
                    amount: null,
                  },
                ]}
                source="staff_ids"
                label=""
                validate={required()}
                style={{ marginTop: 0 }}
              >
                <SimpleFormIterator>
                  <ReferenceInput
                    source="staff_id"
                    reference="sellers"
                    perPage={-1}
                    validate={required()}
                    sort={{ field: "name", order: "ASC" }}
                    label="table_columns.staff"
                  >
                    <AutocompleteInput
                      optionText="name"
                      inputValue={null}
                      shouldRenderSuggestions={(val) => {
                        return val && val.trim().length > 1;
                      }}
                    />
                  </ReferenceInput>
                  <NumberInput
                    source="amount"
                    label="table_columns.amount"
                    helperText="USD"
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </Fragment>
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>

        <Fragment>
          <Grid>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.linked_order")}
            </Typography>
            <ArrayInput source="link">
              <SimpleFormIterator>
                <ReferenceInput
                  label="table_columns.linked_order"
                  source="linked_order_id"
                  reference="order_filters"
                  perPage={200}
                  validate={required()}
                  sort={{ field: "creation_date", order: "DESC" }}
                >
                  <AutocompleteInput
                    inputValue={null}
                    optionText={(record) =>
                      `${record.book} / ${_.padStart(record.code, 2, "0")}`
                    }
                  />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Fragment>
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>
        <Fragment>
          <Grid direction="column" item container xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.customer")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item md>
                <TextInput
                  source="customer_first_name"
                  label="table_columns.first_name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextInput
                  source="customer_last_name"
                  label="table_columns.last_name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item md>
                <TextInput
                  source="customer_email"
                  label="table_columns.email"
                  helperText="E-mail"
                  validate={validateEmail}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item container direction="column" xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.hotel")}
            </Typography>
            <Grid container direction="row" spacing={1}>
              <ArrayInput
                source="hotels"
                label={" "}
                defaultValue={[
                  {
                    hotel_id: null,
                  },
                ]}
              >
                <SimpleFormIterator disableRemove disableAdd>
                  <ReferenceInput
                    label="table_columns.hotel"
                    source="hotel_id"
                    reference="hotels"
                    perPage={100000}
                    validate={required()}
                    sort={{ field: "name", order: "ASC" }}
                    fullWidth
                  >
                    <AutocompleteInput
                      helperText="Search"
                      optionText={(record) =>
                        record && `${record.name} - ${record.address}`
                      }
                    />
                  </ReferenceInput>
                  <TextInput source="room" label="table_columns.hotel_room" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item container direction="column" xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("table_columns.how_did_you_find_us")}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs>
                <ArrayInput
                  source="origin_ids"
                  label=""
                  fullWidth
                  defaultValue={[
                    {
                      origin_id: null,
                    },
                  ]}
                >
                  <SimpleFormIterator>
                    <FormDataConsumer>
                      {({
                        formData, // The whole form data
                        scopedFormData, // The data for this item of the ArrayInput
                        getSource, // A function to get the valid source inside an ArrayInput
                        ...rest
                      }) => {
                        return (
                          <Fragment>
                            <ReferenceInput
                              label="Origin"
                              source={getSource("id")}
                              reference="origins"
                            >
                              <SelectInput
                                validate={required()}
                                optionText={(record) => (
                                  <Typography>{`${record.source} ${record.description}`}</Typography>
                                )}
                              />
                            </ReferenceInput>
                            {scopedFormData &&
                              (scopedFormData.id === 8 ||
                                scopedFormData.id === 18 ||
                                scopedFormData.id === 1) && (
                                <TextInput
                                  style={{ marginLeft: 10 }}
                                  label="Description"
                                  source={getSource("description")}
                                />
                              )}
                          </Fragment>
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>

        <Fragment>
          <Grid item container spacing={1} direction="column" xs={12} md={8}>
            <Grid item>
              <Typography variant="h6">
                {translate("table_columns.notes")}
              </Typography>
            </Grid>
            <Grid item>
              <Fragment>
                <RichTextInput
                  toolbar={[
                    ["bold", "italic", "underline", "link"],
                    [{ direction: "rtl" }],
                    [{ align: [] }],
                    ["clean"],
                    [{ list: "ordered" }, { list: "bullet" }],
                  ]}
                  source="notes"
                  label=""
                />
              </Fragment>
            </Grid>
          </Grid>
        </Fragment>
      </SimpleForm>
    </Create>
  );
};
const UserCreateToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    style={{ display: "flex", justifyContent: "space-between" }}
  >
    <SaveCustomButton label="ra.action.save" redirect="list" />
    <SaveCustomButton label="Save and pay" pay={true} />
  </Toolbar>
);

const SaveCustomButton = ({ record, pay, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;

  const [create] = useCreate("orders");
  const [createOrigin] = useCreate("order_origins");
  const [createSellers] = useCreate("order_sellers");
  const [createHotel] = useCreate("order_hotels");
  const [createLink] = useCreate("order_links");
  const [deleteLink] = useDelete("order_links");

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }

    let origins = formState.values.origin_ids
      ? formState.values.origin_ids
      : [];

    let staffs = formState.values.staff_ids ? formState.values.staff_ids : [];
    let hotels = formState.values.hotels ? formState.values.hotels : [];
    let links = formState.values.link ? formState.values.link : [];

    create(
      {
        payload: {
          data: {
            staff_id: Number(localStorage.getItem("staff_id")),
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          origins.length &&
            origins.forEach((e) => {
              createOrigin(
                {
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      origin_id: e.id,
                      description: e.description,
                    },
                  },
                },
                {
                  onSuccess: ({ data: newRecord }) => {},
                }
              );
            });

          staffs.length &&
            staffs.forEach((e, i) => {
              createSellers(
                {
                  payload: {
                    data: {
                      order_id: newRecord.id,
                      staff_id: e.staff_id,
                      amount: e.amount ? e.amount : 0,
                      is_main: i === 0 ? 1 : 0,
                    },
                  },
                },
                {
                  onSuccess: ({ data: newRecord }) => {},
                }
              );
            });

          hotels.length &&
            hotels.forEach((e, i) => {
              createHotel(
                {
                  payload: {
                    data: {
                      hotel_id: e.hotel_id,
                      room: e.room,
                      order_id: newRecord.id,
                      staff_id: Number(localStorage.getItem("staff_id")),
                    },
                  },
                },
                {
                  onSuccess: ({ data: newRecord }) => {},
                }
              );
            });

          record.links &&
            record.links.forEach((l) => {
              if (!_.find(links, (ol) => ol.id === l.id)) {
                deleteLink({
                  payload: { id: l.id },
                });
              }
            });

          if (links.length > 0) {
            links.forEach((l) => {
              createLink({
                payload: {
                  data: {
                    order_id: newRecord.id,
                    linked_order_id: l.linked_order_id,
                    staff_id: Number(localStorage.getItem("staff_id")),
                    active: l.active,
                  },
                },
              });

              createLink({
                payload: {
                  data: {
                    linked_order_id: newRecord.id,
                    order_id: l.linked_order_id,
                    staff_id: Number(localStorage.getItem("staff_id")),
                    active: l.active,
                  },
                },
              });
            });
          }

          if (links.length > 0) {
            links.forEach((l) =>
              links.forEach(
                (m) =>
                  l !== m &&
                  createLink({
                    payload: {
                      data: {
                        linked_order_id: l.linked_order_id,
                        order_id: m.linked_order_id,
                        staff_id: Number(localStorage.getItem("staff_id")),
                        active: l.active,
                      },
                    },
                  })
              )
            );
          }

          notify("ra.notification.created", "info", {
            smart_count: 1,
          });
          !pay
            ? redirectTo(redirect, basePath + "/all", newRecord.id, newRecord)
            : redirectTo(
                `/payments/create?order_id=${newRecord.id}&type_id=1`,
                basePath,
                newRecord.id,
                newRecord
              );
        },
      }
    );
  };
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default OrderCreate;
//export default withTranslate(OrderCreate);
