// in src/garments.js
import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  ImageInput,
  ImageField,
  ListButton,
  TopToolbar,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from "react-admin";

import { Divider } from "@material-ui/core";

const GarmentOptionValueFabricEdit = (props) => {
  return (
    <Edit {...props} title="Edit Garment Option Value" actions={<Actions />}>
      <SimpleForm>
        <ReferenceInput
          reference="garment_option_values"
          source="garment_option_value_id"
          perPage={-1}
          sort={{ field: "description", order: "ASC" }}
        >
          <AutocompleteInput optionText="description" />
        </ReferenceInput>

        <ReferenceInput
          reference="fabrics"
          source="fabric_id"
          sort={{ field: "unique_code", order: "ASC" }}
          filter={{ type_id: { inq: [3, 120] } }}
        >
          <AutocompleteInput
            optionText="unique_code"
            shouldRenderSuggestions={(val) => {
              return val && val.trim().length > 4;
            }}
          />
        </ReferenceInput>
        <TextInput source="description" fullWidth />

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>

        {/*FRONT PREVIEW */}
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.preview_fronts) {
              return (
                <div>
                  <Labeled label="FRONT PREVIEW">
                    <ImageField source="preview_front" {...rest} />
                  </Labeled>
                </div>
              );
            }
          }}
        </FormDataConsumer>
        <ImageInput
          source="preview_fronts"
          label=""
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="preview_front" title="title" />
        </ImageInput>

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>
        {/*BACK PREVIEW */}
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.preview_backs) {
              return (
                <div>
                  <Labeled label="BACK PREVIEW">
                    <ImageField source="preview_back" {...rest} />
                  </Labeled>
                </div>
              );
            }
          }}
        </FormDataConsumer>
        <ImageInput
          source="preview_backs"
          label=""
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="preview_back" title="title" />
        </ImageInput>

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        </Fragment>
        {/*DETAIL PREVIEW */}
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (!formData.preview_details) {
              return (
                <div>
                  <Labeled label="DETAIL PREVIEW">
                    <ImageField source="preview_detail" {...rest} />
                  </Labeled>
                </div>
              );
            }
          }}
        </FormDataConsumer>
        <ImageInput
          source="preview_details"
          label=""
          accept="image/*"
          mulitple={false}
        >
          <ImageField source="preview_detail" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export default GarmentOptionValueFabricEdit;
