import GarmentFabricCreate from "./GarmentFabricCreate";
import GarmentFabricEdit from "./GarmentFabricEdit";
/*import GarmentOptionShow from "./GarmentOptionShow";
 */
import GarmentFabricList from "./GarmentFabricList";

export default {
  create: GarmentFabricCreate,
  edit: GarmentFabricEdit,
  list: GarmentFabricList,
  //show: GarmentOptionShow,
};
