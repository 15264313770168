import red from "@material-ui/core/colors/red";

const rowStyle = (selectedRow, theme) => (record, index, defaultStyle = {}) => {
  let style = defaultStyle;

  if (record.status_id === 1)
    return {
      ...style,
      borderLeftColor: red["A200"],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  return style;
};

export default rowStyle;
