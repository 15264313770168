//import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import GarmentOptionValueCreate from "./GarmentOptionValueCreate";
import GarmentOptionValueEdit from "./GarmentOptionValueEdit";
import GarmentOptionValueList from "./GarmentOptionValueList";
import GarmentOptionValueShow from "./GarmentOptionValueShow";

export default {
  create: GarmentOptionValueCreate,
  edit: GarmentOptionValueEdit,
  list: GarmentOptionValueList,
  show: GarmentOptionValueShow,
};
