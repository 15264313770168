// in src/garments.js
import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useUpdate,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  useTranslate,
  useDelete,
  useCreate,
  AutocompleteInput,
} from "react-admin";
import { Typography } from "@material-ui/core";

import { useFormState } from "react-final-form";
import { Divider } from "@material-ui/core";
import _ from "lodash";

const GarmentOptionEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title="Edit Garment Option">
      <SimpleForm toolbar={<UserEditToolbar {...props} />}>
        <TextInput source="name" label="Garment Option Name" />
        <TextInput source="description" label="Description" />
        <TextInput source="vietnamese" label="Vietnamese" />

        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.values").toUpperCase()}
          </Typography>
        </Fragment>
        <ArrayInput source="value_ids" label="">
          <SimpleFormIterator style={{ flex: 1 }}>
            <ReferenceInput
              source="garment_option_value_id"
              reference="garment_option_values"
              label="Value"
              sort={{ field: "description", order: "ASC" }}
              perPage={-1}
            >
              <AutocompleteInput optionText="description" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        {/*
        <Fragment>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <Typography>
            {translate("table_columns.measurements").toUpperCase()}
          </Typography>
</Fragment> */}
      </SimpleForm>
    </Edit>
  );
};

const UserEditToolbar = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  permissions,
  ...props
}) => (
  <Toolbar {...props}>
    <EditCustomButton label="ra.action.edit" redirect="show" />
  </Toolbar>
);

const EditCustomButton = ({ record, ...props }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const formState = useFormState();
  const { basePath, redirect } = props;
  const [update] = useUpdate("garment_options");
  const [deleteValue] = useDelete("garment_option_option_values");
  const [updateValue] = useUpdate("garment_option_option_values");
  const [createValue] = useCreate("garment_option_option_values");

  const handleClick = () => {
    if (!formState.valid) {
      return;
    }
    let options = formState.values.value_ids ? formState.values.value_ids : [];

    update(
      {
        payload: {
          id: formState.values.id,
          data: {
            ...formState.values,
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          record.value_ids &&
            record.value_ids.forEach((o) => {
              if (!_.find(options, (oo) => oo.id === o.id)) {
                deleteValue({
                  payload: { id: o.id },
                });
              }
            });

          options.forEach((o) => {
            if (o.id) {
              updateValue({
                payload: {
                  id: o.id,
                  data: {
                    garment_option_id: newRecord.id,
                    garment_option_value_id: o.garment_option_value_id,
                  },
                },
              });
            } else {
              createValue({
                payload: {
                  data: {
                    garment_option_id: newRecord.id,
                    garment_option_value_id: o.garment_option_value_id,
                  },
                },
              });
            }
          });
          notify("ra.notification.updated", "info", {
            smart_count: 1,
          });
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  /*, [
      formState.valid,
      formState.values,
      create,
      notify,
      redirectTo,
      redirect,
      basePath
    ]*/
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default GarmentOptionEdit;
