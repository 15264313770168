import React, { Children, cloneElement, isValidElement } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { translate } from "ra-core";
import Avatar from "@material-ui/core/Avatar";
import Configuration from "../../configuration/Configuration";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

class UserMenu extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    logout: PropTypes.node,
    icon: PropTypes.node,
    translate: PropTypes.func.isRequired
  };

  static defaultProps = {
    label: "ra.auth.user_menu",
    icon: <AccountCircle />
  };

  state = {
    auth: true,
    anchorEl: null
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { children, label, logout, translate } = this.props;
    if (!logout && !children) return null;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div style={{ display: "flex" }}>
        <Tooltip title="Create Order">
          <IconButton
            style={{ color: "white" }}
            component={Link}
            action={null}
            to={`/orders/create`}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Configuration style={{ marginLeft: 40 }} />

        <Tooltip title={label && translate(label, { _: label })}>
          <div>
            <span style={{ marginLeft: 20, marginRight: 10 }}>
              {localStorage.getItem("username") &&
                localStorage.getItem("username")[0].toUpperCase() +
                  localStorage.getItem("username").slice(1) +
                  "@" +
                  (localStorage.getItem("store_id") > 0
                    ? "Bebe " + localStorage.getItem("store_id")
                    : "Bebe")}
            </span>

            <IconButton
              aria-label={label && translate(label, { _: label })}
              aria-owns={open ? "menu-appbar" : null}
              aria-haspopup={true}
              color="inherit"
              onClick={this.handleMenu}
            >
              <Avatar alt="Bebe User" src={localStorage.getItem("avatar")} />
            </IconButton>
          </div>
        </Tooltip>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
        >
          {Children.map(children, menuItem =>
            isValidElement(menuItem)
              ? cloneElement(menuItem, {
                  onClick: this.handleClose
                })
              : null
          )}
          {logout}
        </Menu>
      </div>
    );
  }
}

export default translate(UserMenu);
