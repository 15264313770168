//import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import GarmentOptionValueFabricCreate from "./GarmentOptionValueFabricCreate";
import GarmentOptionValueFabricEdit from "./GarmentOptionValueFabricEdit";
import GarmentOptionValueFabricList from "./GarmentOptionValueFabricList";
//import GarmentOptionValueShow from "./GarmentOptionValueShow";

export default {
  create: GarmentOptionValueFabricCreate,
  edit: GarmentOptionValueFabricEdit,
  list: GarmentOptionValueFabricList,
  //show: GarmentOptionValueShow,
};
