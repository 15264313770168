import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  AutocompleteInput
} from "react-admin";

export const StaffFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="resources.staff.fields.nick_name"
      source="id"
      reference="staffs"
      perPage={-1}
      allowEmpty
      sort={{ field: "nick_name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="nick_name"
        allowEmpty
        //filter={AutoComplete.caseInsensitiveFilter}

        options={{
          //filter: AutoComplete.fuzzyFilter,
          listStyle: { overflow: "auto", maxHeight: 200 }
        }}
      />
    </ReferenceInput>

    <ReferenceInput
      label="resources.staff.fields.type"
      source="type_id"
      reference="staff_types"
      allowEmpty
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      label="resources.staff.fields.store"
      source="store_id"
      reference="stores"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <BooleanInput source="status" />
  </Filter>
);
