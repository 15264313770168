import React from "react";
import { SimpleForm, TextInput, Create } from "react-admin";

const HotelCreate = props => {
  const required = (message = "Required") => value =>
    value ? undefined : message;
  return (
    <Create {...props} title="Create Hotel">
      <SimpleForm>
        <TextInput
          source="name"
          label="table_columns.hotel_name"
          validate={required()}
        />
        <TextInput
          source="address"
          label="table_columns.address"
          validate={required()}
        />
        <TextInput source="phone" label="table_columns.phone" />
      </SimpleForm>
    </Create>
  );
};
export default HotelCreate;
